<template>
  <main-layout :itemMenuActive="19" tituloMenu="Administración de perfiles">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/profiles">
                <i class="bx bx-left-arrow"/>
              </b-button>
              <p class="title is-4 centered-text">{{ dataForm.id ? "Perfil" : "Crear perfil" }}</p>
            </div>
            <div v-if="isEditable()">
              <b-button v-if="editDisabled" type="is-info" style="margin: 0 4px"
                        @click="() => this.editDisabled = false">
                Editar
              </b-button>
              <b-button v-if="dataForm.id" type="is-danger" @click="deleteProfile">
                Eliminar
              </b-button>
            </div>
          </div>
          <div class="column is-gapless">
            <form v-on:submit.prevent="submitForm">
              <div class="columns">
                <b-field class="column is-half" label="Nombre"
                         :type="errors['profileName'] ? 'is-danger' : ''" :message="errors['profileName']">
                  <b-input type="text" v-model="dataForm.profileName" :disabled="editDisabled"/>
                </b-field>
              </div>
              <div class="columns is-multiline is-mobile">
                <div class="column is-half">
                  <div class="box" :key="category.name" v-for="category in dataForm.categoryRows.firstRow">
                    <CheckboxMenu :selectedOptions="category.selected" :options="category.options"
                                  :id="category.id" :name="category.name" @add-selected="addRole"
                                  @remove-selected="deleteRole" :isActive="category.isActive"
                                  :subOptions="category.subCategoryList" :disabled="editDisabled"/>
                  </div>
                </div>
                <div class="column">
                  <div class="box" :key="category.name" v-for="category in dataForm.categoryRows.secondRow">
                    <CheckboxMenu :selectedOptions="category.selected" :options="category.options"
                                  :id="category.id" :name="category.name" @add-selected="addRole"
                                  @remove-selected="deleteRole" :isActive="category.isActive"
                                  :subOptions="category.subCategoryList" :disabled="editDisabled"/>
                  </div>
                </div>
              </div>
              <br>
              <div v-if="!editDisabled" class="columns">
                <div class="column is-half is-offset-one-quarter">
                  <div class="columns">
                    <b-button v-if="dataForm.id" style="margin-right: 10px" class="column" :loading="isLoading"
                              type="is-danger"
                              @click="() => this.editDisabled = true" expanded>
                      Cancelar
                    </b-button>
                    <b-button class="column" :loading="isLoading" type="is-primary"
                              native-type="submit" expanded>
                      Guardar
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {object, setLocale, string} from "yup";
import {mapActions, mapGetters} from "vuex";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import {showErrorAlert} from "../../assets/config/alert_components";
import CheckboxMenu from "./CheckboxMenu.vue";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});

const schema = object().shape({
  profileName: string().required().max(200)
});

export default {
  name: "ProfileDetail",
  components: {
    MainLayout,
    CheckboxMenu
  },
  data() {
    return {
      isLoading: false,
      editDisabled: false,
      dataForm: {
        profileName: '',
        categoryRows: {
          firstRow: [],
          secondRow: []
        }
      },
      roleList: new Set([]),
      errors: {
        profileName: ''
      },
      userRoleList: this.getRoleIdList()
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editDisabled = true;
      this.loadProfile(this.$route.params.id);
    } else {
      this.loadRoles();
    }
  },
  methods: {
    ...mapActions(['_axios']),
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.userRoleList.includes(46)
    },
    loadRoles() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/profile/role`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.formatRolesByRow(response.data, true);
            }
          })
          .finally(() => this.isLoading = false);
    },
    submitForm() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, {
        abortEarly: false,
        context: {update: this.dataForm.id !== undefined}
      })
          .then(() => {
            const profileData = this.getData()
            if (profileData.roleList.length !== 0) {
              this.isLoading = true;
              if (this.dataForm.id) {
                this.updateProfile();
              } else {
                this.createProfile();
              }
            } else {
              showErrorAlert('Debe seleccionar al menos una rol');
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    },
    createProfile() {
      const options = {
        method: 'post',
        url: `${HOST_API}/profile`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        data: this.getData()
      }
      axios(options).then(res => {
        if (res.status === 201) {
          this.$buefy.toast.open({
            message: 'Perfil creado exitosamente!',
            type: 'is-success'
          });
          this.$router.push("/profiles");
        }
      }).catch((e) => {
        showErrorAlert(e.response.data.message)
      }).finally(() => this.isLoading = false)
    },
    updateProfile() {
      const options = {
        method: 'put',
        url: `${HOST_API}/profile/${this.dataForm.id}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        data: this.getData()
      }
      axios(options).then(res => {
        if (res.status === 202) {
          this.$buefy.toast.open({
            message: 'Perfil actualizado exitosamente!',
            type: 'is-success'
          });
          this.editDisabled = true;
        }
      }).catch((e) => {
        showErrorAlert(e.response.data.message)
      }).finally(() => this.isLoading = false)
    },
    loadProfile(id) {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/profile/${id}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        data: this.getData()
      }
      axios(options).then(res => {
        if (res.status === 200) {
          const response = res.data;
          this.dataForm.profileName = response.name;
          this.dataForm.id = response.id;
          this.formatRolesByRow(response.roleList, false);
        }
      }).catch((e) => {
        showErrorAlert(e.response.data.message)
      }).finally(() => this.isLoading = false)
    },
    resetErrorMessages() {
      for (const key in this.errors) {
        this.errors[key] = '';
      }
    },
    deleteProfile() {
      this.$buefy.dialog.confirm({
        title: 'Perfiles',
        message: 'Está seguro de eliminar el perfil?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          const options = {
            method: 'delete',
            url: `${HOST_API}/profile/${this.dataForm.id}`,
            headers: {
              Authorization: `Bearer ${this.$store.state.tokenAuth}`
            },
            data: this.getData()
          }
          axios(options).then(res => {
            if (res.status === 204) {
              this.$buefy.toast.open({
                message: 'Perfil eliminado',
                type: 'is-success'
              });
              this.$router.push('/profiles')
            }
          }).catch((e) => {
            showErrorAlert(e.response.data.message)
          }).finally(() => this.isLoading = false)
        }
      })
    },
    getData() {
      return {
        name: this.dataForm.profileName,
        roleList: Array.from(this.roleList)
      }
    },
    addRole(id) {
      this.roleList.add(id);
    },
    deleteRole(id) {
      this.roleList.delete(id);
    },
    formatRolesByRow(categoryList, isCreate) {
      this.roleList = new Set([]);
      let firstRowCount = 0, secondRowCount = 0;
      for (const category of categoryList) {
        let categoryData = this.formatCategoryRoles(category, isCreate)
        if (firstRowCount === secondRowCount || firstRowCount < secondRowCount) {
          this.dataForm.categoryRows.firstRow.push(categoryData)
          firstRowCount += 1
        } else {
          this.dataForm.categoryRows.secondRow.push(categoryData)
          secondRowCount += 1
        }
      }
    },
    formatCategoryRoles(category, isCreate) {
      const categoryData = {
        options: category.roleList,
        name: category.categoryName,
        subCategoryList: [],
        selected: [],
        id: category.categoryId,
        isActive: false
      }
      if (!isCreate) {
        for (const item of categoryData.options) {
          if (item.state) {
            categoryData.selected.push(item.id);
            this.roleList.add(item.id);
          }
        }
        if (categoryData.selected.length !== 0) {
          categoryData.isActive = true
        }
      }
      for (const sub of category.subCategoryList) {
        const newSubcategory = this.formatCategoryRoles(sub, isCreate);
        categoryData.subCategoryList.push(newSubcategory)
        if (newSubcategory.isActive) {
          categoryData.isActive = true;
        }
      }
      return categoryData;
    }
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

</style>