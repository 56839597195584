<template>
  <main-layout itemMenuActive="31" tituloMenu="Logs de Auditoría">
    <div class="container is-full">
      <br />
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column ">
            <div class="card ">
              <div class="card-content">
                <b-field grouped label="Logs de Auditoría">
                  <b-field label="">
                    <h1 for="" style="margin-right: 1rem; line-height: 2.5rem;">
                      Tipo de Log:
                    </h1>
                    <b-select id="documento" placeholder="Seleccione un tipo de Log" v-model="opcionSeleccionada" expanded
                      v-on:change.native="get_users">
                      <option disabled value="Seleccione un tipo de Log">Seleccione un tipo de Log</option>
                      <option value="habilitacion">Habilitación de documentos</option>
                      <option value="parametrizacion">Parametrización tipos de documento</option>
                      <option value="textParametrization">Parametrización de Textos</option>
                      <option value="exportLogs">Exportación de Reportes</option>
                      <option value="envioProgramados">Envío Programados</option>
                      <option value="iconsParametrization">Parametrización de Iconos</option>
                      <option value="asignacionRoles">Asignación roles</option>
                    </b-select>
                  </b-field>
                  <div v-if="isAbleExport()" class="uk-navbar-right">
                    <b-field label="">
                      <b-button v-on:click="exportar_csv_boton"
                      :disabled="dataTable.length === 0">CSV</b-button>
                      <b-button v-on:click="exportar_xlsx_boton"
                      :disabled="dataTable.length === 0">Excel</b-button>
                      <b-button v-on:click="exportar_pdf_boton"
                      :disabled="dataTable.length === 0">PDF</b-button>
                    </b-field>
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-multiline is-centered" v-if="opcionSeleccionada === 'habilitacion'">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <b-field grouped  label="">
                  <b-field label="Fecha">
                    <b-field label="">
                      <b-datepicker placeholder="Seleccione fecha..." v-model="fecha" range
                        :max-date="maxDate"></b-datepicker>
                    </b-field>
                  </b-field>
                  <b-field label="Usuario">
                    <div class="control has-icons-left">
                      <div class="select">
                        <select v-model="usuarioSeleccionado">
                          <option :value="null" disabled>Seleccione Usuario</option>
                          <option v-for="item in opcionesUsuarios" :value="item" :key="item.id">
                            {{ item.email }}
                          </option>
                        </select>
                      </div>
                      <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                  </b-field>
                  
                    <div class="uk-navbar-right">
                      <b-field b-field grouped group-multiline label="">
                        <p class="control">
                          <b-button native-type="submit" type="is-info" @click="consultar">Consultar</b-button>
                        </p>
                        <p class="control">
                          <b-button native-type="submit" type="is-info" @click="limpiar">Limpiar</b-button>
                        </p>
                      </b-field>
                    </div>

                </b-field>
                <b-field>
                  <div class="columns">
                    <div class="column is-two-sixths">
                      <label class="label">Tipo de documento:</label>
                      <div class="control has-icons-left">
                        <div>
                          <ul>
                            <li v-for="(pareja, index) in parejas.slice(0, Math.ceil(parejas.length / 2))" :key="index">
                              <label class="checkbox">
                                <input type="checkbox" :value="pareja.code" v-model="tiposSeleccionados">
                                {{ pareja.Label }}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="column is-two-sixths">
                      <label class="label">Tipo de documento:</label>
                      <div class="control has-icons-left">
                        <div>
                          <ul>
                            <li v-for="(pareja, index) in parejas.slice(Math.ceil(parejas.length / 2))" :key="index">
                              <label class="checkbox">
                                <input type="checkbox" :value="pareja.code" v-model="tiposSeleccionados">
                                {{ pareja.Label }}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="columns is-mobile is-multiline is-centered" v-if="opcionSeleccionada === 'habilitacion'">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <TableCard messageEmpty="" :columns="columns" :info="dataTable" detailKey="id" :tableConfig="tableConfig"
                :haveFilter="false"></TableCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
//import {formatDataLogs} from "../../assets/config/utilities";
import { COLUMNS_LOGS_ENABLED } from "../../assets/config/constants/TableConstants";
import axios from 'axios';
import iconv from 'iconv-lite';
import { config } from "../../assets/config/jsonconfig.json";
import {saveLog} from "../../services/exportLogs";
import { getToken } from "../../assets/config/getToken.js";

const url = config.urlBaseTiposdeDocumento
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento

export default {

  name: "SopTransaction",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {

      parejas: [],
      tiposSeleccionados: [],
      usuarioSeleccionado: null,
      opcionesUsuarios: [],
      opcionesTipos: [],
      opcionSeleccionada: "habilitacion",
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      total_exitosas: 0,
      total_fallidas: 0,
      isLoading: false,
      dataTable: [],
      counter: 0,
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      locale: "en-CA",
      minDate: new Date(),
      maxDate: new Date(),
      columns: COLUMNS_LOGS_ENABLED,
      roleList: this.getRoleIdList(),
      token: null
    };
  }, created() {
    axios.get(urlBaseTiposdeDocumento + '/get_documents_code', {
      headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
      },
    })
      .then(response => {
        this.parejas = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },

  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.get_users();
    //this.obtenerRangoFecha();
    this.$emit("tituloTransaccion", "VU OnBoarding");
    this.nombreTituloMenu("Secure Onboarding Process");

  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isAbleExport() {
      return this.roleList.includes(49)
    },
    exportar_csv_general(endpoint_consulta, datas) {
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_csv';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');
      const data = {
        data: datas,
        url: urlBaseTiposdeDocumento + endpoint_consulta,
        filename: "data.csv",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de transacción", "Funcionalidad", "Tipo de usuario", "Tipo de Documento"]
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.text(); // convertir respuesta a texto
        })
        .then(text => {
          // convertir texto a buffer de latin1
          const buffer = iconv.encode(text, 'latin1');
          // crear objeto blob a partir del buffer
          const blob = new Blob([buffer], { type: 'text/csv' });
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const fileName = `data_${formattedDate}.csv`;
          const encodedFileName = encodeURI(fileName);
          link.setAttribute('download', encodedFileName);
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
          this.saveExportLog("csv");
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    exportar_xlsx_general(endpoint_consulta, datas) {
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_excel';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');
      const data = {
        data: datas,
        url: urlBaseTiposdeDocumento + endpoint_consulta,
        filename: "data.xlsx",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de transacción", "Funcionalidad", "Tipo de usuario", "Tipo de Documento"]
      };
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        }, responseType: 'blob',
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.xlsx`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();

            this.saveExportLog("excel");
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    exportar_pdf_general(endpoint_consulta, datas) {
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_pdf';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');
      const data = {
        data: datas,
        url: urlBaseTiposdeDocumento + endpoint_consulta,
        filename: "data.pdf",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de transacción", "Funcionalidad", "Tipo de usuario", "Tipo de Documento"]
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.pdf`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();

            this.saveExportLog("pdf");
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },

    consultar() {

      if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const data = url + "/getlogs_HU";
        this.handleSubmitUpdateget(data);
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const ruta = url + "/getlogs_date";
        const data = {
          "url": ruta,
          "params": {
            "fecha_in": this.convertirFechadata(this.fecha[0]),
            "fecha_out": this.sumarDia(this.fecha[1])
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        console.log(this.usuarioSeleccionado)
        const ruta = url + "/getlogs_user";
        const data = {
          "url": ruta,
          "params": {
            "user": this.usuarioSeleccionado.email
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data);
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const ruta = url + "/getlogs_type_many";
        const data = {
          "url": ruta,
          "params": {
            "types": this.tiposSeleccionados
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const ruta = url + "/getlogs_date_user";
        const data = {
          "url": ruta,
          "params": {
            "fecha_in": this.convertirFechadata(this.fecha[0]),
            "fecha_out": this.sumarDia(this.fecha[1]),
            "user": this.usuarioSeleccionado.email
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const ruta = url + "/getlogs_type_many_date";
        const data = {
          "url": ruta,
          "params": {
            "fecha_in": this.convertirFechadata(this.fecha[0]),
            "fecha_out": this.sumarDia(this.fecha[1]),
            "types": this.tiposSeleccionados
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== 0) {
        const ruta = url + "/getlogs_type_many_user";
        const data = {
          "url": ruta,
          "params": {
            "user": this.usuarioSeleccionado.email,
            "types": this.tiposSeleccionados
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== []) {
        const ruta = url + "/getlogs_type_many_date_user";
        const data = {
          "url": ruta,
          "params": {
            "fecha_in": this.convertirFechadata(this.fecha[0]),
            "fecha_out": this.sumarDia(this.fecha[1]),
            "user": this.usuarioSeleccionado.email,
            "types": this.tiposSeleccionados
          },
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        this.handleSubmitUpdate(data)
      }
    },
    exportar_xlsx_boton() {

      if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_HU";
        const data2 = {
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1])
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many";
        const data2 = {
          "types": this.tiposSeleccionados
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "types": this.tiposSeleccionados
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== []) {
        const endpoint = "/getlogs_type_many_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_xlsx_general(endpoint, data2)
      }
    },
    exportar_pdf_boton() {
      if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_HU";
        const data2 = {}
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1])
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many";
        const data2 = {
          "types": this.tiposSeleccionados
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "types": this.tiposSeleccionados
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_pdf_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== []) {
        const endpoint = "/getlogs_type_many_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_pdf_general(endpoint, data2)
      }
    },
    exportar_csv_boton() {
      if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_HU";
        const data2 = {}
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1])
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many";
        const data2 = {
          "types": this.tiposSeleccionados
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length === 0) {
        const endpoint = "/getlogs_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado === null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_date";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "types": this.tiposSeleccionados
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length === 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== 0) {
        const endpoint = "/getlogs_type_many_user";
        const data2 = {
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_csv_general(endpoint, data2)
      }
      else if (this.fecha.length !== 0 && this.usuarioSeleccionado !== null && this.tiposSeleccionados.length !== []) {
        const endpoint = "/getlogs_type_many_date_user";
        const data2 = {
          "fecha_in": this.convertirFechadata(this.fecha[0]),
          "fecha_out": this.sumarDia(this.fecha[1]),
          "user": this.usuarioSeleccionado.email,
          "types": this.tiposSeleccionados
        }
        this.exportar_csv_general(endpoint, data2)
      }
    },
    convertirFechadata(fechaString) {
      const fecha = new Date(fechaString);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    },
    sumarDia(fechaString) {
      const fecha = new Date(fechaString);
      fecha.setDate(fecha.getDate() + 1);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    }
    ,
    limpiar() {
      this.usuarioSeleccionado = null;
      this.tiposSeleccionados = [];
      this.fecha = [];
      const data = url + "/getlogs_HU";
      this.handleSubmitUpdateget(data);
    },
    get_users() {
      if (this.opcionSeleccionada === "parametrizacion") {
        this.$router.push('/ReportesParametrizacion')
      } else if (this.opcionSeleccionada === "textParametrization") {
        this.$router.push('/textParametrizationLogs')
      } else if (this.opcionSeleccionada === "exportLogs") {
          this.$router.push('/exportLogs')
      } else if(this.opcionSeleccionada === "envioProgramados"){
        this.$router.push('/exportLogs')
      } else if (this.opcionSeleccionada === "iconsParametrization"){
        this.$router.push('/iconParametrizationLogs')
      } else if (this.opcionSeleccionada === "asignacionRoles"){
        this.$router.push('/asignacionRolesLogs');
      }

      const options = {
        method: "get",
        uri: "auth/ListUsers",
      };
      this._axios(options)
        .then((response) => {
          this.opcionesUsuarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get(url + '/get_documents_code', {
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
      })
        .then((response) => {
          this.opcionesTipos = response.data["Label"];
          this.tipo = response.data["code"]
        })
        .catch((error) => {
          console.log(error);
        })
    },
    handleSubmitUpdate(data) {
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;

        this.dataTable = [];
        this.counter = 0;
        axios.post(data.url, data.params
        )
          .then((response) => {
            response.data.forEach((item) => {
              this.dataTable.push(item);
            });
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    handleSubmitUpdateget(data) {
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;

        this.dataTable = [];
        this.counter = 0;
        axios.get(data , {
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          },
        })
          .then((response) => {
            response.data.forEach((item) => {
              this.dataTable.push(item);
            });
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    obtenerRangoFecha() {
      const options = {
        method: "get",
        uri: "boards/getRangeDate",
      };
      this._axios(options)
        .then((response) => {
          this.minDate = new Date(response.data[0]);
          this.maxDate = new Date(response.data[1]);
        })
        .catch((error) => {
          this.isLoadingTable = false;
          console.log(error);
        })
        .finally(() => this.cancelarLoading());
      this.handleSubmit(url + '/getlogs_HU');
    },
    handleSubmit(data) {
      this.get_users();
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;
        this.dataTable = [];
        this.counter = 0;

        axios.get(data, {
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          },
        })
          .then((response) => {
            response.data.forEach((item) => {
              this.dataTable.push(item);
            });
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    }
    ,
    exportExcel: function () {
      this.exportFile("exportExcelOnBoardingRangDate");
    },
    exportPDF() {
      this.exportFile("exportPDFOnBoardingRangDate");
    },
    exportCSV() {
      this.exportFile("exportCSVSOP");
    },
    exportFile(type) {
      if (this.fechaInicial !== "") {
        let fechaInicial = this.convertFecha(this.fechaInicial);
        let fechaFinal = this.convertFecha(this.fechaFinal);
        let routeData = `${this._HOST_SERVE}/export/${type}/${fechaInicial}/${fechaFinal}`;
        window.open(routeData, "_blank");
      }
    },
    alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
      this.$buefy.dialog.alert({
        title: titulo,
        message: msj,
        type: tipo,
        hasIcon: true,
        icon: "times-circle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
      });
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    convertFecha: function (fecha) {
      const dtf = new Intl.DateTimeFormat(this.locale);
      return dtf.format(new Date(fecha));
    },
      saveExportLog(type) {
          let logData = {
              "user": this.getUsuario(),
              "sectionId": 4,
              "reportId": 1,
              "subReport": "Habilitación de documentos",
              "subReportId": 1,
              "format": type
          }
          if (this.fecha.length !== 0) {
              logData.startDate = this.fecha[0]
              logData.endDate = this.fecha[1]
          }
          saveLog(logData);
      },
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
      ...mapGetters(["getUsuario"])
  },
};
</script>
<style>
:root {
  --primary: #00522a;
}

b-table {
  font-size: small;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}

.has-text-primary {
  color: #00522a !important;
}

.styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}

.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}

.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>