<template>
  <main-layout :itemMenuActive="22" tituloMenu="Iconos">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <p class="title is-4 centered-text">Edición de iconos</p>
          <br>
          <div class="columns">
            <div class="column is-half">
              <b-field horizontal label="Proceso">
                <b-select placeholder="Seleccione un proceso" v-model="processSelected" expanded>
                  <option :key="item.key" v-for="item in processList" :value="item">
                    {{ item.value }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div v-if="processIcons">
            <hr>
            <p class="title is-5">{{ processSelected.value }}</p>
            <div class="columns">
              <div class="column">
                <div :key="index" class="columns" v-for="(rowList, index) in processIconsByRow">
                  <div class="column is-half" :key="item.key" v-for="item in rowList">
                    <b-field horizontal :label="item.name"
                             :type="formErrors[item.key] ? 'is-danger' : ''" :message="formErrors[item.key]">
                      <div class="columns">
                        <b-select class="column is-four-fifths" placeholder="Seleccione el icono" expanded
                                  v-model="item.value" :disabled="isDisabled()">
                          <option
                              v-for="(option, index) in item.options"
                              :value="option"
                              :key="option">
                            Opción {{ index + 1 }}
                          </option>
                        </b-select>
                        <div class="column" style="display: flex; justify-content: center; align-items: center">
                          <i style="font-size: 1.5em;" :class="item.value"></i>
                        </div>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isEditable()" class="columns">
              <div class="column column is-4 is-offset-8">
                <div class="columns">
                  <b-button style="margin-right: 10px" class="column" :loading="isLoading"
                            type="is-danger" expanded @click="discard">
                    Descartar
                  </b-button>
                  <b-button class="column" :loading="isLoading" type="is-primary" expanded
                            @click="submit" :disabled="isDisabledSubmit">
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions, mapGetters} from "vuex";
import {object, setLocale, string} from "yup";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import {config} from "../../assets/config/jsonconfig.json";
import { getToken } from "../../assets/config/getToken.js";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});

const URL_DOCUMENT_TYPES = config.urlBaseTiposdeDocumento;
export default {
  name: "Parametrization",
  components: {
    MainLayout
  },
  data() {
    return {
      isLoading: false,
      processList: [],
      processSelected: undefined,
      processIcons: undefined,
      originalProcessVars: undefined,
      formErrors: undefined,
      schema: undefined,
      isDisabledSubmit: false,
      roleList: this.getRoleIdList(),
      token: null
    }
  },
  watch: {
    processSelected(newValue) {
      if (newValue) {
        this.processIcons = undefined;
        this.loadProcessIcons(newValue.key)
      }
    },
    processIcons: {
      handler(newValue) {
        if (newValue) {
          this.isDisabledSubmit = this.isEquivalent(this.getFormData(), this.originalProcessVars)
        }
      },
      deep: true
    }
  },
  computed: {
    processIconsByRow() {
      const result = []
      for (let i = 0; i < this.processIcons.length; i += 2)
        result.push(this.processIcons.slice(i, i + 2))
      return result
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.loadProcessList();
  },
  methods: {
    ...mapActions(['_axios']),
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.roleList.includes(56)
    },
    isDisabled() {
      return !this.isEditable()
    },
    submit() {
      this.resetFormErrors();
      this.schema.validate(this.getFormData(), {abortEarly: false})
          .then(() => {
            this.saveProcessIcons();
          }).catch((err) => {
        err.inner.forEach((error) => {
          this.formErrors = {...this.formErrors, [error.path]: error.message};
        });
      });
    },
    saveProcessIcons() {
      this.isLoading = true;
      let postData = this.getFormData();
      postData.processKey = this.processSelected.key;
      const options = {
        method: 'post',
        url: `${HOST_API}/parametrization/icon`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        data: postData
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.$buefy.toast.open({
                message: `Los cambios se guardaron exitosamente`,
                type: 'is-success'
              })
              this.saveLog(postData);
              this.originalProcessVars = this.getFormData()
              this.isDisabledSubmit = true;
            }
          })
          .finally(() => this.isLoading = false);
    },
    saveLog(data) {
      delete data.processKey
      for (const iconKey in data) {
        if (data[iconKey] !== this.originalProcessVars[iconKey]) {
          this.saveIconLog(iconKey, this.originalProcessVars[iconKey], data[iconKey])
        }
      }
    },
    saveIconLog(iconKey, lastValue, newValue) {
      const result = this.processIcons.filter((iconData) => iconData.key === iconKey)[0];

      const options = {
        method: 'post',
        url: `${URL_DOCUMENT_TYPES}/save_log_icons`,
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        data: {
          processKey: parseInt(this.processSelected.key),
          process: this.processSelected.value,
          iconName: result.name,
          lastValue: "Opción " + (result.options.indexOf(lastValue) + 1),
          newValue: "Opción " + (result.options.indexOf(newValue) + 1),
          user: this.getUsuario()
        }
      }
      axios(options);
    },
    loadProcessList() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/parametrization/icon`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.processList = response.data.data;
            }
          })
          .finally(() => this.isLoading = false);
    },
    loadProcessIcons(processKey) {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/parametrization/icon/${processKey}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data.data;
              let reponseList = [];
              for (const key in responseData) {
                responseData[key]["key"] = key
                reponseList.push(responseData[key]);
              }
              this.processIcons = reponseList;
              this.originalProcessVars = this.getFormData();
              this.createSchema();
            }
          })
          .finally(() => this.isLoading = false);
    },
    createSchema() {
      let jsonSchema = {}, formErrors = {}
      for (const item of this.processIcons) {
        jsonSchema[item.key] = string().required().max(200)
        formErrors[item.key] = ''
      }
      this.schema = object().shape(jsonSchema)
      this.formErrors = formErrors
    },
    resetFormErrors() {
      for (const key in this.formErrors) {
        this.formErrors[key] = '';
      }
    },
    getFormData() {
      let jsonFormData = {}
      for (const item of this.processIcons) {
        jsonFormData[item.key] = item.value
      }
      return jsonFormData
    },
    isEquivalent(a, b) {
      const aProps = Object.getOwnPropertyNames(a);
      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    },
    discard() {
      this.processIcons = undefined;
      this.processSelected = undefined;
    },
    ...mapGetters(["getUsuario"])
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

</style>