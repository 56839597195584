const APISKEY = {
    "api_key_1": "f93985e4-7294-4b00-85af-3cbaf962843d",
    "api_key_2": "3bae6f23-def6-4e34-b89b-0bba17ef5468",
    "api_key_3": "069d7b68-ab3d-46e8-b5be-d10bf5dc2915"
}
function seleccionarClaveAPIRandom() {
    const keys = Object.keys(APISKEY);
    const randomIndex = Math.floor(Math.random() * keys.length);
    return APISKEY[keys[randomIndex]];
}
export async function getToken(dominio) {
    dominio = atob(dominio);
    let parametros = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_key: seleccionarClaveAPIRandom()
        })
    };

    return await fetch(`${dominio}/typedocuments/generate_token`, parametros)
    .then(response => {
        return response.json();
    })
    .catch(function (error) {
        console.error(`URL /generate_token: Hubo un problema con la petición:`, error);
        return error;
    });
}