<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br>
    <div class="container">
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <p class="title is-4">Envios programados</p>
                <b-button type="is-primary" tag="router-link"
                          to="/report">
                  Crear
                </b-button>
              </div>

              <form v-on:submit.prevent="submitForm">
                <!--                <b-field label="">-->
                <!--                  <h1 for="" style="margin-right: 1rem; line-height: 2.5rem">-->
                <!--                    Rango de Fecha:-->
                <!--                  </h1>-->
                <!--                  <b-datepicker-->
                <!--                      placeholder="Seleccione fecha..."-->
                <!--                      v-model="rangeDate"-->
                <!--                      range-->
                <!--                      :max-date="maxDate"-->
                <!--                  >-->
                <!--                  </b-datepicker>-->
                <!--                  <b-button native-type="submit" type="is-info">-->
                <!--                    Consultar-->
                <!--                  </b-button>-->
                <!--                </b-field>-->
              </form>
              <br>
              <TableCard messageEmpty="Sin datos para procesar."
                         :columns="columns" :info="data"
                         detailKey="id"
                         :haveFilter="false"
                         :tableConfig="tableConfig"
                         @delete="deleteReport"
                         @edit="editReport"></TableCard>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "../../components/Layout/MainLayout";
import {COLUMNS_AUTOMATIC_REPORTS_TABLE} from "../../assets/config/constants/TableConstants";
import {formatAutomaticReportData} from "../../assets/config/utilities";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";

export default {
  name: "SendReports",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {
      isLoading: false,
      maxDate: new Date(),
      data: [],
      columns: COLUMNS_AUTOMATIC_REPORTS_TABLE,
      rangeDate: [],
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false
      },
      options: {
        method: '',
        url: '',
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        }
      }
    }
  },
  mounted() {
    this.submitForm()
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.options.method = 'get';
      this.options.url = `${HOST_API}/report`;
      axios(this.options)
          .then((response) => {
            if (response.status === 200) {
              this.data = formatAutomaticReportData(response.data);
            }
          })
          .finally(() => this.isLoading = false);
    },
    deleteReport(reportId) {
      this.$buefy.dialog.confirm({
        title: 'Reporte automático',
        message: `Está seguro de eliminar el reporte identificado con el ID: ${reportId}?`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          this.options.method = 'delete';
          this.options.url = `${HOST_API}/report/${reportId}`;
          axios(this.options)
              .then((response) => {
                if (response.status === 204) {
                  this.data = this.data.filter(function (value) {
                    return value.id !== reportId;
                  })
                }
              })
              .finally(() => this.isLoading = false);
        }
      })
    },
    editReport(reportId) {
      this.$router.push({name: 'CrearReporte', params: {id: reportId}})
    }
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

</style>