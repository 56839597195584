<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/sendreportsv2">
                <i class="bx bx-left-arrow" />
              </b-button>
              <p class="title is-4 centered-text">{{ dataForm.id ? "Envío programado" : "Programar envío" }}</p>
            </div>
            <div>
              <b-button v-if="editDisabled" type="is-info" style="margin: 0 4px" @click="editar_nuevo_reporte">
                Editar
              </b-button>
              <b-button v-if="dataForm.id" type="is-danger" @click="deleteReport">
                Eliminar
              </b-button>
            </div>
          </div>
          <br>
          <form v-on:submit.prevent="submitForm">
            <div class="columns">
              <b-field class="column" label="Periodo de ejecución" :type="errors['typeTimeLapse'] ? 'is-danger' : ''"
                :message="errors['typeTimeLapse']">
                <b-select placeholder="Selecciona una opción" v-model="peridoseleccionado" :disabled="isDisabled"
                  expanded>
                  <option :key=this.Mensual value="Mensual">Mensual</option>
                  <option :key=this.Semanal value="Semanal">Semanal</option>
                </b-select>
              </b-field>


              <b-field class="column is-fullwidth" label="Reporte">
                <b-select v-model="reporteseleccionado" placeholder="Selecciona una opción"
                  @input="changeExportOptions" :disabled="editDisabled" expanded>
                  <option :key="option.type" v-for="option of basereporte" :value="option">{{ option }}</option>
                </b-select>
              </b-field>

              <b-field v-if="this.basesubreporte.length !== 0" class="column is-narrow" label="Tipo de Log">
                <b-select v-model="subreporteseleccionado" placeholder="Selecciona una opción"
                  @input="changeExportOptionstipes" :disabled="this.basesubreporte.length === 0" expanded>
                  <option :key="option" v-for="option of basesubreporte" :value="option">{{ option }}</option>
                </b-select>
              </b-field>


              <b-field class="column" label="Formato">
                <b-select v-model="formatoseleccionado" placeholder="Selecciona una opción"
                 :disabled="this.baseformato.length === 0 || this.baseformato===[]" expanded>
                  <option :key="option" v-for="option of baseformato" :value="option">{{
                    option
                  }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Fecha Inicio">
                <b-datepicker v-model="fechainicioseleccionada" placeholder="Seleccione la fecha" icon="calendar-today"
                  :disabled="isDisabled">
                </b-datepicker>
              </b-field>
              <b-field class="column" label="Hora de Ejecución" :type="errors['executionHour'] ? 'is-danger' : ''"
                :message="errors['executionHour']">
                <b-timepicker rounded v-model="dataForm.executionHour" placeholder="Seleccione la hora" icon="clock"
                  editable :hour-format="'24'" :disabled="isDisabled">
                </b-timepicker>
              </b-field>
              <b-field class="column is-2" label="Estado"
                :style="dataForm.id ? { 'display': 'block' } : { 'display': 'none' }">
                <div class="control">
                  <b-switch v-model="dataForm.isActive" @input="changeState" :disabled="editDisabled">{{
                    stateText
                  }}
                  </b-switch>
                </div>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Asunto" :type="errors['subject'] ? 'is-danger' : ''"
                :message="errors['subject']">
                <b-input maxlength="200" name="subject" v-model="dataForm.subject" placeholder="Ingrese el asunto"
                  expanded :disabled="editDisabled"></b-input>
              </b-field>
              <b-field class="column" label="Correos" :type="errors['emails'] ? 'is-danger' : ''"
                :message="errors['emails']">
                <b-taginput v-model="dataForm.emails" ellipsis icon="label" maxtags="10" placeholder="Agregar correo"
                  aria-close-label="Delete this tag" @typing="onEmailChange" :before-adding="beforeAddingEmail"
                  :disabled="editDisabled">
                </b-taginput>
              </b-field>
            </div>
            <b-field label="Mensaje" :type="errors['content'] ? 'is-danger' : ''" :message="errors['content']">
              <b-input maxlength="400" type="textarea" v-model="dataForm.content" placeholder="Ingrese el mensaje"
                :disabled="editDisabled"></b-input>
            </b-field>
            <div v-if="dataForm.createdAt" class="columns">
              <div class="column">
                <p><strong>Fecha de creación:</strong> {{ formatDate(dataForm.createdAt) }}</p>
              </div>
              <div class="column">
                <p><strong>Fecha de actualización:</strong> {{ formatDate(dataForm.modifiedAt) }}</p>
              </div>
            </div>
            <div v-if="!editDisabled" style="display: flex">
              <div class="buttons" style="margin-right: 0; margin-left: auto">
                <b-button :loading="isLoading" type="is-danger" @click="cancel">
                  Cancelar
                </b-button>
                <b-button :loading="isLoading" type="is-primary" @click="createReport">
                  Guardar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {
  string,
  date,
  object,
  array,
  setLocale
} from 'yup';
import {
  HOST_API,
  TIME_LAPSE_TYPE_OPTIONSv2,
} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import { dateFormatting } from "../../assets/config/utilities";
import { config } from "../../assets/config/jsonconfig.json";
import {mapGetters} from "vuex";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

setLocale({
  mixed: {
    required: 'Es un campo obligatorio',
  },
  number: {
    min: 'El valor debe ser mayor o igual a ${min}',
    max: 'El valor debe ser menor o igual a ${max}'
  },
  array: {
    min: 'Debe ingresar ${min} o más emails',
    max: 'Debe ingresar ${max} o menos emails'
  }
});

const schema = object().shape({
  subject: string().required().max(200),
  content: string().required().max(400),
  emails: array().min(1).max(10),
  startDate: date().required(),
  // endDate: date().required(),
  executionHour: date().required(),
  // timeLapse: number().min(1).max(20).transform((_, val) => val === Number(val) ? val : null).typeError('Es un campo obligatorio'),
  reportType: string().required(),
  exportType: string().required(),
});

export default {
  name: "CreateReport",
  components: {
    MainLayout
  },
  data() {
    return {
      fecha_de_creacion: null,
      fecha_actualizacion: null,
      fechainicioseleccionada:null,
      estado: null,
      asunto: null,
      correos: null,
      mensaje:null,
      horaejecucion:null,
      tipodeformato: [],
      tipodeformatoseleccionado: null,
      tipodelogeo: null,
      subReporteformatosanidados:[],
      peridoseleccionado: null,
      tipodelog: [],
      Mensual: "Mensual",
      Semanal: "Semanal",
      tipodelogseleccionado: null,
      opcionreporteseleccionada: null,
      timeLapseTypeOptions: TIME_LAPSE_TYPE_OPTIONSv2,
      reportTypeOptions: [],
      basereporte: [],
      reporteseleccionado: null,
      baseformato: [],
      formatoseleccionado: null,
      basesubreporte: [],
      subreporteseleccionado:null,
      datoscompletos: [],
      exportTypeOptions: undefined,
      isLoading: false,
      exportOptionDisabled: true,
      // endDateConfig: {
      //   minDate: undefined,
      //   disabled: true
      // },
      editDisabled: false,
      isDisabled: false,
      stateText: 'Activo',
      dataForm: {
        isActive: true,
        subject: '',
        content: '',
        emails: [],
        startDate: undefined,
        endDate: undefined,
        executionHour: undefined,
        // timeLapse: 1,
        typeTimeLapse: TIME_LAPSE_TYPE_OPTIONSv2[0].value,
        exportType: undefined,
        reportType: undefined
      },
      errors: {
        subject: '',
        content: '',
        emails: '',
        startDate: '',
        // endDate: '',
        executionHour: '',
        // timeLapse: '',
        reportType: '',
        exportType: ''
      },
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.loadReportOptions();
    if (this.$route.params.id) {
      this.loadReportData(this.$route.params.id);
      this.editDisabled = true;
      this.isDisabled = true;
    }
  },
  methods: {
    loadReportOptions() {
      this.basereporte = []
      this.basesubreporte=[]
      const options = {
        method: 'get',
        url: urlBaseTiposdeDocumento + "/get_records",
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          response.data.forEach((obj) => {
            this.datoscompletos.push(obj)
            this.basereporte.push(obj.Reporte)
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => this.isLoading = false);
    },
    submitForm() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, { abortEarly: false })
        .then(() => {
          this.isLoading = true;
          if (this.dataForm.id) {
            this.updateReport();
          } else {
            this.createReport();
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    createReport() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, { abortEarly: false })
        .then(() => {
          this.isLoading = true;
          if (this.dataForm.id) {
            this.updateReport();
          } else {
            this.createReport();
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });

      const options = {
        method: 'post',
        url: urlBaseTiposdeDocumento + "/record_suscription",
        data: {
          "Periodo de ejecución": this.peridoseleccionado,
          "Reporte": this.reporteseleccionado,
          "SubReporte": this.subreporteseleccionado,
          "Formato": this.formatoseleccionado,
          "Fecha Inicio": this.fechainicioseleccionada,
          "Fecha creación": new Date(),
          "Hora de Ejecución": this.dataForm.executionHour,
          "Asunto": this.dataForm.subject,
          "Correos": this.dataForm.emails,
          "Mensaje": this.dataForm.content,
          "Activo": true,
            "Usuario": this.getUsuario()
        },
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push("/sendreportsv2");
          }
        })
        .catch((error) => {
          const data = error.response.data;
          if (error.response.status === 400 && data["subErrors"]) {
            for (const item of data["subErrors"]) {
              this.errors = { ...this.errors, [item.field]: item.message }
            }
          }
        })
        .finally(() => this.isLoading = false);
    },
    updateReport() {
      const options = {
        method: 'put',
        url: urlBaseTiposdeDocumento + "/record_suscription",
        data: this.getUpdateData(),
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 202) {
            this.dataForm.modifiedAt = response.data.modifiedAt;
            this.cancel();
          }
        })
        .catch((error) => {
          const data = error.response.data;
          if (error.response.status === 400 && data["subErrors"]) {
            for (const item of data["subErrors"]) {
              this.errors = { ...this.errors, [item.field]: item.message }
            }
          }
        })
        .finally(() => this.isLoading = false);
    },
    
    getUpdateData() {
      return {
        subject: this.dataForm.subject,
        content: this.dataForm.content,
        exportType: this.dataForm.exportType,
        reportType: this.dataForm.reportType,
        emails: this.dataForm.emails,
        isActive: this.dataForm.isActive,
      }
    },
    beforeAddingEmail(email) {
      if (email.match(validRegex)) {
        this.errors.emails = '';
        return true;
      }
      this.errors.emails = 'El email ingresado no es válido'
      return false;
    },
    onEmailChange(email) {
      if (email === '') {
        this.errors.emails = '';
      } else {
        this.beforeAddingEmail(email);
      }
    },
    changeState(value) {
      this.stateText = value ? 'Activo' : 'Inactivo';
    },
    changeExportOptions() {
      this.basesubreporte = []
      this.subreporteseleccionado=null,
      this.diligenciarformatos()      
    },
    diligenciarformatos() { 
      console.log("Iniciando")
      this.basesubreporte = []
      this.baseformato = []
      const options = {
        method: 'post',
        url: urlBaseTiposdeDocumento + "/get_format_by_type",
        data: {
          "Reporte": this.reporteseleccionado,
          "SubReporte": this.subreporteseleccionado
        },
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 401) {
            console.log("no hay")
          }
          if (response.status === 200) {
            const lengthx = response.data.Formatos.length;
            for (let x = 0; x < lengthx; x++) {
              this.baseformato.push(response.data.Formatos[x])
            }

          }
        })
        .catch((error) => {
          console.log(error)

        })
        .finally(() => this.isLoading = false);

        const optiones = {
        method: 'get',
        url: urlBaseTiposdeDocumento + "/get_subreport_by_report?id="+this.reporteseleccionado,
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(optiones)
        .then((response) => {
          if (response.status === 401) {
            console.log("no hay")
          }
          if (response.status === 200) {
            const length = parseInt(response.data.length, 10);
            for (let i = 0; i < length; i++) {
              this.basesubreporte.push(response.data[i].SubReporte);
            }
            
          
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => this.isLoading = false);
    },
    changeExportOptionstipes() {
      this.subReporteformatosanidados=null,
      this.diligenciarformatos()
    },
    resetErrorMessages() {
      for (const key in this.errors) {
        this.errors[key] = '';
      }
    },
    formatDate(date) {
      return dateFormatting(date);
    },
    deleteReport() {
      this.$buefy.dialog.confirm({
        title: 'Reporte automáticoaaa',
        message: '¿Está seguro de eliminar el reporte?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          const options = {
            method: 'delete',
            url: `${HOST_API}/report/${this.dataForm.id}`,
            headers: {
              Authorization: `Bearer ${this.$store.state.tokenAuth}`,
              'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
            },
          };
          axios(options)
            .then((response) => {
              setTimeout(() => {
                console.log(response)
                location.reload(); // Actualizar la página después de un pequeño retraso
              }, 100); // Ajusta el tiempo de espera según sea necesario
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    cancel() {
      if (!this.dataForm.id) {
        this.$router.push('/sendreportsv2');
      }
      this.editDisabled = true;
      this.isDisabled = true;
      this.exportOptionDisabled = true;
    },
    editar_nuevo_reporte() {
      this.loadReportData()
    },
    ...mapGetters(["getUsuario"])
  }
}
</script>

<style scoped>
p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}
</style>