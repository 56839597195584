<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/sendreports">
                <i class="bx bx-left-arrow"/>
              </b-button>
              <p class="title is-4 centered-text">{{ dataForm.id ? "Envío programado" : "Programar envío" }}</p>
            </div>
            <div>
              <b-button v-if="editDisabled" type="is-info" style="margin: 0 4px" @click="edit">
                Editar
              </b-button>
              <b-button v-if="dataForm.id" type="is-danger" @click="deleteReport">
                Eliminar
              </b-button>
            </div>
          </div>
          <br>
          <form v-on:submit.prevent="submitForm">
            <div class="columns">
              <b-field class="column" label="Periodo de ejecución"
                       :type="errors['typeTimeLapse'] ? 'is-danger' : ''" :message="errors['typeTimeLapse']">
                <!--                <b-input type="number" v-model="dataForm.timeLapse" @input="convertInputToNumber"-->
                <!--                         :disabled="editDisabled">-->
                <!--                </b-input>-->
                <b-select v-model="dataForm.typeTimeLapse" :disabled="isDisabled" expanded>
                  <option :key="option.type" v-for="option of timeLapseTypeOptions" :value="option.value">{{
                      option.label
                    }}
                  </option>
                </b-select>
              </b-field>
             

              <b-field class="column is-fullwidth" label="Reporte"
                      :type="errors['reportType'] ? 'is-danger' : ''" :message="errors['reportType']">
                <b-select v-model="dataForm.reportType" placeholder="Selecciona una opción"
                          @input="changeExportOptions" :disabled="editDisabled" expanded>
                  <option :key="option.type" v-for="option of reportTypeOptions" :value="option.value">{{ option.name }}</option>
                </b-select>
              </b-field>

              <b-field class="column is-narrow" label="Tipo de Log"
                      :type="errors['reportType'] ? 'is-danger' : ''" :message="errors['reportType']" v-show="dataForm.reportType === reportTypeOptions[reportTypeOptions.length - 1].value">
                <b-select v-model="dataForm.logType" placeholder="Selecciona una opción"
                          @input="changeExportOptions" expanded>
                  <option :key="option.type" v-for="option of logTypeOptions" :value="option.value">{{ option.name }}</option>
                  <option :value="'CLA'">Habilitación de tipos de documento</option>
                </b-select>
              </b-field>


              <b-field class="column" label="Formato"
                       :type="errors['reportType'] ? 'is-danger' : ''" :message="errors['exportType']">
                <b-select v-model="dataForm.exportType" expanded :disabled="exportOptionDisabled">
                  <option :key="option" v-for="option of exportTypeOptions" :value="option">{{
                      option
                    }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Fecha Inicio"
                       :type="errors['startDate'] ? 'is-danger' : ''" :message="errors['startDate']">
                <b-datepicker
                    v-model="dataForm.startDate"
                    placeholder="Seleccione la fecha"
                    icon="calendar-today"
                    :disabled="isDisabled">
                </b-datepicker>
              </b-field>
              <b-field class="column" label="Hora de Ejecución"
                       :type="errors['executionHour'] ? 'is-danger' : ''" :message="errors['executionHour']">
                <b-timepicker
                    rounded
                    v-model="dataForm.executionHour"
                    placeholder="Seleccione la hora"
                    icon="clock"
                    editable
                    :hour-format="'24'"
                    :disabled="isDisabled">
                </b-timepicker>
              </b-field>
              <!--              <b-field class="column is-5" label="Fecha Fin"-->
              <!--                       :type="errors['endDate'] ? 'is-danger' : ''" :message="errors['endDate']">-->
              <!--                <b-datepicker-->
              <!--                    :min-date="endDateConfig.minDate"-->
              <!--                    :focused-date="endDateConfig.minDate"-->
              <!--                    v-model="dataForm.endDate"-->
              <!--                    placeholder="Seleccione la fecha"-->
              <!--                    icon="calendar-today"-->
              <!--                    :disabled="endDateConfig.disabled">-->
              <!--                </b-datepicker>-->
              <!--              </b-field>-->
              <b-field class="column is-2" label="Estado"
                       :style="dataForm.id ? {'display':'block'} : {'display':'none'}">
                <div class="control">
                  <b-switch v-model="dataForm.isActive" @input="changeState" :disabled="editDisabled">{{
                      stateText
                    }}
                  </b-switch>
                </div>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Asunto"
                       :type="errors['subject'] ? 'is-danger' : ''" :message="errors['subject']">
                <b-input maxlength="200" name="subject" v-model="dataForm.subject"
                         placeholder="Ingrese el asunto" expanded :disabled="editDisabled"></b-input>
              </b-field>
              <b-field class="column" label="Correos"
                       :type="errors['emails'] ? 'is-danger' : ''" :message="errors['emails']">
                <b-taginput
                    v-model="dataForm.emails"
                    ellipsis
                    icon="label"
                    maxtags="10"
                    placeholder="Agregar correo"
                    aria-close-label="Delete this tag"
                    @typing="onEmailChange"
                    :before-adding="beforeAddingEmail"
                    :disabled="editDisabled">
                </b-taginput>
              </b-field>
            </div>
            <b-field label="Mensaje"
                     :type="errors['content'] ? 'is-danger' : ''" :message="errors['content']">
              <b-input maxlength="400" type="textarea" v-model="dataForm.content"
                       placeholder="Ingrese el mensaje" :disabled="editDisabled"></b-input>
            </b-field>
            <div v-if="dataForm.createdAt" class="columns">
              <div class="column">
                <p><strong>Fecha de creación:</strong> {{ formatDate(dataForm.createdAt) }}</p>
              </div>
              <div class="column">
                <p><strong>Fecha de actualización:</strong> {{ formatDate(dataForm.modifiedAt) }}</p>
              </div>
            </div>
            <div v-if="!editDisabled" style="display: flex">
              <div class="buttons" style="margin-right: 0; margin-left: auto">
                <b-button :loading="isLoading" type="is-danger" @click="cancel">
                  Cancelar
                </b-button>
                <b-button :loading="isLoading" type="is-primary"
                          native-type="submit">
                  Guardar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {
  string,
  date,
  object,
  array,
  setLocale
} from 'yup';
import {
  HOST_API,
  TIME_LAPSE_TYPE_OPTIONS,
} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import {dateFormatting} from "../../assets/config/utilities";

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

setLocale({
  mixed: {
    required: 'Es un campo obligatorio',
  },
  number: {
    min: 'El valor debe ser mayor o igual a ${min}',
    max: 'El valor debe ser menor o igual a ${max}'
  },
  array: {
    min: 'Debe ingresar ${min} o más emails',
    max: 'Debe ingresar ${max} o menos emails'
  }
});

const schema = object().shape({
  subject: string().required().max(200),
  content: string().required().max(400),
  emails: array().min(1).max(10),
  startDate: date().required(),
  // endDate: date().required(),
  executionHour: date().required(),
  // timeLapse: number().min(1).max(20).transform((_, val) => val === Number(val) ? val : null).typeError('Es un campo obligatorio'),
  reportType: string().required(),
  exportType: string().required(),
});

export default {
  name: "CreateReport",
  components: {
    MainLayout
  },
  data() {
    return {
      timeLapseTypeOptions: TIME_LAPSE_TYPE_OPTIONS,
      reportTypeOptions: [],
      exportTypeOptions: undefined,
      isLoading: false,
      exportOptionDisabled: true,
      // endDateConfig: {
      //   minDate: undefined,
      //   disabled: true
      // },
      editDisabled: false,
      isDisabled: false,
      stateText: 'Activo',
      dataForm: {
        isActive: true,
        subject: '',
        content: '',
        emails: [],
        startDate: undefined,
        endDate: undefined,
        executionHour: undefined,
        // timeLapse: 1,
        typeTimeLapse: TIME_LAPSE_TYPE_OPTIONS[0].value,
        exportType: undefined,
        reportType: undefined
      },
      errors: {
        subject: '',
        content: '',
        emails: '',
        startDate: '',
        // endDate: '',
        executionHour: '',
        // timeLapse: '',
        reportType: '',
        exportType: ''
      }
    }
  },
  mounted() {
    this.loadReportOptions();
    if (this.$route.params.id) {
      this.loadReportData(this.$route.params.id);
      this.editDisabled = true;
      this.isDisabled = true;
    }
  },
  methods: {
    loadReportOptions() {
      const options = {
        method: 'get',
        url: `${HOST_API}/report/options`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            this.reportTypeOptions = response.data;
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => this.isLoading = false);
    },
    submitForm() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, {abortEarly: false})
          .then(() => {
            this.isLoading = true;
            if (this.dataForm.id) {
              this.updateReport();
            } else {
              this.createReport();
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    },
    createReport() {
      const options = {
        method: 'post',
        url: `${HOST_API}/report`,
        data: this.cloneDataForm(),
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 201) {
              this.$router.push("/sendreports");
            }
          })
          .catch((error) => {
            const data = error.response.data;
            if (error.response.status === 400 && data["subErrors"]) {
              for (const item of data["subErrors"]) {
                this.errors = {...this.errors, [item.field]: item.message}
              }
            }
          })
          .finally(() => this.isLoading = false);
    },
    updateReport() {
      const options = {
        method: 'put',
        url: `${HOST_API}/report/${this.dataForm.id}`,
        data: this.getUpdateData(),
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 202) {
              this.dataForm.modifiedAt = response.data.modifiedAt;
              this.cancel();
            }
          })
          .catch((error) => {
            const data = error.response.data;
            if (error.response.status === 400 && data["subErrors"]) {
              for (const item of data["subErrors"]) {
                this.errors = {...this.errors, [item.field]: item.message}
              }
            }
          })
          .finally(() => this.isLoading = false);
    },
    cloneDataForm() {
      let copyData = Object.assign({}, this.dataForm);
      copyData.startDate = new Date(copyData.startDate.setHours(copyData.executionHour.getHours(), copyData.executionHour.getMinutes())).toISOString();
      // copyData.endDate = new Date(copyData.endDate.setHours(copyData.executionHour.getHours(), copyData.executionHour.getMinutes())).toISOString();
      delete copyData['executionHour'];
      return copyData;
    },
    getUpdateData() {
      return {
        subject: this.dataForm.subject,
        content: this.dataForm.content,
        exportType: this.dataForm.exportType,
        reportType: this.dataForm.reportType,
        emails: this.dataForm.emails,
        isActive: this.dataForm.isActive,
      }
    },
    beforeAddingEmail(email) {
      if (email.match(validRegex)) {
        this.errors.emails = '';
        return true;
      }
      this.errors.emails = 'El email ingresado no es válido'
      return false;
    },
    onEmailChange(email) {
      if (email === '') {
        this.errors.emails = '';
      } else {
        this.beforeAddingEmail(email);
      }
    },
    // setEndDateRange(value) {
    //   this.dataForm.endDate = undefined;
    //   let newDate = new Date(value.getTime())
    //   if (this.dataForm.typeTimeLapse === 'm') {
    //     this.endDateConfig.minDate = newDate.setMonth(newDate.getMonth() + 1)
    //   } else {
    //     this.endDateConfig.minDate = newDate.setDate(newDate.getDate() + 7)
    //   }
    //   this.endDateConfig.minDate = new Date(this.endDateConfig.minDate);
    //   if (this.endDateConfig.disabled) {
    //     this.endDateConfig.disabled = false;
    //   }
    // },
    // changeEndDate() {
    //   if (this.dataForm.startDate) {
    //     this.setEndDateRange(this.dataForm.startDate);
    //   }
    // },
    // convertInputToNumber(value) {
    //   this.dataForm.timeLapse = Number.parseInt(value);
    //   this.changeEndDate()
    // },
    changeState(value) {
      this.stateText = value ? 'Activo' : 'Inactivo';
    },
    changeExportOptions(value) {
      for (const item of this.reportTypeOptions) {
        if (item.value === value) {
          this.exportTypeOptions = item.exportOptions;
          this.exportOptionDisabled = false;
          this.dataForm.exportType = item.exportOptions[0];
          break
        }
      }
    },
    resetErrorMessages() {
      for (const key in this.errors) {
        this.errors[key] = '';
      }
    },
    formatDate(date) {
      return dateFormatting(date);
    },
    loadReportData(reportId) {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/report/${reportId}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              let reportData = response.data;
              const exportType = reportData.exportType;
              reportData.startDate = new Date(reportData.startDate);
              // reportData.endDate = new Date(reportData.endDate);
              // const endDate = reportData.endDate;
              reportData.executionHour = new Date(reportData.startDate.getTime());
              reportData.startDate = new Date(reportData.startDate.setHours(0, 0));
              this.dataForm = reportData;
              this.changeExportOptions(reportData.reportType);
              this.changeState(reportData.isActive);
              // this.setEndDateRange(reportData.startDate)
              this.dataForm.exportType = exportType;
              this.dataForm.typeTimeLapse = this.dataForm.typeTimeLapse.toUpperCase();
              // this.dataForm.endDate = endDate;
              this.exportOptionDisabled = true;
              // this.endDateConfig.disabled = true;
            }
          })
          .finally(() => this.isLoading = false);
    },
    deleteReport() {
      this.$buefy.dialog.confirm({
        title: 'Reporte automático',
        message: `Está seguro de eliminar el reporte?`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          const options = {
            method: 'delete',
            url: `${HOST_API}/report/${this.dataForm.id}`,
            headers: {
              Authorization: `Bearer ${this.$store.state.tokenAuth}`
            },
          }
          axios(options)
              .then((response) => {
                if (response.status === 204) {
                  this.$router.push('/sendreports');
                }
              })
              .finally(() => this.isLoading = false);
        }
      })
    },
    cancel() {
      if (!this.dataForm.id) {
        this.$router.push('/sendreports');
      }
      this.editDisabled = true;
      this.isDisabled = true;
      this.exportOptionDisabled = true;
    },
    edit() {
      this.editDisabled = false;
      this.exportOptionDisabled = false;
    }
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

</style>