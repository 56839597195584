<template>
    <main-layout itemMenuActive="31" tituloMenu="Logs de Auditoría">
        <br />
        <div class="container is-full">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column">
                    <div class="card">
                        <div class="card-content">
                            <b-field grouped label="Logs de Auditoría">
                                <b-field label="">
                                    <h1 for="" style="margin-right: 1rem; line-height: 2.5rem;">
                                        Tipo de Log:
                                    </h1>
                                    <b-select id="documento" placeholder="Seleccione un tipo de Log"
                                        v-model="opcionSeleccionada" expanded v-on:change.native="changeView">
                                        <option disabled value="Seleccione un tipo de Log">Seleccione un tipo de
                                            Log
                                        </option>
                                        <option value="habilitacion">Habilitación de documentos</option>
                                        <option value="parametrizacion">Parametrización tipos de documento</option>
                                        <option value="textParametrization">Parametrización de Textos</option>
                                        <option value="exportLogs">Exportación de Reportes</option>
                                        <option value="envioProgramados">Envío Programados</option>
                                        <option value="iconsParametrization">Parametrización de Iconos</option>
                                        <option value="asignacionRoles">Asignación roles</option>
                                    </b-select>
                                </b-field>
                                <div v-if="isAbleExport()" class="uk-navbar-right">
                                    <b-field label="">
                                        <b-button :disabled="!eventsExist" v-on:click="exportCsv"> CSV</b-button>
                                        <b-button :disabled="!eventsExist" v-on:click="exportXlsx"> Excel</b-button>
                                        <b-button :disabled="!eventsExist" v-on:click="exportPdf"> PDF</b-button>
                                    </b-field>
                                </div>
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="columns">
                                <div class="column is-2">
                                    <b-field label="Fecha">
                                        <b-datepicker placeholder="Seleccione Fecha" v-model="filters.dateRange"
                                            :max-date=maxDate locale="es-ES" range expanded>
                                        </b-datepicker>
                                    </b-field>
                                </div>
                                <div class="column is-3">
                                    <b-field label="Usuario">
                                        <b-select placeholder="Seleccione Usuario" v-model="filters.user" expanded>
                                            <option v-for="item in userList" :value="item" :key="item.id">
                                                {{ item.email }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-2">
                                    <b-field label="Sección">
                                        <b-select placeholder="Seleccione Sección" v-model="filters.section" expanded>
                                            <option v-for="item in sectionList" :value="item" :key="item._id">
                                                {{ item.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-3">
                                    <b-field label="Reporte">
                                        <b-select placeholder="Seleccione Reporte" v-model="filters.report"
                                            :disabled="!isSectionSelected" expanded>
                                            <option v-for="item in reportList" :value="item" :key="item.id">
                                                {{ item.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <div class="buttons">
                                        <b-button type="is-info" :loading="isLoading" @click="getEvents"
                                            :disabled="!existsFilters" expanded>
                                            Consultar
                                        </b-button>
                                        <b-button type="is-link" :loading="isLoading" @click="cleanFilters" expanded>
                                            Limpiar
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleTableCard v-if="eventsData" :columns="tableColumns" :dataList="eventsData.data"
                :total="eventsData.totalCount" :current-page="this.filters.page" :page-change="pageChange"></SimpleTableCard>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import axios from "axios";
import SimpleTableCard from "../../components/cards/SimpleTableCard.vue";
import {
    COLUMNS_EXPORT_LOGS_TABLE
} from "../../assets/config/constants/TableConstants";
import { dateFormatting } from "../../assets/config/utilities";
import {config} from "../../assets/config/jsonconfig.json";
import {saveLog} from "../../services/exportLogs";
const URL_DOCUMENT_TYPES = config.urlBaseTiposdeDocumento;
import { getToken } from "../../assets/config/getToken.js";

export default {
    name: "ExportLogs",
    components: {
        MainLayout,
        SimpleTableCard
    },
    data() {
        return {
            isLoading: false,
            events: [],
            filters: this.getEmptyFilters(),
            sectionList: [],
            reportList: [],
            userList: [],
            isSectionSelected: false,
            existsFilters: false,
            eventsData: undefined,
            maxDate: new Date(),
            tableColumns: COLUMNS_EXPORT_LOGS_TABLE,
            opcionSeleccionada: "exportLogs",
            roleList: this.getRoleIdList(),
            token: null
        };
    },
    watch: {
        sectionValue(val) {
            if (val) {
                this.reportList = val.reports;
                this.isSectionSelected = true;
            }
        },
        filters: {
            handler(val) {
                this.existsFilters = JSON.stringify(val) !== JSON.stringify(this.getEmptyFilters());
            },
            deep: true
        }
    },

    computed: {
        eventsExist() {
            return this.eventsData && this.eventsData.data.length !== 0;
        },
        sectionValue() {
            return this.filters.section;
        },
        ...mapState(["_HOST_SERVE"]),
    },
    async mounted() {
        this.token = await getToken(config.DOMINIO);
        this.loadSectionList()
        this.loadUserList()
    },

    methods: {
        ...mapGetters(["getRoleIdList"]),
        isAbleExport() {
          return this.roleList.includes(49)
        },
        loadSectionList() {
            this.isLoading = true;
            const options = {
                method: 'get',
                url: `${URL_DOCUMENT_TYPES}/export/logs/sections`,
                headers: {
                    'Authorization': `Bearer ${this.token.Bearer}`
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status === 200) {
                        this.sectionList = response.data.data;
                        console.log(this.sectionList)
                    }
                })
                .finally(() => this.isLoading = false);
        },
        loadUserList() {
            const options = {
                method: "get",
                uri: "auth/ListUsers",
            };
            this._axios(options)
                .then((response) => {
                    this.userList = response.data;
                })
                .finally(() => this.isLoading = false);
        },
        getEvents() {
            this.isLoading = true;
            this.eventsData = undefined;
            const options = {
                method: 'get',
                url: `${URL_DOCUMENT_TYPES}/export/logs`,
                headers: {
                    'Authorization': `Bearer ${this.token.Bearer}`
                },
                params: this.getParamsObj()
            }
            axios(options)
                .then((response) => {
                    if (response.status === 200) {
                        let responseData = response.data;
                        responseData.data = this.formatData(responseData.data)
                        this.eventsData = responseData;
                    }
                })
                .finally(() => this.isLoading = false);
        },
        exportCsv() {
            this.exportEventsFile("csv");
        },
        exportPdf() {
            this.exportEventsFile("pdf");
        },
        exportXlsx() {
            this.exportEventsFile("xlsx");
        },
        exportEventsFile(type) {
            const params = this.getParamsObj();
            const options = {
                method: 'get',
                url: `${URL_DOCUMENT_TYPES}/export/logs/${type}`,
                headers: {
                    'Authorization': `Bearer ${this.token.Bearer}`
                },
                params: params,
                responseType: 'blob'
            }
            axios(options)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    let fileName = `export_logs_${new Date().toLocaleDateString()}.${type}`;

                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    this.saveExportEvent(type);
                })
                .catch(error => {
                    console.log('error: ', error);
                });
        },
        getParamsObj() {
            let paramsObj = {}
            if (this.filters.dateRange.length !== 0) {
                paramsObj.firstDate = this.filters.dateRange[0].toISOString().slice(0, 10)
                paramsObj.lastDate = this.filters.dateRange[1].toISOString().slice(0, 10)
            }
            if (this.filters.user !== undefined) {
                paramsObj.user = this.filters.user.email
            }
            if (this.filters.report !== undefined) {
                paramsObj.reportId = this.filters.report.id
            }
            if (this.filters.section !== undefined) {
                paramsObj.sectionId = this.filters.section._id
            }
            if (this.filters.page) {
                paramsObj.page = this.filters.page
            }
            return paramsObj;
        },
        getEmptyFilters() {
            return {
                dateRange: [],
                user: undefined,
                report: undefined,
                section: undefined,
                page: 1
            }
        },
        pageChange(page) {
            this.filters.page = page;
            this.getEvents()
        },
        cleanFilters() {
            this.isSectionSelected = false;
            this.filters = this.getEmptyFilters();
            this.eventsData = undefined;
        },
        formatData(eventList) {
            for (let eventItem of eventList) {
                eventItem.createdAt = dateFormatting(eventItem.createdAt)
            }
            return eventList
        },
        changeView() {
            if (this.opcionSeleccionada === "habilitacion") {
                this.$router.push('/logs_habilitacion')
            }
            if (this.opcionSeleccionada === "parametrizacion") {
                this.$router.push('/ReportesParametrizacion')
            } else if (this.opcionSeleccionada === "textParametrization") {
                this.$router.push('/textParametrizationLogs')
            } else if (this.opcionSeleccionada === "exportLogs") {
                this.$router.push('/exportLogs')
            } else if (this.opcionSeleccionada === "envioProgramados"){
                this.$router.push('/envioProgramados')
            } else if (this.opcionSeleccionada === "iconsParametrization"){
                this.$router.push('/iconParametrizationLogs')
            } else if (this.opcionSeleccionada === "asignacionRoles"){
                this.$router.push('/asignacionRolesLogs')
            }

        },
        saveExportEvent(type) {
            let logData = {
                "user": this.getUsuario(),
                "sectionId": 4,
                "reportId": 1,
                "subReport": "Exportación de reportes",
                "subReportId": 4,
                "format": type === "xlsx" ? "excel" : type
            }
            if (this.filters.dateRange.length !== 0) {
                logData.startDate = this.filters.dateRange[0]
                logData.endDate = this.filters.dateRange[1]
            }
            saveLog(logData);
        },
        ...mapMutations(["nombreTituloMenu"]),
        ...mapActions(["_axios"]),
        ...mapGetters(["getUsuario"])
    },
};
</script>
<style></style>