<template>
  <main-layout itemMenuActive="31" tituloMenu="Logs de Auditoría">
    <br/>
    <div class="container is-full">
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <b-field grouped label="Logs de Auditoría">
                <b-field label="">
                  <h1 for="" style="margin-right: 1rem; line-height: 2.5rem;">
                    Tipo de Log:
                  </h1>
                  <b-select id="documento" placeholder="Seleccione un tipo de Log"
                            v-model="opcionSeleccionada" expanded v-on:change.native="changeView">
                    <option disabled value="Seleccione un tipo de Log">Seleccione un tipo de
                      Log
                    </option>
                    <option value="habilitacion">Habilitación de documentos</option>
                    <option value="parametrizacion">Parametrización tipos de documento</option>
                    <option value="textParametrization">Parametrización de Textos</option>
                    <option value="exportLogs">Exportación de Reportes</option>
                    <option value="envioProgramados">Envío Programados</option>
                    <option value="iconsParametrization">Parametrización de Iconos</option>
                    <option value="asignacionRoles">Asignación roles</option>
                  </b-select>
                </b-field>
                <div v-if="isAbleExport()" class="uk-navbar-right">
                  <b-field label="">
                    <b-button :disabled="!eventsExist" v-on:click="exportCsv"> CSV</b-button>
                    <b-button :disabled="!eventsExist" v-on:click="exportXlsx"> Excel</b-button>
                  </b-field>
                </div>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="columns">
                <div class="column is-3">
                    <b-field label="Fecha">
                        <b-datepicker placeholder="Seleccione Fecha" v-model="dateRange"
                            :max-date=maxDate locale="es-ES" range expanded @input="verificarParaBloquear" :disabled="blockInputs">
                        </b-datepicker>
                    </b-field>
                </div>
                <div class="column is-1">
                    <b-field label="Usuario">
                        <b-select placeholder="Seleccione Usuario" v-model="user" expanded v-on:change.native="verificarParaBloquear" :disabled="blockInputs">
                          <option disabled value="">Seleccione Usuario</option>
                          <option v-for="item in userList" :value="item" :key="item.id">
                              {{ item.email }}
                          </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                  <b-field label="Tipo de documento" vertical :type="formErrors.documentType ? 'is-danger' : ''"
                    :message="formErrors.documentType">
                    <b-select ref="tipoSelect" placeholder="Seleccione una opción" expanded v-model="documentType"
                      v-on:change.native="cambioTipo" :disabled="blockInputs">
                      <option value="">
                        Seleccione una opción
                      </option>
                      <option v-for="(value, key) in documentTypes" :value="value.tipo" :alfanumerico="value.alfa" :key="key">
                        {{ value.text }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-3">
                  <b-field vertical label="Número de documento" :type="formErrors.documentNumber ? 'is-danger' : ''"
                    :message="formErrors.documentNumber">
                    <b-input ref="numeroDocumento" pattern="[0-9]+" @input="verificarParaBloquear"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" name="subject"
                      expanded v-model="documentNumber" :disabled="blockInputs"></b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <div class="buttons">
                    <b-button type="is-info" :loading="isLoading" @click="consultar" :disabled="deshabilitarBtnConsultar" expanded>
                      Consultar
                    </b-button>
                    <b-button type="is-link" :loading="isLoading" @click="limpiar" :disabled="deshabilitarBtnLimpiar" expanded>
                      Limpiar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SimpleTableCard v-if="mostrarConsulta" 
        :columns="columnsConsultar" 
        :dataList="dataConsultar"
        :total="totalCount" 
        :current-page="page"
        :page-change="pageChange" 
        :detailed="true"
        :tableTypeDetails="'AsignacionRolesDetail'">
      </SimpleTableCard>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
//import {saveLog} from "../../services/exportLogs";
import SimpleTableCard from "../../components/cards/SimpleTableCard.vue";
import {config} from "../../assets/config/jsonconfig.json";
import { getToken } from "../../assets/config/getToken.js";

const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento;
//const URL_DOCUMENT_TYPES = 'https://compensar-sinconvivencia.infomediaservice.online/typedocuments/api/v1';
//const URL_DOCUMENT_TYPES = "https://seguridad.compensar.com/typedocuments/api/v1";//config.urlBaseTiposdeDocumentoV1;
const URL_DOCUMENT_TYPES = config.urlTiposdeDocumento + "v1";
export default {
  name: "asignacionRolesLogs",
  components: {
    SimpleTableCard,
    MainLayout,
  },
  data() {
    return {
      isLoading: false,
      maxDate: new Date(),
      opcionSeleccionada: "asignacionRoles",
      roleList: this.getRoleIdList(),
      deshabilitarBtnConsultar: true,
      deshabilitarBtnLimpiar: true,
      blockInputs: false,
      mostrarConsulta: false,

      columnsConsultar: [
        {
          field: 'nro',
          label: 'Nro',
          centered: true
        },
        {
          field: 'createdAt',
          label: 'Fecha y hora',
          centered: true
        },
        {
          field: 'user',
          label: 'Usuario',
          centered: true
        },
        {
          field: 'actionType',
          label: 'Acción',
          centered: true
        },
        {
          field: 'documentType',
          label: 'Tipo de identificación',
          centered: true
        },
        {
          field: 'documentNumber',
          label: 'Identificación',
          centered: true
        },
        {
          field: 'names',
          label: 'Cliente',
          centered: true
        }
      ],
      
      formErrors: {
        documentNumber: '',
        documentType: ''
      },

      userList: [],
      documentTypes: [],
      dateRange: [],
      dataConsultar: [],

      user: '',
      documentType: '',
      documentNumber: '',
      xApiKey: '87430357-185d-45e3-b3ac-c712a4c6411c',
      page: 1,
      totalCount: 0,
      token: null
    };
  },
  computed: {
    eventsExist() {
      return this.dataConsultar && this.dataConsultar.length !== 0;
    },
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.apiTiposDocumento().then((res) => {
      let opTipos = res.filter(tipo => {
        return tipo.empresa['Administracion de Delegados'] == true
      }).map(function (tipo) {
        return ({
          tipo: tipo.code.toLowerCase(),
          text: tipo.Title,
          alfa: tipo.AlfaNumerico,
        })
      }).sort((x, y) => x.text.localeCompare(y.text));

      this.documentTypes = opTipos;
    });
    this.loadUserList();
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    async apiTiposDocumento() {
      this.isLoading = true;
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento + '/tiposdedocumento',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        }
      }).then((res) => {
        this.isLoading = false;
        return res.data;
      }).catch(async (error) => {
        this.isLoading = false;
        console.error(error)
      })
    },
    loadUserList() {
      const options = {
        method: "get",
        uri: "auth/ListUsers",
      };
      this.isLoading = true;
      this._axios(options)
        .then((response) => {
          this.userList = response.data;
        })
        .finally(() => this.isLoading = false);
    },
    async apiConsultar(filter) {
      this.isLoading = true;
      let url = `${URL_DOCUMENT_TYPES}/delegates/roles/user/logs?page=${this.page}&size=${10}`;
      
      if (filter.documentType)
        url += `&documentType=${filter.documentType}`;

      if (filter.documentNumber)
        url += `&documentNumber=${filter.documentNumber}`;

      if (filter.user)
        url += `&user=${filter.user}`;

      if (filter.firstDate)
        url += `&firstDate=${filter.firstDate}`;

      if (filter.lastDate)
        url += `&lastDate=${filter.lastDate}`;
      
      return await axios({
        method: 'get',
        url: url,
        headers: {
          'x-api-key': this.xApiKey,
          'Authorization': `Bearer ${this.token.Bearer}`
        },
      }).then((res) => {
        this.isLoading = false;
        return res.data;
      }).catch(async (error) => {
        this.isLoading = false;
        console.error(error)
      })
    },
    decodificarValores(data) {
      for (const i in data) {
        const valor = data[i]
        valor.nro = (parseInt(i) +1);

        let cadena = '';
        for (const i in valor.lastRoleList) {
          const item = valor.lastRoleList[i];
          cadena += item.id +'-'+ item.roleName +'; ';
        }
        valor.lastRoleList = cadena;

        cadena = '';
        for (const i in valor.newRoleList) {
          const item = valor.newRoleList[i];
          cadena += item.id +'-'+ item.roleName +'; ';
        }
        valor.newRoleList = cadena;
      }
    },
    async consultar() {
      let valor = await this.apiConsultar(this.obtenerParametros());
      this.dataConsultar = valor.data;
      this.decodificarValores(this.dataConsultar);

      this.totalCount = valor.totalCount;

      this.blockInputs = true;
      this.deshabilitarBtnConsultar = true;
      this.deshabilitarBtnLimpiar = false;
      this.mostrarConsulta = true;
    },
    obtenerParametros() {
      let filter = {}
      if (Array.isArray(this.dateRange)) {
        if (this.dateRange.length > 0) {
          filter['firstDate'] = this.dateRange[0].toISOString().slice(0, 10)
          filter['lastDate'] = this.dateRange[1].toISOString().slice(0, 10)
        }
      }

      if (this.documentType) {
        filter['documentType'] = this.documentType.toLowerCase();
      }

      if (this.documentNumber) {
        filter['documentNumber'] = this.documentNumber;
      }

      if (this.user) {
        filter['user'] = this.user.userName;
      }
      return filter;
    },
    async pageChange(page) {
      this.page = page;
      let valor = await this.apiConsultar(this.obtenerParametros());
      this.dataConsultar = valor.data;
      this.decodificarValores(this.dataConsultar);

      this.totalCount = valor.totalCount;
    },
    limpiar() {
      this.dateRange = [];
      this.dataConsultar = [];
      this.user = '';
      this.documentType = '';
      this.documentNumber = '';
      this.deshabilitarBtnLimpiar = true;
      this.blockInputs = false;
      this.mostrarConsulta = false;
      this.verificarParaBloquear();
    },
    isAbleExport() {
      return this.roleList.includes(49)
    },
    cambioTipo() {
      let alfaNumerico = this.$refs.tipoSelect.$refs.select.selectedOptions[0].getAttribute('alfanumerico');
      let pattern = '[0-9]+';
      let oninput = "this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\..*)\\./g, '$1');";
      if (alfaNumerico == 'true') {
        pattern = '[0-9A-Za-zÑñ]+';
        oninput = "this.value = this.value.replace(/[^0-9A-Za-zÑñ]/g, '');";
      }
      this.$refs.numeroDocumento.$refs.input.pattern = pattern;
      this.$refs.numeroDocumento.$refs.input.setAttribute('oninput', oninput);
      this.documentNumber = ''
      this.verificarParaBloquear();
    },
    verificarParaBloquear() {
      if (this.dateRange.length > 0 || this.user) {
        this.deshabilitarBtnConsultar = false;
      } else {
        this.deshabilitarBtnConsultar = true;
      }

      if (this.documentNumber && this.documentType) {
        this.deshabilitarBtnConsultar = false;
      }

      if ((this.documentNumber && !this.documentType) || (!this.documentNumber && this.documentType)) {
        this.deshabilitarBtnConsultar = true;
      }
    },
    exportCsv() {
      this.exportEventsFile("csv");
    },
    exportXlsx() {
      this.exportEventsFile("xlsx");
    },
    exportEventsFile(type) {
      const options = {
        method: 'get',
        url: `${URL_DOCUMENT_TYPES}/delegates/roles/user/logs/${type}`,
        params: this.obtenerParametros(),
        responseType: 'blob',
        headers: {
          'x-api-key': this.xApiKey,
          'Authorization': `Bearer ${this.token.Bearer}`
        }
      }
      axios(options).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName = `asignacion_roles_logs_${new Date().toLocaleDateString()}.${type}`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
        //this.saveExportEvent(type);
      }).catch(error => {
        console.error('error exportEventsFile(): ', error);
      });
    },
    changeView() {
      if (this.opcionSeleccionada === "habilitacion") {
        this.$router.push('/logs_habilitacion')
      }
      if (this.opcionSeleccionada === "parametrizacion") {
        this.$router.push('/ReportesParametrizacion')
      } else if (this.opcionSeleccionada === "textParametrization") {
        this.$router.push('/textParametrizationLogs')
      } else if (this.opcionSeleccionada === "exportLogs") {
        this.$router.push('/exportLogs')
      } else if (this.opcionSeleccionada === "envioProgramados") {
        this.$router.push('/envioProgramados')
      } else if (this.opcionSeleccionada === "iconsParametrization") {
        this.$router.push('/iconParametrizationLogs')
      } else if (this.opcionSele === "asignacionRoles"){
        this.$router.push('/asignacionRolesLogs')
      }
    },
    /*saveExportEvent(type) {
      let logData = {
        "user": this.$store.getters.getUsuario,
        "sectionId": 4,
        "reportId": 1,
        "subReport": "Asignación roles",
        "subReportId": 7,
        "format": type === "xlsx" ? "excel" : type
      }
      if (this.dateRange.length !== 0) {
        logData.startDate = this.dateRange[0]
        logData.endDate = this.dateRange[1]
      }
      saveLog(logData);
    },*/
    ...mapActions(["_axios"])
  },
};
</script>
<style></style>