import dataConfig from "@/assets/config/jsonconfig.json"

export const DOCUMENT_TYPES = {
    cc: 'Cedula de Ciudadanía',
    ce: 'Cedula de Extranjería',
    pe: 'Permiso Especial',
    pt: 'Permiso Temporal',
    ni: 'NIT',
}

export const DATE_FORMAT_OPTIONS = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
}

export const STATES = {
    Pending: {
        label: 'Pendiente',
        tag: 'is-warning'
    },
    Canceled: {
        label: 'Cancelado',
        tag: 'is-warning'
    },
    Restore: {
        label: 'Restaurado',
        tag: 'is-info'
    },
    Rejected: {
        label: 'Rechazado',
        tag: 'is-danger'
    },
    Ended: {
        label: 'Finalizado'
    },
    Risky: {
        label: 'Arriesgada',
        tag: 'is-danger'
    },
    Replaced: {
        label: 'Reemplazada',
        tag: 'is-info'
    },
    'Accepted by operator': {
        label: 'Aceptada por el operador',
        tag: 'is-info'
    },
    'Rejected by operator': {
        label: 'Rechazada por el operador',
        tag: 'is-danger'
    },
    'User anonymized': {
        label: 'Usuario anónimo',
        tag: 'is-danger'
    },
    'Risky by spoofing': {
        label: 'Riesgo de suplantación',
        tag: 'is-danger'
    }
}

export const MESSAGE_CODES = {
    201: 'Validación Exitosa de Contraseña',
    100: 'Usuario no existente',
    101: 'Usuario existente',
    106: 'Nombre de Usuario muy corto',
    150: 'Contraseña inexistente',
    152: 'Contraseña bloqueada',
    153: 'Se superó el límite de intentos',
    154: 'Validación Fallida de Contraseña',
    165: 'Fallo desencriptando la contraseña',
    956: 'Fecha no válida'
}

export const MESSAGE_CODES_USER_REGISTER = {
    200: 'Contraseña Añadida Exitosamente',
    201: 'Contraseña Añadida Exitosamente',
    100: 'Usuario no existente',
    101: 'Usuario existente',
    105: 'Usuario caracteres inválidos',
    106: 'Nombre de Usuario muy corto',
    152: 'Contraseña bloqueada',
    153: 'Se superó el límite de intentos',
    154: 'Validación Fallida de Contraseña',
    160: 'Contraseña utilizada anteriormente',
    164: 'Ya existe la contraseña en la lista de contraseñas prohibidas',
    165: 'Fallo desencriptando la contraseña',
    166: 'Contraseña prohibida',
    167: 'La contraseña no puede contener el número de telefono',
    168: 'La contraseña no puede contener el documento de identidad',
    169: 'La contraseña no puede contener la fecha de nacimiento',
    601: 'Nuevo Usuario, Nueva contraseña con política',
    603: 'Usuario existente,  Nueva contraseña con política',
    605: 'Usuario existente, Contraseña existente con política',
    956: 'Fecha no válida',
    2077: 'La contraseña no puede contener la fecha de nacimiento',
    2073: 'La contraseña existe en la lista de contraseñas prohibidas',
    2063: 'La contraseña contiene caracteres invalidos'
}

export const MESSAGE_CODES_USER_MODIFY = {
    201: 'Contraseña Modificada Exitosamente',
    100: 'Usuario no existente',
    106: 'Nombre de Usuario muy corto',
    150: 'Contraseña inexistente',
    152: 'Contraseña bloqueada',
    153: 'Se superó el límite de intentos',
    154: 'Validación Fallida de Contraseña',
    160: 'Contraseña utilizada anteriormente',
    165: 'Fallo desencriptando la contraseña'
}

export const TIME_LAPSE_TYPE_OPTIONS = [
    {
        label: 'Semanal',
        value: 'W'
    }, {
        label: 'Mensual',
        value: 'M'
    }
]
export const TIME_LAPSE_TYPE_OPTIONSv2 = [
    {
        label: 'Semanal',
        value: 'Semanal'
    }, {
        label: 'Mensual',
        value: 'Mensual'
    }
]

export const MESSAGE_CODES_REGISTER = new Map([
    [100, 'Usuario no existente'],
    [101, 'Usuario existente'],
    [105, 'Usuario caracteres inválidos'],
    [106, 'Nombre de Usuario muy corto'],
    [114, 'No existe pais negocio'],
    [152, 'Contraseña bloqueada'],
    [153, 'Se superó el límite de intentos'],
    [154, 'Validación Fallida de Contraseña'],
    [157, 'Contraseña con caracteres inválidos'],
    [158, 'Contraseña no alcanza el límite mínimo de caracteres'],
    [160, 'Contraseña utilizada anteriormente'],
    [164, 'Ya existe la contraseña en la lista de contraseñas prohibidas'],
    [165, 'Fallo desencriptando la contraseña'],
    [166, 'Contraseña prohibida'],
    [167, 'La contraseña no puede contener el número de telefono'],
    [168, 'La contraseña no puede contener el documento de identidad'],
    [169, 'La contraseña no puede contener la fecha de nacimiento'],
    [601, 'Nuevo Usuario, Nueva contraseña con política'],
    [603, 'Usuario existente,  Nueva contraseña con política'],
    [605, 'Usuario existente, Contraseña existente con política'],
    [956, 'Fecha no válida'],
])

export const VU_SOP_ESTADOS = new Map([
    ["Pending","Pendiente"],
    ["Canceled","Cancelado"],
    ["Restore","Restaurado"],
    ["Rejected","Rechazado"],
    ["Ended","Finalizado"],
    ["Risky","Riesgoso"],
    ["Replaced","Reemplazado"],
    ["Accepted by operator","Aceptado por el operador"],
    ["Rejected by operator","Rechazado por el operador"],
    ["User anonymized","Anonimizado"],
    ["Risky by spoofing","Riesgoso por spoofing"],
])

export const VU_FACE_ERROR_LABELS = new Map([
    ["LOGIN_OK","1002: El login se realizó con éxito."],
    ["FACE_NOT_FOUND","2001: No se detectó rostro en la selfie."],
    ["ML_FACE_ANTISPOOFING_FAIL","2024: La imagen no superó el Machine Learning Antispoofing de rostro."],
    ["FACE_NO_GLASSES_FAIL","2035: La selfie no supera la \"Validación anteojos\"."],
    ["ML_FACE_ANTISPOOFING_NO_INFORMATION","No obtenemos información de servicio de antispoofing por distintos motivos(404,500,etc)"]
])

export const VU_SOP_ERROR_LABELS = new Map([
    ["END_OPERATION_FAIL", "904: La operación no finalizó con éxito ya que no superó el umbral de aceptación configurado para ese negocio"],
    ["END_OPERATION_EMPTY_FAIL", "905: La operación no finalizó con éxito porque no tiene imagen o información del documento"],
    ["END_OPERATION_BARCODE_NOT_EXISTS", "1910: La operación no finalizó con éxito debido a que no supera la validación de existencia de Barcode"],
    ["END_OPERATION_DOCUMENT_EXPIRATED", "1911: La operación no finalizó con éxito debido a que no supera la validación de documento expirado  según la tolerancia configurada"],
    ["END_OPERATION_FRONT_BACK_NOT_BELONG", "1907: La operación no finalizó con éxito debido a que no supera la validación de la información de frente y dorso del documento según OCR, MRZ y Barcode"],
    ["OPERATION_DOESNT_BELONG", "953: La operación no existe para ese username"],
    ["END_OPERATION_RESPONSE_FAIL", "1920: La operación no finalizó con éxito debido a existe un error en la respuesta"],
    ["CANCEL_OPERATION_FAIL", "907: La operación no pudo ser cancelada"],
    ["ADD_FRONT_FAIL", "9090: El frente del documento no se pudo agregar debido a un error no especificado"],
    ["ADD_FRONT_ML_DOCUMENT_FACE_ANTISPOOFING_FAIL", "9091: La imagen del frente del documento no superó el Machine Learning Antispoofing de rostro en documento"],
    ["ADD_FRONT_ML_ANTISPOOFING_FAIL", "910: La imagen del frente del documento no superó el Machine Learning Antispoofing"],
    ["FRONT_COUNTRY_DONT_MATCH", "967: El país identificado en el frente del documento no corresponde a un país configurado para ese negocio"],
    ["GOVERNMENT_PROXY_REST_INFORMATION_FAILED", "11121: La información del ciudadano no se obtuvo."],
    ["INFORMATION_PERSON_GOVERNMENT_SERVICE_NOT_VALID_DOCUMENT", "13104: El documento no es válido"],
    ["FRONT_ALREADY_EXIST", "911: El frente del documento no se pudo agregar debido a que ya existe una imagen del frente del documento para esa operación"],
    ["SCORE_EMPTY_FRONT", "935: El chequeo del score falló. No se encuentra imagen de frente del documento"],
    ["FRONT_BACK_COUNTRY_DONT_MATCH", "965: El frente y dorso del documento son de distintos paises"],
    ["FRONT_BACK_COUNTRY_VERSION_DONT_MATCH", "966: El frente y dorso del documento corresponden a distintas versiones de documento"],
    ["ADD_BACK_FAIL", "9120: Error no especificado al agregar el dorso del documento"],
    ["BACK_ALREADY_EXIST", "914: Ya existe una imagen del dorso de documento registrada para esa operación"],
    ["FACE_IMAGE_MONOCHROMATIC_BALANCE_FAIL", "2025: La selfi no supera la validación de balance monocromático"],
    ["FACE_NOT_FOUND", "2001: No se detectó rostro en la selfi"],
    ["ANALYZE_DOCUMENT_FAIL", "964: El alta del documento falló"],
    ["GET_DOCUMENT_INFORMATION_EMPTY_FAIL", "992: No se pudo obtener la información del documento"],
    ["PONDERATION_COMPONENT_REQUIRED_NOT_PRESENT", "1301: No es encuentra un componente requerido para la ponderación"],
    ["FACE_AGAINST_REGISTERED_INVALID", "2015: No se superó la validación de la selfie neutral (SN) enviada contra la SN ya registrada, debido a que los rostros no se parecen."],
    ["IMAGE_NOT_VALID", "960: La imagen enviada no es válida"],
    ["FACE_SERVICE_FAIL", "2006: El request al endpoint para biometría gubernamental no está disponible."],
    ["OPERATION_DISABLED", "954: La operación debe estar en estado PENDIENTE"],
    ["FACE_IMAGE_ML_ANTISPOOFING_FAIL", "2032: La selfie no supera la validación de Machine Learning antispoofing"],
    ["CROSSCHECK_FAIL", "3011: El crosscheck no fue superado"],
    ["CROSSCHECK_OK", "3010: El chequeo cruzado de fotos fue superado "],
    ["INTERNAL_FAIL", "931: Código utilizado en distintos casos"],
    ["OPERATION_USER_LOCKED", "1101: El usuario está bloqueado"],
    ["FACE_AGAINST_REGISTERED_FAIL", "2016: No se superó la validación de la selfie neutral (SN) enviada contra la SN ya registrada, debido a un error"],
    ["FACE_IMAGE_BACKGROUND_ICAO_FAIL", "2023: La selfie no supera la validación de \"Fondo\"."],
    ["DOCUMENT_UNKNOWN_ERROR", "Error desconocido"],
    ["FACE_NO_GLASSES_FAIL", "2035: La selfie no supera la \"Validación anteojos\"."],
    ["DOCUMENT_FACE_IMAGE_NOT_DETECTED", "9006: Rostro no detectado en el documento"],
    ["FACE_IMAGE_FLASHLIGHT_SPOT_FAIL", "2030: La selfie no supera la validación de \"Flash\"."],
    ["END_OPERATION_BIOMETRICS_COMPARE_FAIL", "1930: No ha sido superado el score biometrico."],
    ["GOVERNMENT_PROXY_REST_CONFIGURATION_REQUEST_FAILED", "10101: La configuración no se obtuvo."],
    ['ADD_FRONT_OK','909: El frente del documento se agregó con éxito.'],
    ['ADD_ANOMALIES_FAIL','926: La información de las anomalías no se pudo agregar.'],
    ['ADD_ANOMALIES_OK','925: La información de las anomalías se agregó con éxito.'],
    ['ADD_ATTACHMENT_FAIL','946: El archivo adjunto no se pudo agregar.'],
    ['ADD_ATTACHMENT_OK','945: El archivo adjunto se agregó correctamente.'],
    ['ADD_BACK_ML_ANTISPOOFING_FAIL','913: La imagen del dorso del documento no superó el Machine Learning Antispoofing de rostro en documento.'],
    ['ADD_BACK_ML_DOCUMENT_FACE_ANTISPOOFING_FAIL','9094: La imagen del documento no superó el Machine Learning Antispoofing de rostro en documento.'],
    ['ADD_BACK_OK','912: El dorso del documento se agregó con éxito.'],
    ['ADD_BARCODE_FAIL','921: La información del Barcode no se pudo agregar. Los nodos "document" y "data" están vacíos.'],
    ['ADD_BARCODE_OK','920: La información del Barcode se agregó con éxito.'],
    ['ADD_DOCUMENTDATA_OK','961: La información de la persona fue agregada con éxito.'],
    ['ADD_DOCUMENTIMAGE_FAIL','939: La imagen del documentó no se agregó debido a un error no especificado.'],
    ['ADD_DOCUMENTIMAGE_OK','938: La imagen del documento se agregó con éxito.'],
    ['END_OPERATION_OK','903: La operación finalizó con éxito.'],
    ['NEW_OPERATION_OK','901: La operación se creó con éxito.'],
    ['CANCEL_OPERATION_OK','906: La operación fue cancelada.'],
    ['ADD_OCR_FAIL','916: La información del OCR no se pudo agregar. Los nodos "document" y "data" están vacíos.'],
    ['ADD_OCR_OK','915: La información del OCR se agregó con éxito.'],
    ['ADD_SELFIES_OK','932: Las selfies se agregaron con éxito'],
    ['ADD_SELFIES_FAIL','Las selfies no se agregaron con éxito'],
    ['ADD_BACK_DP_FAIL',"9093: Documento no reconocido"],
    ['ADD_FRONT_DP_FAIL','9092: Tamaño de imagen incorrecto'],
    ['ML_FACE_ANTISPOOFING_FAIL','1930: No ha sido superado el score biometrico'],
    ['ADD_FRONT_CITIZEN_PICTURE_REQUIRED','9097: DP no devuelve la foto del ciudadano recortada y es requerida'],
    ['ML_FACE_ANTISPOOFING_NO_INFORMATION','2043: No obtenemos información de servicio de antispoofing por distintos motivos(404,500,etc)'],
    ['GENERIC_DOCUMENT_DISABLED','1971: Validación de documentos genericos deshabilitada'],
    ['GET_OPERATION_INFORMATION_FAIL','997: La información de la operación no se pudo obtener debido a un error no especificado.'],
    ['GET_OPERATION_INFORMATION_OK','996: La información de la operación se obtuvo con éxito.'],
    ['UPDATE_OPERATION_STATUS_OK','La actualización del estado de la operación se realizó con éxito.']
])

export const VU_SERVICE_PROVIDER = new Map([
    
    ['ORC-TST-SP','Tienda'],
    ['ORC-SP','Tienda'],
    ['HER-SP','Hercules'],
    ['PORTALCARTERA-SP','Portal Cartera'],
    ['PORTALCARTERA1-SP','Portal Cartera'],
    ['PORTALCARTERA2-SP','Portal Cartera'],
    ['PORTALCARTERA4-SP','Portal Cartera'],
    ['PORTALCARTERA5-SP','Portal Cartera'],
    ['TRN-I-SP','Transar'],
    ['TRNMISFIN-II-SP','Transar'],
    ['TRNMISFIN-SP','Transar'],
    ['CRED-II-SP','Credito'],
    ['TRN-MD-II','Transar'],
    ['TRNMD-PRU-SP','Transar'],
    ['WSFED-SP','Portal Corporativo'],
    ['WSFED1-SP','Portal Corporativo'],
    ['APP-SALUD-OIDC','Portal Salud'],
    ['APP-SALUD-SP','App Salud'],
    ['CREDITODIGITAL-OIDC','Credito digital'],
    ['TRANSAR-MISDATOS-OIDC1','Transar'],
    ['TRANSAR-MISCERTIFICACIONES-OIDC','Transar'],
    ['TRANSAR-MISAFILIACIONES-OIDC','Transar'],
    ['TRANSAR-MISFINANZAS-OIDC','Transar'],
    ['TRANSAR-MISDATOS1-OIDC','Transar'],
    ['TRANSAR-CLASICO-OIDC1','Transar'],
    ['TRANSAR-MIREGISTRO-OIDC1','Transar'],
    ['TRANSAR-MIREGISTRO-OIDC','Transar'],
    ['TRANSAR-MISDATOS-OIDC','Transar'],
    ['TRANSAR-CLASICO-OIDC','Transar'],
    ['BMOVIL-OIDC','App Billetera'],
    ['BMOVIL-SP','App Billetera'],
    ['ONBOARDING-OIDC','Ciam'],
    ['CAMBIOPASSWORD-OIDC','Cambio Password'],
    ['DEL-OIDC','Delegados'],
    ['WSFED-OIDC','Portal Corporativo'],
    ['TRANSAR-MIS-DATOS2-SP','Transar'],
    ['TRANSAR-MISCERTIFICACIONES-SP','Transar'],
    ['TRANSAR-MI-REGISTRO-SP','Transar'],
    ['TRANSAR-MISFINANZAS-SP','Transar']
])


export const HOST_API = dataConfig.config.urlBaseAPIMails;
// export const HOST_API = 'https://compensar-sinconvivencia.infomediaservice.online/api-correos/api';
// export const HOST_API = 'http://localhost:8080/api';