<template>
  <main-layout :itemMenuActive="19" tituloMenu="Administración de usuarios">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between; align-items: center">

            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/users">
                <i class="bx bx-left-arrow"/>
              </b-button>
              <p class="title is-4 centered-text">{{ dataForm.id ? "Usuario" : "Crear usuario" }}</p>
            </div>
            <div v-if="isEditable()">
              <b-button v-if="editDisabled" type="is-info" style="margin: 0 4px"
                        @click="() => this.editDisabled = false">
                Editar
              </b-button>
              <b-button v-if="dataForm.id" type="is-danger" @click="deleteUser">
                Eliminar
              </b-button>
            </div>
          </div>
          <div class="column is-gapless">
            <form v-on:submit.prevent="submitForm">
              <div class="columns">
                <b-field class="column" label="Nombre"
                         :type="errors['firstName'] ? 'is-danger' : ''" :message="errors['firstName']">
                  <b-input type="text" v-model="dataForm.firstName" :disabled="editDisabled"/>
                </b-field>
                <b-field class="column" label="Apellido"
                         :type="errors['lastName'] ? 'is-danger' : ''" :message="errors['lastName']">
                  <b-input type="text" v-model="dataForm.lastName" :disabled="editDisabled"/>
                </b-field>
              </div>
              <div class="columns">
                <b-field class="column is-two-thirds" label="Correo"
                         :type="errors['email'] ? 'is-danger' : ''" :message="errors['email']">
                  <b-input type="email" v-model="dataForm.email" :disabled="editDisabled"/>
                </b-field>
                <b-field class="column" label="Perfil"
                         :type="errors['profileId'] ? 'is-danger' : ''" :message="errors['profileId']">
                  <b-select placeholder="Seleccione una opción" v-model="dataForm.profileId" required expanded
                            :disabled="editDisabled">
                    <option :key="item.id" v-for="item in profileList" :value="item.id">{{ item.name }}</option>
                  </b-select>
                </b-field>
              </div>
              <div class="columns" v-if="dataForm.id && !editDisabled">
                <b-field style="margin-right: 0; margin-left: auto;">
                  <b-checkbox v-model="passwordEnabled">
                    Cambiar contraseña
                  </b-checkbox>
                </b-field>
              </div>
              <div v-if="!editDisabled && passwordEnabled" class="columns">
                <b-field class="column"
                         :type="errors['password'] ? 'is-danger' : ''" :message="errors['password']">
                  <template #label>
                    Contraseña
                    <b-tooltip type="is-dark" position="is-right">
                      <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                      <template v-slot:content>
                        <ul>
                          <li>Logitud entre 8 y 16 caracteres</li>
                          <li>Al menos una letra (a-z ó A-Z)</li>
                          <li>Al menos un número (0-9)</li>
                          <li>Al menos una caracter especial (#{@$%&)</li>
                        </ul>
                      </template>
                    </b-tooltip>
                  </template>
                  <b-input type="password" v-model="dataForm.password" :disabled="editDisabled"/>
                </b-field>
                <b-field class="column" label="Confirmar contraseña"
                         :type="errors['confirmPassword'] ? 'is-danger' : ''" :message="errors['confirmPassword']">
                  <b-input type="password" v-model="dataForm.confirmPassword" @input="validatePasswords"
                           :disabled="editDisabled"/>
                </b-field>
              </div>
              <div v-if="!editDisabled" class="columns">
                <!--                  <div class="buttons is-half is-offset-one-quarter" style="margin-right: auto; margin-left: auto">-->
                <div class="column is-half is-offset-one-quarter">
                  <div class="columns">
                    <b-button v-if="dataForm.id" style="margin-right: 10px" class="column" :loading="isLoading"
                              type="is-danger"
                              @click="() => this.editDisabled = true" expanded>
                      Cancelar
                    </b-button>
                    <b-button class="column" :loading="isLoading" type="is-primary"
                              native-type="submit" expanded>
                      Guardar
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {number, object, ref, setLocale, string} from "yup";
import {mapActions, mapGetters} from "vuex";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  },
  string: {
    min: 'El valor debe tener al menos ${min} caracteres',
    max: 'El valor debe tener maximo ${max} caracteres'
  }
});

const schema = object().shape({
  firstName: string().required().max(200),
  lastName: string().required().max(200),
  email: string().email().required(),
  password: string().when('$update', (update, passSchema) =>
      update ? passSchema : passSchema.required().min(8).max(16).matches(
          /^(?=.*[a-zA-Z])/,
          "Debe tener al menos una letra"
      ).matches(
          /^(?=.*[\d])/,
          "Debe tener al menos un número"
      ).matches(
          /^(?=.*[^a-zA-Z\d])/,
          "Debe tener al menos una caracter especial"
      )
  ),
  confirmPassword: string().oneOf([ref('password')], 'Las contraseñas no coinciden').when('$update', (update, passSchema) =>
      update ? passSchema : passSchema.required()),
  profileId: number().required()
});

export default {
  name: "UserDetail",
  components: {
    MainLayout
  },
  data() {
    return {
      isLoading: false,
      editDisabled: false,
      passwordEnabled: true,
      profileList: [],
      dataForm: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
        profileId: undefined
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        profileId: ''
      },
      roleList: this.getRoleIdList()
    }
  },
  mounted() {
    this.loadProfiles();
    if (this.$route.params.id) {
      this.editDisabled = true;
      this.passwordEnabled = false;
      this.loadUser(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions(['_axios']),
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.roleList.includes(43)
    },
    submitForm() {
      this.resetErrorMessages();
      if (!this.passwordEnabled) {
        this.dataForm.password = '';
        this.dataForm.confirmPassword = '';
      }
      schema.validate(this.dataForm, {
        abortEarly: false,
        context: {update: this.dataForm.id !== undefined && !this.passwordEnabled}
      })
          .then(() => {
            this.isLoading = true;
            if (this.dataForm.id) {
              this.updateUser();
            } else {
              this.createUser();
            }
          })
          .catch((err) => {
            err.inner.forEach((error) => {
              this.errors = {...this.errors, [error.path]: error.message};
            });
          });
    },
    createUser() {
      const options = {
        method: "post",
        uri: "auth/signup",
        data: this.getData()
      };
      this._axios(options).then(res => {
        if (res.status === 201) {
          this.$buefy.toast.open({
            message: 'Usuario creado exitosamente!',
            type: 'is-success'
          });
          this.$router.push("/users");
        }
      }).catch((e) => {
        let errorMessage = e.response.data.detail;
        if (errorMessage.includes('is already taken')) {
          errorMessage = `El correo "${this.dataForm.email}" ya esta en uso`
        }
        this.$buefy.toast.open({
          message: ` <b>${errorMessage}</b>`,
          type: 'is-danger'
        })
      }).finally(() => this.isLoading = false)
    },
    updateUser() {
      let formData = this.getUpdateData();
      if (!this.passwordEnabled) {
        delete formData['NewPassword'];
      }
      console.log(this.getUpdateData())
      const options = {
        method: "post",
        uri: "auth/UpdateUser",
        data: formData
      };
      this._axios(options).then(res => {
        if (res.status === 200) {
          this.$buefy.toast.open({
            message: 'Usuario actualizado exitosamente!',
            type: 'is-success'
          });
          this.dataForm.password = '';
          this.dataForm.confirmPassword = '';
          this.editDisabled = true;
          this.passwordEnabled = false;
        }
      }).catch((e) => {
        let errorMessage = e.response.data.detail;
        if (errorMessage.includes('is already taken')) {
          errorMessage = `El correo "${this.dataForm.email}" ya esta en uso`
        }
        this.$buefy.toast.open({
          message: ` <b>${errorMessage}</b>`,
          type: 'is-danger'
        })
      }).finally(() => this.isLoading = false)
      // this.isLoading = false;
    },
    loadUser(id) {
      this.isLoading = true;
      const options = {
        method: "get",
        uri: `auth/InfoUser?userid=${id}`
      };
      this._axios(options).then(res => {
        if (res.status === 200) {
          this.dataForm = res.data[0];
        }
      }).finally(() => this.isLoading = false)
    },
    resetErrorMessages() {
      for (const key in this.errors) {
        this.errors[key] = '';
      }
    },
    deleteUser() {
      this.$buefy.dialog.confirm({
        title: 'Usuarios',
        message: 'Está seguro de eliminar el usuario?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          const options = {
            method: 'post',
            uri: `auth/DeleteUser?userId=${this.dataForm.id}`
          };
          this._axios(options)
              .then((response) => {
                if (response.status === 200) {
                  this.$router.push('/users');
                }
              })
              .finally(() => this.isLoading = false);
        }
      })
    },
    validatePasswords() {
      this.errors.confirmPassword = '';
      if (this.dataForm.password.localeCompare(this.dataForm.confirmPassword)) {
        this.errors.confirmPassword = 'Las contraseñas no coinciden';
      }
    },
    getData() {
      const pass = new Buffer(this.dataForm.password);
      return {
        "firstName": this.dataForm.firstName,
        "lastName": this.dataForm.lastName,
        "email": this.dataForm.email,
        "password": pass.toString('base64'),
        "ProfileId": this.dataForm.profileId
      }
    },
    getUpdateData() {
      const pass = new Buffer(this.dataForm.password);
      return {
        "Id": this.dataForm.id,
        "FirstNameU": this.dataForm.firstName,
        "LastNameU": this.dataForm.lastName,
        "EmailU": this.dataForm.email,
        "NewPassword": pass.toString('base64'),
        "ProfileId": this.dataForm.profileId
      }
    },
    loadProfiles() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/profile`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      };
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.profileList = response.data
            }
          })
          .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

</style>