<template>
  <section v-if="info.length > 0">
    <p v-if="title" class="subtitle is-4">{{ title }}</p>
    <div v-if="haveFilter" style="display: flex;justify-content: flex-end">
      <b-field class="column is-6" :label="configFilter.label">
        <b-input v-model="searchQuery" :placeholder="configFilter.placeholder" autofocus/>
      </b-field>
    </div>
    <!--
    <b-field grouped group-multiline>
      <div v-for="(column, index) in columns" :key="index" class="control">
        <b-checkbox v-model="column.isVisible">
          {{ column.label }}
        </b-checkbox>
      </div>
    </b-field>-->
    <CustomTable :columns="columns" :info="filter" :detailKey="detailKey" :ciamId="ciamId" :represent="represent" :type="tableType"
                 :tableConfig="tableConfig"
                 @delete="deleteItem"
                 @edit="editItem"
                 @historico="historicoItem"></CustomTable>
  </section>
  <div v-else>
    <b-message type="is-info">
      <template v-if="messageEmpty">
        {{ messageEmpty }}
      </template>
      <template v-else>
        Sin datos de {{ title }} para procesar.
      </template>
    </b-message>
  </div>
</template>

<script>

import {getStatusText} from "../../assets/config/utilities";

export default {
  name: "",
  components: {
    CustomTable: () => import("../tables/CustomTable")
  },
  props: {
    title: String,
    messageEmpty: String,
    haveFilter: {
      type: Boolean,
      default: true
    },
    configFilter: {
      type: Object,
      default: () => {
        return {
          columns: []
        }
      }
    },
    columns: Object,
    info: Array,
    detailKey: String,
    ciamId: String,
    represent: String,
    tableType: {
      type: Number,
      default: 1
    },
    tableConfig: {
      type: Object,
      default: () => {
        return {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: true,
        }
      }
    }
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  watch: {
    info() {
      this.searchQuery = '';
    }
  },
  computed: {
    filter() {
      let change = this.info;
      if (this.haveFilter && this.searchQuery !== '') {
        let newData = this.info.filter(item => {
          let allCon;
          for (const col of this.configFilter.columns) {
            allCon = allCon || (this.columns[col].type === 'status' ?
                getStatusText(item[col]).toLowerCase().includes(this.searchQuery.toLowerCase()) :
                item[col].toString().toLowerCase().includes(this.searchQuery.toLowerCase()));
          }
          if (allCon) return item
        });
        newData.forEach((item, index) => {
          item.num = index + 1;
        });
        return newData;
      }
      change.forEach((item, index) => {
        item.num = index + 1;
      });
      return change;
    }
  },
  methods: {
    deleteItem(id) {
      this.$emit('delete', id);
    },
    editItem(id) {
      this.$emit('edit', id);
    },
    historicoItem(id) {
      this.$emit('historico', id);
      console.log("Click Historico!");
    },
    agregarFila() {
      this.dataTable.push({
        id: 'hola',
      });
    },
  }
}
</script>

<style scoped>

</style>