import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';
import axios from "axios";
import dataConfig from "@/assets/config/jsonconfig.json"

Vue.use(Vuex);

const _HOST_AXIOS = dataConfig.config.urlBase;//IP para consumir le orquestador
// const _HOST_AXIOS = 'https://skandia.dev.infomediaservice.online:5005/api';
// const _HOST_AXIOS = 'https://infomedia.azurewebsites.net/api';
//const _HOST_AXIOS = 'https://tuya-dashboard-dev.azurewebsites.net/api';

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
    reducer: (state) => ({
        tokenAuth: state.tokenAuth,
        expireIn: state.expireIn,
        usuario: state.usuario,
        tituloMenu: state.tituloMenu,
        roles: state.roles,
        perfil: state.perfil,
    }),
})

export default new Vuex.Store({
    state: {
        tokenAuth: "",
        expireIn: "",
        usuario: "",
        tituloMenu: "",
        roles: {},
        perfil: "",
        _PERFIL_MODULO_: dataConfig.perfil_modulo,
        _HOST_SERVE: dataConfig.config.urlBase,
        //IP para consumir le orquestador
        // _HOST_SERVE: 'https://skandia.dev.infomediaservice.online:5005/api'
        // _HOST_SERVE: 'https://infomedia.azurewebsites.net/api'
        //_HOST_SERVE: 'https://tuya-dashboard-dev.azurewebsites.net/api'

    },
    plugins: [
        vuexLocalStorage.plugin
    ],
    getters: {
        getTokenAuth(state) {
            return state.tokenAuth;
        },
        getExpireIn(state) {
            return state.expireIn;
        },
        getUsuario(state) {
            return state.usuario;
        },
        getRoles(state) {
            return state.roles
        },
        getRoleIdList(state) {
            return state.roles.map(role => role.id)
        }
    },
    mutations: {
        nombreTituloMenu(state, nombre) {
            state.tituloMenu = nombre;
        },
        setTokenAuth(state, token) {
            state.tokenAuth = token;
        },
        setExpireIh(state, expireIn) {
            state.expireIn = expireIn;
        },
        setUsuario(state, usuario) {
            state.usuario = usuario;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setPerfil(state, perfil) {
            state.perfil = perfil;
        },
        cerrarSesion(state) {
            state.tokenAuth = "";
            state.expireIn = 0;
            state.usuario = null;
            state.roles = {};
        }
    },
    actions: {
        _axios({getters}, options) {
            return axios({
                headers: {
                    Authorization: `Bearer ${getters.getTokenAuth}`
                },
                method: options.method,
                url: `${_HOST_AXIOS}/${options.uri}`,
                data: options.data
            });
        }
    },
    modules: {},
});
