<template>

  <main-layout :itemMenuActive="23" tituloMenu="Administración de perfiles">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <!--            <div class="column is-half is-offset-one-quarter">-->
          <div style="display: flex; justify-content: space-between; align-items: center">
            <p class="title is-4 centered-text">Lista de perfiles</p>
            <div class="buttons">
              <b-button v-if="isEditable()" type="is-primary"
                        tag="router-link" to="/profile">
                Crear
              </b-button>
              <b-button v-if="isExportable()" type="is-success" v-on:click="exportCsv">
                Exportar
              </b-button>
            </div>
          </div>
          <br>
          <TableCard messageEmpty="Sin datos para procesar."
                     :columns="columns" :info="profileList"
                     detailKey="id"
                     :haveFilter="false"
                     :tableConfig="tableConfig"
                     @delete="deleteProfile"
                     @edit="editProfile"></TableCard>
          <br>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions, mapGetters} from "vuex";
import TableCard from "../../components/cards/TableCard";
import axios from "axios";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import {COLUMNS_PROFILE_TABLE} from "../../assets/config/constants/TableConstants";
import {showErrorAlert} from "../../assets/config/alert_components";
import {removeEditButtons} from "../../assets/config/utilities";

export default {
  name: "ProfileList",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    return {
      isLoading: false,
      profileList: [],
      columns: COLUMNS_PROFILE_TABLE,
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false
      },
      roleList: this.getRoleIdList()
    }
  },
  mounted() {
    this.checkRoles();
    this.loadProfiles();
  },
  methods: {
    ...mapActions(['_axios']),
    ...mapGetters(["getRoleIdList"]),
    checkRoles() {
      if (!this.isEditable()) {
        this.columns.actions.data = removeEditButtons(this.columns.actions.data)
      }
    },
    isEditable() {
      return this.roleList.includes(46)
    },
    isExportable() {
      return this.roleList.includes(47)
    },
    loadProfiles() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/profile`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      };
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.profileList = response.data
              for (const profile of this.profileList) {
                profile.roleList = profile.roleList.join(', ')
              }
            }
          })
          .finally(() => this.isLoading = false);
    },
    editProfile(id) {
      this.$router.push({name: 'Perfil', params: {id: id}})
    },
    deleteProfile(id) {
      this.$buefy.dialog.confirm({
        title: 'Usuarios',
        message: 'Está seguro de eliminar el perfil?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          const options = {
            method: 'delete',
            url: `${HOST_API}/profile/${id}`,
            headers: {
              Authorization: `Bearer ${this.$store.state.tokenAuth}`
            },
          };
          axios(options)
              .then((response) => {
                if (response.status === 204) {
                  this.loadProfiles();
                }
              }).catch((e) => {
                showErrorAlert(e.response.data.message)
              }).finally(() => this.isLoading = false);
        }
      })
    },
    exportCsv() {
      const options = {
        method: "get",
        uri: "export/exportPerfilesDashboard/",
        responseType: 'blob'
      };
      this._axios(options)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const dateList = this.getDateList()
            let fileName = `perfiles_modulo_parametrizacion_${dateList[0]}${dateList[1]}${dateList[2]}.csv`;

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            // this.saveExportEvent(csv);
          })
          .catch(error => {
            console.log('error: ', error);
          });
    },
    getDateList() {
      const today = new Date()
      return [
        today.getFullYear(),
        ('0' + (today.getMonth()+1)).slice(-2),
        ('0' + today.getDate()).slice(-2)
      ]
    }
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

</style>