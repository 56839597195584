<template>
  <main>
    <!-- Incio Menu navbar -->
    <nav class="uk-navbar-container" uk-navbar>
      <div class="uk-navbar-left">
        <!-- Boton del menu de navegacion (hamburgueza) -->
        <b-button
            style="margin-left: 1rem"
            size="is-small"
            type="is-primary"
            class="btn-menu"
            outlined
            uk-toggle="target: #offcanvas-push"
        >
          <i class="bx bx-menu"></i>
        </b-button>
        <!-- Logo de Infomedia -->
        <img
            style="margin-left: 1rem; line-height: 2.5rem"
            src="@/assets/Infomedia-logo.png"
            width="100"
            uk-img
        />
        <!-- logo cliente -->
        <img
            style="margin-left: 1rem; line-height: 2.5rem"
            src="../../assets/logo_aliado.png"
            width="100"
            uk-img
        />
        <!-- Titulo de las opcion de menu actual -->
        <span
            class="uk-text-bolder uk-text-large"
            style="margin-left: 2rem"
        >{{ tituloMenu }}</span
        >
      </div>
      <div class="uk-navbar-right">
        <ul class="uk-navbar-nav">
          <!-- <li class="uk-active">
            <a href="#" class="" @click="cargarInfoHijo"
            ><i class="bx bx-loader"></i>Refrescar</a
            >
          </li> -->
          <li>
            <a href="#" style="text-transform: lowercase !important"
            ><i
                class="bx bx-user"
                style="text-transform: lowercase !important"
            ></i
            >{{ usuario }}</a
            >
            <div class="uk-navbar-dropdown">
              <ul class="uk-nav uk-navbar-dropdown-nav">
                <li><a href="#" @click="logout()">Cerrar sesión</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Fin Menu navbar -->
    <!-- Inicio Menu de navagacion(hamburgueza) izquierdo -->
    <div id="offcanvas-push" uk-offcanvas="mode: push; overlay: true">
      <div class="uk-offcanvas-bar menu-main">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <br/>

        <aside class="menu">
          <b-menu-list label="Dashboard">
            <b-menu-item label="ADMINISTRACIÓN" v-if="isMenuActive([40, 41, 42, 43, 45, 46])" class="item-menu">
              <CustomRouterLink v-if="isMenuActive([40, 41])" route="/sendreportsv2" iconOption="bx-mail-send" text="Envío de Reportes" :optionNum="18" :optionSelected="itemMenuActive"/>
              <b-menu-item label="DE USUARIOS" v-if="isMenuActive([42, 43, 45, 46])" class="custom-menu-list">
                <CustomRouterLink v-if="isMenuActive([42, 43])"  route="/users" iconOption="bx-user-circle" text="Usuarios" :optionNum="19" :optionSelected="itemMenuActive"/>
                <CustomRouterLink v-if="isMenuActive([45, 46])" route="/profiles" iconOption="bx-street-view" text="Perfiles" :optionNum="23" :optionSelected="itemMenuActive"/>
              </b-menu-item>
            </b-menu-item>
            <b-menu-item label="AUDITORíA" class="item-menu" v-if="isMenuActive([48, 49])">
              <CustomRouterLink v-if="isMenuActive([48, 49])" route="/logs_auditoria" iconOption="bx-user-circle" text="Logs de Auditoría" :optionNum="31" :optionSelected="itemMenuActive"/>
            </b-menu-item>
            <b-menu-item label="ADMINISTRACIÓN DE MÓDULO" v-if="isMenuActive([50, 51, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 77, 78, 79, 80])" class="item-menu">
              <CustomRouterLink v-if="isMenuActive([50, 51])" route="/parametrization" iconOption="bx-edit" text="Parametrización de textos" :optionNum="20" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([53, 54])" route="/adminalert" iconOption="bx-check-shield" text="Mensajes de Indisponibilidad" :optionNum="34" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([55, 56])" route="/icons" iconOption="bx-extension" text="Parametrización de Iconos" :optionNum="22" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([57, 58])" route="/ParametrizaciondeTiposdeDocumentos" iconOption="bx-edit" text="Parametrización de Tipos de Documentos" :optionNum="36" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([59, 60])" route="/habilitacionTD" iconOption="bx-window-alt" text="Habilitación Tipos de documento" :optionNum="35" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([61, 62])" route="/evidente" iconOption="bx-key" text="Activación Evidente Master" :optionNum="21" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([77, 78])" route="/asignacionRoles" iconOption="bx-key" text="Asignación de roles" :optionNum="222" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([79, 80])" route="/syntheticUsers" iconOption="bx-edit" text="Configuración lista de exclusión" :optionNum="0" :optionSelected="itemMenuActive"/>
              <CustomRouterLink v-if="isMenuActive([81, 82])" route="/switch" iconOption="bx-edit" text="Switch Servicios Creación" :optionNum="0" :optionSelected="itemMenuActive"/>
            </b-menu-item>
          </b-menu-list>

        </aside>
      </div>
    </div>
    <!-- Fin Menu de navagacion(hamburgueza) izquierdo -->
    <div style="background: #f0f2f5; height: 95vh; overflow-y: auto">
      <slot></slot>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <!-- Site footer -->
    <footer>
      <div class="footer-container">
        <a class="footer-a">
          <img src="../../assets/Infomedia-logo.png" width="70"/>
        </a>
        &nbsp;&nbsp;&nbsp;
        <p>
          {{ new Date().getFullYear() }} &nbsp;&nbsp;<b
        ><a href="http://www.infomediaservice.com"
            style="color: #4a4a4a !important; text-decoration: none"
        >© Infomedia Service S.A.</a></b>  v2.0
        </p>
      </div>
    </footer>
  </main>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import CustomRouterLink from "../menu/CustomRouterLink";

export default {
  name: "MainLayout",
  components: {
    CustomRouterLink
  },
  props: ["itemMenuActive", "tituloMenu"],
  data() {
    return {
      roleList: this.getRoles(),
      roleKeyList: this.getRoleKeyList()
    }
  },
  methods: {
    ...mapActions(["_axios"]),
    ...mapGetters(["getUsuario", "getExpireIn", "getRoles"]),
    ...mapMutations([
      "cerrarSesion",
      "setTokenAuth",
      "setExpireIh",
      "setUsuario"
    ]),
    promptNumber() {
      const options = {
        method: "get",
        uri: "boards/getTimeReloadDashboard",
        data: null,
      };
      this._axios(options)
          .then((res) => {
            if (res.data.answer) {
              this.timeAnswer = res.data.answer;
              this.timeReload = res.data.time;
            }
          })
          .catch((e) => {
            console.log(e);
          });

      if (this.timeAnswer) {
        this.$buefy.dialog.prompt({
          message: `Tiempo de Recarga Dashboard (Segundos)`,
          inputAttrs: {
            type: "number",
            placeholder: "Segundos",
            value: this.timeReload,
            maxlength: 2,
            min: 1,
            max: 36000,
          },
          trapFocus: true,
          //onConfirm: (value) => this.$buefy.toast.open(`Your age is: ${value}`),
          onConfirm: (value) => this.guardarTiempoRecarga(value),
        });
      }
    },
    logout() {
      this.setUsuario("");
      this.setExpireIh("");
      this.setTokenAuth("");
      this.$router.push("/login");
    },
    isSubMenuActive(roleId) {
      return this.roleKeyList.includes(roleId)
    },
    isMenuActive(roleList) {
        for (const roleId of roleList) {
            if (this.isSubMenuActive(roleId)) {
                return true;
            }
        }
        return false;
    },
    getRoleKeyList() {
        return this.getRoles().map(role => role.id)
    }
  },
  mounted() {
    if (this.getExpireIn().length === 0) this.$router.push("/login");
  },
  computed: {
    usuario() {
      return this.getUsuario();
    },
    ...mapState(["_PERFIL_MODULO_"]),
  },
};
</script>

<style>
.menu-main {
  background: rgb(48, 65, 86) !important;
  color: #bfcbd9;
}

.menu-list a {
  border-radius: 2px;
  color: #d1dae5 !important;
  display: block;
  padding: 0.5em 0.75em;
}
.menu-list li ul {
    border-left: 0px solid #dbdbdb !important;
    margin: 0.75em;
    padding-left: 0em !important;
}
p.menu-label {
  color: white;
  display: table-cell;
  padding: 6px;
}

.item-menu {
  font-size: 0.80em !important;
}

.menu-list a a:hover {
  background: rgb(178, 190, 181, .1);
  color: white;
}


.custom-menu-list a:hover {
  background: rgb(178, 190, 181, .1);
}

.menu-list a:hover {
  background: rgb(178, 190, 181, .1) !important;
  color: white !important;
  text-decoration: none !important;
}

.menu-item,
.menu-item-bg {
  background: rgb(48, 65, 86);
  color: #bfcbd9;
}

/* .uk-button{
  color: #BFCBD9 !important;
} */
.uk-accordion-title {
  color: #bfcbd9 !important;
}

.uk-close {
  color: #bfcbd9 !important;
}

.uk-heading-line {
  color: #bfcbd9 !important;
}

.hover-item:hover {
  background: #304156;
  color: white;
  transition: 0.4s;
}

.menu-title {
  background: #38386e;
  padding: 1rem;
  border-radius: 3px;
  width: 100%;
  display: block;
}

.footer-a {
  text-align: center;
}

.footer-container {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #f0f2f5;
  border-top: 1px solid lightgray;
}
</style>