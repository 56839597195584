import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Buefy from 'buefy'
import "@/utils/style.css"
//import 'buefy/dist/buefy.css'
import '@mdi/font/css/materialdesignicons.css'
import Store from "./store";

Vue.config.productionTip = false
Vue.use(Buefy);

const errorHandler = error => {
    if (error.response && error.response.status === 401) {
        Store.commit('cerrarSesion');
        if (router.currentRoute.path !== '/login') {
            return router.push('/login').catch(() => {});
        }
    }
    return Promise.reject(error);
};

axios.interceptors.response.use(
    null, error => errorHandler(error)
);

new Vue({
    router,
    store,
    axios,
    render: h => h(App)
}).$mount('#app')