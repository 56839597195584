<template>
  <main-layout :itemMenuActive="222" tituloMenu="Asignación de roles">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <div class="column is-one-third">
              <b-field label="Tipo de documento" vertical :type="formErrors.documentType ? 'is-danger' : ''"
                :message="formErrors.documentType">
                <b-select ref="tipoSelect" placeholder="Seleccione una opción" expanded v-model="formData.documentType"
                  v-on:change.native="cambioTipo" :disabled="blokearInputs">
                  <option value="">
                    Seleccione una opción
                  </option>
                  <option v-for="(value, key) in documentTypes" :value="value.tipo" :alfanumerico="value.alfa" :key="key">
                    {{ value.text }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field vertical label="Número de documento" :type="formErrors.documentNumber ? 'is-danger' : ''"
                :message="formErrors.documentNumber">
                <b-input ref="numeroDocumento" pattern="[0-9]+" :disabled="blokearInputs" @input="llenoNumDocument"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" name="subject"
                  expanded v-model="formData.documentNumber"></b-input>
              </b-field>
            </div>
            <div class="column is-one-fifth" style="position: relative; margin-left: auto;">
              <div>
                <div v-if="op == editar">
                  <b-button type="is-primary is-fullwidth" @click="guardarCambios" :loading="isLoading">Guardar
                    Cambios</b-button>
                  <b-button type="is-fullwidth" @click="descartar" :loading="isLoading">Descartar</b-button>
                </div>
                <div v-else>
                  <b-button type="is-primary is-fullwidth" @click="consultarRoles" :loading="isLoading"
                    :disabled="deshabilitarBtnConsultar">Consultar Roles</b-button>
                  <b-button type="is-fullwidth" @click="editarRoles" :loading="isLoading"
                    :disabled="deshabilitarBtnEditar">Editar Roles</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="card" v-if="mostrar">
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column is-half">
                <b>{{ nombre ? nombre : 'No tiene' }}</b>
                <br>
                <b>Documento: </b> {{ documento ? documento : 'No tiene' }}
                <br>
                <b>CIAM ID: </b> {{ ciamId ? ciamId : 'No tiene' }}
                <br>
                <b>Correo: </b> {{ correo ? correo : 'No tiene' }}
                <br>
                <b>Teléfono: </b> {{ telefono ? telefono : 'No tiene' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card" v-if="mostrar && op == consultar">
        <div class="card-content">
          <TableCard messageEmpty="No tiene datos" detailKey="id" 
            :columns="columnsConsultar" 
            :info="dataConsultarRoles"
            :haveFilter="false" 
            :tableConfig="tableConfig">
          </TableCard>
        </div>
      </div>
      <div class="card" v-if="mostrar && op == editar">
        <div class="card-content">
          <TableCard messageEmpty="No tiene datos" detailKey="id" 
            :columns="columnsEditar" 
            :info="dataEditarRoles"
            :haveFilter="false" 
            :tableConfig="tableConfig">
          </TableCard>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </main-layout>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Swal from 'sweetalert2';
import TableCard from "../../components/cards/TableCard";
import MainLayout from "../../components/Layout/MainLayout";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento;
//const urlBaseTiposdeDocumentoV1 = "https://compensar-sinconvivencia.infomediaservice.online/typedocuments/api/v1";
//const urlBaseTiposdeDocumentoV1 = "https://seguridad.compensar.com/typedocuments/api/v1";//config.urlBaseTiposdeDocumentoV1;
const urlBaseTiposdeDocumentoV1 = config.urlTiposdeDocumento + "v1";
import { getToken } from "../../assets/config/getToken.js";

export default {
  name: "AsignacionRoles",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    return {
      roleList: this.getRoleIdList(),
      columnsConsultar: {
        nro: {
          label: 'Nro.',
          type: 'text',
          isVisible: true
        },
        id: {
          label: 'Id rol',
          type: 'text',
          sortable: true,
          isVisible: true
        },
        roleName: {
          label: 'Nombre rol',
          type: 'text',
          sortable: true,
          isVisible: true,
        },
        description: {
          label: 'Categoría',
          type: 'text',
          sortable: true,
          isVisible: true
        }
      },
      columnsEditar: {
        nro: {
          label: 'Nro.',
          type: 'text',
          isVisible: true
        },
        state: {
          label: 'Accion',
          type: 'accionCheck',
          isVisible: true
        },
        id: {
          label: 'Id rol',
          type: 'text',
          sortable: true,
          isVisible: true
        },
        roleName: {
          label: 'Nombre rol',
          type: 'text',
          sortable: true,
          isVisible: true,
        },
        description: {
          label: 'Categoría',
          type: 'text',
          sortable: true,
          isVisible: true
        }
      },
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      consultar: 'consultar',
      editar: 'editar',
      blokearInputs: false,
      deshabilitarBtnConsultar: true,
      deshabilitarBtnEditar: true,
      mostrar: false,
      nombre: '',
      documento: '',
      ciamId: '',
      correo: '',
      telefono: '',
      isLoading: false,
      op: '',
      formErrors: {
        documentNumber: '',
        documentType: ''
      },
      formData: {
        documentNumber: '',
        documentType: ''
      },
      documentTypes: null,
      dataConsultarRoles: [],
      dataEditarRoles: [],
      xApiKey: '87430357-185d-45e3-b3ac-c712a4c6411c',
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.apiTiposDocumento().then((res) => {
      let opTipos = res.filter(tipo => {
        return tipo.empresa['Administracion de Delegados'] == true
      }).map(function (tipo) {
        return ({
          tipo: tipo.code.toLowerCase(),
          text: tipo.Title,
          alfa: tipo.AlfaNumerico,
        })
      }).sort((x, y) => x.text.localeCompare(y.text));

      this.documentTypes = opTipos;
    });
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isDisable(id) {
      //console.log('roleList', this.roleList, id);
      return !this.roleList.includes(id);
    },
    async apiTiposDocumento() {
      this.isLoading = true;
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento + '/tiposdedocumento',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
      }).then((res) => {
        this.isLoading = false;
        return res.data;
      }).catch(async (error) => {
        this.isLoading = false;
        const result = await Swal.fire({
          title: 'Error al cargar los documentos',
          icon: 'error',
          confirmButtonText: 'OK'
        })

        if (result.isConfirmed) {
          location.reload();
        }
        console.error(error)
      })
    },
    async serviceroles(url, method, data) {
      let setting = {
        method: method,
        url: `${urlBaseTiposdeDocumentoV1}/${url}`,
        headers: {
          'x-api-key': this.xApiKey,
          'Authorization': `Bearer ${this.token.Bearer}`
        },
      };

      if (data)
        setting['data'] = data;

      this.isLoading = true;
      return await axios(setting).then((res) => {
        this.isLoading = false;
        return res.data;
      }).catch((error) => {
        this.isLoading = false;
        console.error(error);
      })
    },
    async apiConsultarRoles(tipo, numero) {
      return await this.serviceroles(`delegates/roles/user?documentType=${tipo}&documentNumber=${numero}&dashUser=${this.$store.getters.getUsuario}`, 'get');
    },
    async apiCargaEditarRoles(tipo, numero) {
      return await this.serviceroles(`delegates/roles/user/parameterizable?documentType=${tipo}&documentNumber=${numero}&dashUser=${this.$store.getters.getUsuario}`, 'get');
    },
    async apiGuardar(data) {
      return await this.serviceroles(`delegates/roles/user/parameterizable`, 'post', data);
    },
    async opErrores(valores) {
      if (valores && valores.code == '401' && valores.message == 'User not exist') {
        await this.msgError('Este usuario no tiene CIAMID registrado');
      } else if (valores && valores.code == '403' && valores.message == 'User with more than one ciamId') {
        await this.msgError('Este usuario tiene varios CIAMID');
      } else {
        console.warn('valores', valores);
        await this.msgError('Se presenta una falla en el consumo de servicios y bases de datos, por favor contactar al proveedor');
      }
    },
    async consultarRoles() {
      if (!this.formData.documentNumber || !this.formData.documentType) {
        console.warn('se esta consultando sin llenar datos');
        return;
      }

      const valores = await this.apiConsultarRoles(this.formData.documentType, this.formData.documentNumber);
      if (!valores || valores.code && valores.message) {
        await this.opErrores(valores);
        return;
      }

      if (!valores.ciamId) {
        await this.msgError('Este usuario no tiene CIAMID registrado');
        return;
      }
      
      if (Array.isArray(valores.roleList)) {
        for (const i in valores.roleList) {
          const rol = valores.roleList[i];
          rol['nro'] = (parseInt(i) +1);
        }
      }

      this.dataConsultarRoles = valores.roleList;

      this.llenarCard(valores);

      this.deshabilitarBtnConsultar = this.isDisable(77);
      this.deshabilitarBtnEditar = this.isDisable(78);
      this.blokearInputs = false;
      this.mostrar = true;
      this.op = this.consultar;
    },
    async editarRoles() {
      if (!this.formData.documentNumber || !this.formData.documentType) {
        console.warn('Se esta consultando sin llenar datos');
        return;
      }

      const valores = await this.apiCargaEditarRoles(this.formData.documentType, this.formData.documentNumber);
      if (!valores || valores.code && valores.message) {
        await this.opErrores(valores);
        return;
      }

      if (!valores.ciamId) {
        await this.msgError('Este usuario no tiene CIAMID registrado');
        return;
      }

      if (Array.isArray(valores.roleList)) {
        for (const i in valores.roleList) {
          const rol = valores.roleList[i];
          rol['nro'] = (parseInt(i) +1);
        }
      }

      this.dataEditarRoles = valores.roleList;
      this.llenarCard(valores);

      this.deshabilitarBtnConsultar = this.isDisable(77);
      this.deshabilitarBtnEditar = this.isDisable(78);
      this.blokearInputs = true;
      this.mostrar = true;
      this.op = this.editar;
    },
    async msgError(msg) {
      await Swal.fire({
        title: msg,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      this.valoresIniciales();
    },
    llenarCard(valores) {
      this.nombre = valores.names;
      this.documento = this.formData.documentNumber;
      this.ciamId = valores.ciamId;
      this.correo = valores.email;
      this.telefono = valores.phone;
    },
    valoresIniciales() {
      this.deshabilitarBtnConsultar = this.isDisable(77);
      this.deshabilitarBtnEditar = this.isDisable(78);
      this.blokearInputs = false;
      this.mostrar = false;
      this.op = '';
    },
    cambioTipo() {
      let alfaNumerico = this.$refs.tipoSelect.$refs.select.selectedOptions[0].getAttribute('alfanumerico');
      let pattern = '[0-9]+';
      let oninput = "this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\..*)\\./g, '$1');";
      if (alfaNumerico == 'true') {
        pattern = '[0-9A-Za-zÑñ]+';
        oninput = "this.value = this.value.replace(/[^0-9A-Za-zÑñ]/g, '');";
      }
      this.$refs.numeroDocumento.$refs.input.value = '';
      this.$refs.numeroDocumento.$refs.input.pattern = pattern;
      this.$refs.numeroDocumento.$refs.input.setAttribute('oninput', oninput);
      this.formData.documentNumber = '';
      this.mostrar = false;
      this.llenoNumDocument();
    },
    llenoNumDocument() {
      if (this.$refs.numeroDocumento.$refs.input.value && this.$refs.tipoSelect.$refs.select.value) {
        this.deshabilitarBtnConsultar = this.isDisable(77);
        this.deshabilitarBtnEditar = this.isDisable(78);
      } else {
        this.deshabilitarBtnConsultar = true;
        this.deshabilitarBtnEditar = true;
      }
    },
    async guardarCambios() {
      if (this.op && this.op == this.editar) {
        const result = await Swal.fire({
          title: '¿Estás seguro de los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        });

        if (result.isConfirmed) {
          const roleList = this.dataEditarRoles.map(function (role) {
            return { id: role.id, state: role.state }
          });
          const dataActualizar = {
            "ciamId": this.ciamId,
            "roleList": roleList,
            "dashUser": this.$store.getters.getUsuario
          };
          const valido = await this.apiGuardar(dataActualizar);
          if (valido.code == 200 && valido.message == 'Roles updated') {
            const result = await Swal.fire({
              title: 'Cambios realizados exitosamente',
              icon: 'success',
              confirmButtonText: 'OK'
            });

            if (result.isConfirmed) {
              this.reiniciarValores();
            } else {
              this.reiniciarValores();
            }
          } else {
            console.warn('error en apiGuardar', valido);
          }
        }
      }
    },
    async descartar() {
      if (this.op && this.op == this.editar) {
        const result = await Swal.fire({
          title: '¿Estás seguro de descartar los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        });

        if (result.isConfirmed)
          this.reiniciarValores();
      }
    },
    reiniciarValores() {
      this.blokearInputs = false;
      this.deshabilitarBtnConsultar = true;
      this.deshabilitarBtnEditar = true;
      this.mostrar = false;
      this.nombre = '';
      this.documento = '';
      this.ciamId = '';
      this.correo = '';
      this.telefono = '';
      this.isLoading = false;
      this.op = '';
      this.formErrors.documentNumber = '';
      this.formErrors.documentType = '';
      this.formData.documentNumber = '';
      this.formData.documentType = '';
      this.dataConsultarRoles = [];
      this.dataEditarRoles = [];
    }
  }
}
</script>

<style scoped></style>