<template>
    <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
            <div class="card">
                <div class="card-content">
                    <div v-if="dataList.length > 0">
                        <b-table :data="dataList" :detailsColumns="columnDetail" :columns="columns" :paginated="isPaginated" :per-page="perPage"
                            :current-page="currentPage" backend-pagination pagination-position="bottom"
                            :pagination-rounded="true" @page-change="onPageChange" :detailed="isDetailed" :total="total"
                            detail-key="_id"
                            :row-class="(row) => !row.updatedData ? 'hide-arrow-icon-detail' : ''"
                            striped scrollable>
                            <template #detail="props">
                                <div v-if="props.row.updatedData">
                                <article class="media">
                                    <div class="media-content">
                                        <div class="content">
                                            <b-table :data="props.row.updatedData" :columns="columnDetail">
                                                <b-table-column field="field" label="Campo" width="40"
                                                    v-slot="props">
                                                    {{ validarColumnas(props.row.field) }}
                                                </b-table-column>
                                                <b-table-column field="lastValue" label="Valor Anterior"
                                                    v-slot="props">
                                                    {{ separarComas(props.row.lastValue,props.row.field) }}
                                                </b-table-column>
                                                <b-table-column field="newValue" label="Valor Nuevo"
                                                    v-slot="props">
                                                    {{ separarComas(props.row.newValue,props.row.field) }}
                                                </b-table-column>
                                            </b-table>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            </template>
                        </b-table>
                    </div>

                    <div v-else>
                        <b-message type="is-info">
                            <template v-if="messageEmpty">
                                {{ messageEmpty }}
                            </template>
                            <template v-else>
                                Sin datos para procesar.
                            </template>
                        </b-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHour } from "../../assets/config/utilities";
export default {
    name: "TableCardSendReport",
    props: {
        columns: Array,
        columnDetail: Array,
        dataList: Array,
        messageEmpty: String,
        isPaginated: {
            type: Boolean,
            default: true
        },
        isDetailed: {
            type: Boolean,
            default: true
        },
        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        total: Number,
        pageChange: { type: Function },
    },
    methods: {
        onPageChange(page) {
            this.pageChange(page);
        },
        separarComas(texto,campo){
            console.log(campo +","+ texto);
            if(campo === "Hora de Ejecución"){
                texto = getHour(texto);
            }
            if(typeof texto === 'boolean'){
                if(texto === true){
                    texto = 'Activo';
                }else{
                    texto = 'Inactivo';
                }
            }
            if(typeof texto === 'object' && texto !== null)
            {
                var msj = texto;
                return msj.join(', ');
            }else{
                return texto;
            }
        },
        validarColumnas(column){
            console.log(column);
            switch (column) {
                case 'Activo':
                    column = 'Estado';
                    break;
            
                default:
                    break;
            }
            return column;
        },
        hasDetailsVisible() {
            console.log(this.dataList.updatedData !== undefined)
            console.log(this.dataList.updatedData);
            var valor = this.dataList.some((item) => item.updatedData);
            console.log(valor);
            return valor;
        },
    }
}
</script>
<style>
	.hide-arrow-icon-detail a[role='button'] {
		display: none;
	}
</style>