<template>
  <div>
    <b-field>
      <button class="menu-button" @click.prevent="changeActiveState">
        <i :class="state ? 'bx bx-minus' : 'bx bx-plus'" style="font-size: 1.5em;"/>
      </button>
      <br>
      <p style="margin-left: 5px" class="title is-5 centered-text">{{ name }}</p>
    </b-field>
    <ul class="menu-list" v-if="state">
      <li :key="item.value" v-for="item in options">
        <b-checkbox :disabled="disabled" v-model="newSelected"
                    :native-value="item.id">
          {{ item.name }}
        </b-checkbox>
      </li>
      <li :key="subCategory.id" v-for="subCategory in subOptions">
        <br>
        <CheckboxMenu :selectedOptions="subCategory.selected" :options="subCategory.options"
                      :id="subCategory.id" :name="subCategory.name" @add-selected="addSelected"
                      @remove-selected="removeSelected" :isActive="subCategory.isActive"
                      :subOptions="subCategory.subCategoryList" :disabled="disabled"/>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CheckboxMenu",
  props: {
    selectedOptions: Array,
    options: Array,
    name: String,
    id: Number,
    disabled: Boolean,
    subOptions: Array,
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newSelected: this.selectedOptions,
      state: this.isActive
    }
  },
  mounted() {
    if (!this.state && this.newSelected.length !== 0) {
      this.state = true
    }
  },
  watch: {
    newSelected(actual, old) {
      if (old.length < actual.length) {
        this.addSelected(actual.filter(x => !old.includes(x))[0])
      } else {
        this.removeSelected(old.filter(x => !actual.includes(x))[0])
      }
    }
  },
  methods: {
    changeActiveState() {
      this.state = !this.state;
    },
    addSelected(id) {
      this.$emit('add-selected', id);
    },
    removeSelected(id) {
      this.$emit('remove-selected', id);
    },
  }
}
</script>

<style scoped>

.menu-button {
  border: none;
  background: none;
  padding: 0;
}

.menu-list {
  margin-left: 25px;
}

</style>