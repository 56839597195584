<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br>
    <div class="container">
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div style="display: flex; justify-content: space-between; align-items: center">
                <p class="title is-4">Envios programados</p>
                <b-button v-if="isEditable()" type="is-primary" tag="router-link"
                          to="/reportv2">
                  Crear
                </b-button>
              </div>

              <form v-on:submit.prevent="submitForm">
              </form>
              <br>
              <TableCard messageEmpty="Sin datos para procesar."
                         :columns="columns" :info="data"
                         detailKey="id"
                         :haveFilter="false"
                         :tableConfig="tableConfig"
                         @delete="deleteReport"
                         @edit="editReport"
                         @historico="historicoReport"></TableCard>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "../../components/Layout/MainLayout";
import { COLUMNS_AUTOMATIC_REPORTS_TABLE_v2 } from "../../assets/config/constants/TableConstants";
import {config} from "../../assets/config/jsonconfig.json";
import {mapGetters} from "vuex";
import {removeEditButtons} from "../../assets/config/utilities";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

export default {
  name: "SendReports",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {
      fecha_de_creacion: null,
      fecha_actualizacion:null,
      estado: null,
      asunto: null,
      correos: null,
      mensaje:null,
      horaejecucion:null,
      isLoading: false,
      maxDate: new Date(),
      data: [],
      columns: COLUMNS_AUTOMATIC_REPORTS_TABLE_v2,
      rangeDate: [],
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false
      },
      options: {
        method: '',
        url: '',
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        }
      },
      roleList: this.getRoleIdList(),
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.checkRoles()
    this.submitForm()
  },
  methods: {
    checkRoles() {
      if (!this.isEditable()) {
        this.columns["_id"]["data"] = removeEditButtons(this.columns["_id"]["data"])
      }
    },
    isEditable() {
      return this.roleList.includes(41)
    },
    submitForm() {
      this.isLoading = true;
      this.options.method = 'get';
      this.options.url = urlBaseTiposdeDocumento + '/get_record_suscription';
      this.options.headers = {
        'Authorization': `Bearer ${this.token.Bearer}`,
        'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
    };
      axios(this.options)
          .then((response) => {
            if (response.status === 200) {
              this.data = response.data;
            }
          })
          .finally(() => this.isLoading = false);
    },
    deleteReport(reportId) {
      this.$buefy.dialog.confirm({
        title: 'Reporte automático',
        message: `¿Está seguro de eliminar el reporte identificado con el ID: ${reportId}?`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          this.options.method = 'delete';
          this.options.url = urlBaseTiposdeDocumento + "/delete_record_suscription?id=" + reportId  + '&user='+ this.getUsuario();
          this.options.headers = {
              'Authorization': `Bearer ${this.token.Bearer}`,
              'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
          };
          axios(this.options).then((response) => {
              if (response.status === 204) {
                this.data = this.data.filter(function (value) {
                  return value.id !== reportId;
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
              setTimeout(() => {
                location.reload(); // Actualizar la página después de 4 segundos
              }, 1800); // 4 segundos = 4000 milisegundos
            });
          this.$router.push({ path: '/sendreportsv2' });
        }
      });
    },
    editReport(reportId) {
      console.log("Editar " + reportId);
      this.$router.push({ path: '/viewreportv2/' + reportId});
    },
    historicoReport(reportId){
      console.log("Historico " + reportId);
      this.$router.push({ path: '/historicoreport/' + reportId});
    },
      ...mapGetters(["getUsuario", "getRoleIdList"])
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

</style>