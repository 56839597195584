import {DialogProgrammatic as Dialog} from 'buefy';

export function showErrorAlert(message) {
    Dialog.alert({
        title: 'Error',
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
    })
}