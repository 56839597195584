<template>
  <main-layout :itemMenuActive="24" tituloMenu="Reporte de Delegados">

    <div class="container">
      <br/>
      <div class="box">
        <form v-on:submit.prevent="loadEvents">
          <b-field label="">
            <h1 style="margin-right: 1rem; line-height: 2.5rem">Filtrar:</h1>
            <b-datepicker
                v-model="date_range"
                placeholder="Fecha Inicial"
                icon="calendar-today"
                trap-focus
                rounded
                required
                range
                :max-date="new Date()"
            >
            </b-datepicker>
            <b-button native-type="submit" type="is-info">Consultar</b-button>
          </b-field>
        </form>
      </div>
    </div>
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <TableCard messageEmpty="Sin datos para procesar."
                     :columns="columns" :info="events"
                     detailKey="id"
                     :haveFilter="false"
                     :tableConfig="tableConfig"></TableCard>
          <br>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions} from "vuex";
import TableCard from "../../components/cards/TableCard";
import {COLUMNS_PROXY_EVENTS_TABLE} from "../../assets/config/constants/TableConstants";

export default {
  name: "ProxyList",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    return {
      date_range: [],
      isLoading: false,
      events: [],
      columns: COLUMNS_PROXY_EVENTS_TABLE,
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false
      }
    }
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    ...mapActions(['_axios']),
    loadEvents() {
      this.isLoading = true;
      let options = {
        method: "post",
        uri: "vulogs/proxy"
      };
      if (this.date_range.length === 0) {
        options.data = {
          Fecha: new Date()
        }
      } else {
        options.data = {
          FechaInicial: this.date_range[0],
          FechaFinal: this.date_range[1]
        }
      }
      this._axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.events = response.data.datos;
            }
          })
          .finally(() => this.isLoading = false);
    },
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

</style>