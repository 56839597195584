<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/sendreportsv2">
                <i class="bx bx-left-arrow" />
              </b-button>
              <p class="title is-4 centered-text">Envío programado</p>
            </div>
            <div v-if="isEditable()">
              <b-button type="is-info" style="margin: 0 4px" @click="editar_nuevo_reporte">
                Editar
              </b-button>
              <b-button type="is-danger" @click="deleteReport">
                Eliminar
              </b-button>
            </div>
          </div>
          <br>
          <form v-on:submit.prevent="submitForm">
            <div class="columns">
              <b-field class="column" label="Periodo de ejecución" :type="errors['typeTimeLapse'] ? 'is-danger' : ''"
                :message="errors['typeTimeLapse']">
                <b-select placeholder="Selecciona una opción" v-model="peridoseleccionado" expanded :disabled="true">
                  <option :key=this.Mensual value="Mensual">Mensual</option>
                  <option :key=this.Semanal value="Semanal">Semanal</option>
                </b-select>
              </b-field>


              <b-field class="column is-fullwidth" label="Reporte">
                <b-select v-model="opcionreporteseleccionada" placeholder="Selecciona una opción"
                  @input="changeExportOptions" expanded :disabled="true">
                  <option :key="option.type" v-for="option of reportTypeOptions" :value="option">{{ option }}</option>
                </b-select>
              </b-field>

              <b-field v-if="tipodelog.length !== 0" class="column is-narrow" label="Tipo de Log">
                <b-select v-model="tipodelogseleccionado" placeholder="Selecciona una opción"
                  @input="changeExportOptionstipes" expanded :disabled="true">
                  <option :key="option" v-for="index, option of tipodelog" :value="option">{{ index }}</option>
                </b-select>
              </b-field>


              <b-field class="column" label="Formato">
                <b-select v-model="tipodeformatoseleccionado" placeholder="Selecciona una opción" :disabled="true"
                  expanded>
                  <option :key="option" v-for="option of tipodeformato" :value="option">{{
                    option
                  }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Fecha Inicio" :type="errors['startDate'] ? 'is-danger' : ''"
                :message="errors['startDate']">
                <b-datepicker v-model="fechaseleccionada" icon="calendar-today"
                  :disabled="true">
                </b-datepicker>
              </b-field>
              <b-field class="column" label="Hora de Ejecución" :type="errors['executionHour'] ? 'is-danger' : ''"
                :message="errors['executionHour']">
                <b-timepicker rounded v-model="horaejecucion" icon="clock" editable
                  :hour-format="'24'" :disabled="true">
                </b-timepicker>
              </b-field>
              <b-field class="column is-2" label="Estado" :style="dataForm.id">
                <div class="control">
                  <b-switch v-model="estado" @input="changeState" :disabled=true>{{
                    stateText
                  }}
                  </b-switch>
                </div>
              </b-field>
            </div>
            <div class="columns">
              <b-field class="column" label="Asunto" :type="errors['subject'] ? 'is-danger' : ''"
                :message="errors['subject']">
                <b-input maxlength="200" name="subject" v-model="asunto" placeholder="Ingrese el asunto" expanded
                  :disabled="true"></b-input>
              </b-field>
              <b-field class="column" label="Correos" :type="errors['emails'] ? 'is-danger' : ''"
                :message="errors['emails']">
                <b-taginput v-model="correos" ellipsis icon="label" maxtags="10" placeholder="Agregar correo"
                  :disabled="true" aria-close-label="Delete this tag" @typing="onEmailChange"
                  :before-adding="beforeAddingEmail">
                </b-taginput>
              </b-field>
            </div>
            <b-field label="Mensaje" :type="errors['content'] ? 'is-danger' : ''" :message="errors['content']">
              <b-input maxlength="400" type="textarea" v-model="mensaje" :placeholder="mensaje"
                :disabled="true"></b-input>
            </b-field>
            <div class="columns">
              <div class="column">
                <p><strong>Fecha de Inicio:</strong> {{ formatDate(fecha_de_creacion) }}</p>
              </div>
              <div class="column">
                <p><strong>Fecha de creación:</strong> {{ formatDate(fecha_actualizacion) }}</p>
              </div>
            </div>

          </form>
        </div>
      </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {
  string,
  date,
  object,
  array,
  setLocale
} from 'yup';
import {
  HOST_API,
  TIME_LAPSE_TYPE_OPTIONSv2,
} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import { dateFormatting } from "../../assets/config/utilities";
import { config } from "../../assets/config/jsonconfig.json";
import {mapGetters} from "vuex";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

setLocale({
  mixed: {
    required: 'Es un campo obligatorio',
  },
  number: {
    min: 'El valor debe ser mayor o igual a ${min}',
    max: 'El valor debe ser menor o igual a ${max}'
  },
  array: {
    min: 'Debe ingresar ${min} o más emails',
    max: 'Debe ingresar ${max} o menos emails'
  }
});

const schema = object().shape({
  subject: string().required().max(200),
  content: string().required().max(400),
  emails: array().min(1).max(10),
  startDate: date().required(),
  // endDate: date().required(),
  executionHour: date().required(),
  // timeLapse: number().min(1).max(20).transform((_, val) => val === Number(val) ? val : null).typeError('Es un campo obligatorio'),
  reportType: string().required(),
  exportType: string().required(),
});

export default {
  name: "CreateReport",
  components: {
    MainLayout
  },
  data() {
    return {
      fecha_de_creacion: null,
      fecha_actualizacion:null,
      estado: null,
      asunto: null,
      correos: null,
      mensaje:null,
      horaejecucion:null,
      tipodeformato: [],
      tipodeformatoseleccionado: null,
      tipodelogeo: null,
      peridoseleccionado: null,
      tipodelog: [],
      Mensual: "Mensual",
      Semanal: "Semanal",
      tipodelogseleccionado: null,
      opcionreporteseleccionada: null,
      fechaseleccionada: null,
      fechaseleccionadaPlaceholder: null,
      timeLapseTypeOptions: TIME_LAPSE_TYPE_OPTIONSv2,
      reportTypeOptions: [],
      datoscompletos: [],
      exportTypeOptions: undefined,
      isLoading: false,
      exportOptionDisabled: true,
      // endDateConfig: {
      //   minDate: undefined,
      //   disabled: true
      // },
      editDisabled: false,
      isDisabled: false,
      stateText: 'Activo',
      dataForm: {
        isActive: true,
        subject: '',
        content: '',
        emails: [],
        startDate: undefined,
        endDate: undefined,
        executionHour: undefined,
        // timeLapse: 1,
        typeTimeLapse: TIME_LAPSE_TYPE_OPTIONSv2[0].value,
        exportType: undefined,
        reportType: undefined
      },
      errors: {
        subject: '',
        content: '',
        emails: '',
        startDate: '',
        // endDate: '',
        executionHour: '',
        // timeLapse: '',
        reportType: '',
        exportType: ''
      },
      roleList: this.getRoleIdList(),
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.loadReportOptions();
    if (this.$route.params.id) {
      this.loadReportData(this.$route.params.id);
      this.editDisabled = true;
      this.isDisabled = true;
    }
  },
  methods: {
    loadReportOptions() {
      const reportId = this.$route.params.reportId;
      console.log("Report ID:", reportId);
      const options = {
        method: 'get',
        url: urlBaseTiposdeDocumento + "/get_record_suscription_by_id/" + reportId + '?user='+ this.getUsuario(),
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          this.peridoseleccionado = response.data["Periodo de ejecución"],
          this.reportTypeOptions = [response.data["Reporte"]]
          this.opcionreporteseleccionada = response.data["Reporte"]
          this.tipodeformatoseleccionado = response.data["Formato"]
          this.tipodeformato = [response.data["Formato"]]
          //this.fechaseleccionada = new Date(response.data["Fecha Inicio"])
          this.fechaseleccionadaPlaceholder = new Date(response.data["Fecha Inicio"])
          //const fecha = new Date(response.data["Hora de Ejecución"])
          //const horas = fecha.getHours().toString().padStart(2, "0");
          //const minutos = fecha.getMinutes().toString().padStart(2, "0");
          //this.horaejecucion = `${horas}:${minutos}`;
          this.asunto = response.data["Asunto"]
          this.correos = response.data["Correos"]
          this.mensaje = response.data["Mensaje"]
          this.fecha_de_creacion = response.data["Fecha Inicio"]
          this.fecha_actualizacion = response.data["Fecha creación"]
          this.estado = response.data["Activo"]
          
          this.fechaseleccionada = new Date(response.data["Fecha Inicio"])
          this.horaejecucion =  new Date(response.data["Hora de Ejecución"]);
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => this.isLoading = false);
    },
    submitForm() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, { abortEarly: false })
        .then(() => {
          this.isLoading = true;
          if (this.dataForm.id) {
            this.updateReport();
          } else {
            this.createReport();
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    createReport() {
      this.resetErrorMessages();
      schema.validate(this.dataForm, { abortEarly: false })
        .then(() => {
          this.isLoading = true;
          if (this.dataForm.id) {
            this.updateReport();
          } else {
            this.createReport();
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });

      const options = {
        method: 'post',
        url: urlBaseTiposdeDocumento + "/record_suscription",
        data: {
          "Periodo de ejecución": this.peridoseleccionado,
          "Reporte": this.opcionreporteseleccionada,
          "Formato": this.tipodeformatoseleccionado,
          "Fecha Inicio": this.dataForm.startDate,
          "Fecha creación": new Date(),
          "Hora de Ejecución": this.dataForm.executionHour,
          "Asunto": this.dataForm.subject,
          "Correos": this.dataForm.emails,
          "Mensaje": this.dataForm.content,
          "Activo": true,
            "Usuario": this.getUsuario()
        },
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push("/sendreportsv2");
          }
        })
        .catch((error) => {
          const data = error.response.data;
          if (error.response.status === 400 && data["subErrors"]) {
            for (const item of data["subErrors"]) {
              this.errors = { ...this.errors, [item.field]: item.message }
            }
          }
        })
        .finally(() => this.isLoading = false);
    },
    updateReport() {
      const options = {
        method: 'put',
        url: urlBaseTiposdeDocumento + "/record_suscription",
        data: this.getUpdateData(),
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 202) {
            this.dataForm.modifiedAt = response.data.modifiedAt;
            this.cancel();
          }
        })
        .catch((error) => {
          const data = error.response.data;
          if (error.response.status === 400 && data["subErrors"]) {
            for (const item of data["subErrors"]) {
              this.errors = { ...this.errors, [item.field]: item.message }
            }
          }
        })
        .finally(() => this.isLoading = false);
    },
    cloneDataForm() {
      let copyData = Object.assign({}, this.dataForm);
      copyData.startDate = new Date(copyData.startDate.setHours(copyData.executionHour.getHours(), copyData.executionHour.getMinutes())).toISOString();
      // copyData.endDate = new Date(copyData.endDate.setHours(copyData.executionHour.getHours(), copyData.executionHour.getMinutes())).toISOString();
      delete copyData['executionHour'];
      return copyData;
    },
    getUpdateData() {
      return {
        subject: this.dataForm.subject,
        content: this.dataForm.content,
        exportType: this.dataForm.exportType,
        reportType: this.dataForm.reportType,
        emails: this.dataForm.emails,
        isActive: this.dataForm.isActive,
      }
    },
    beforeAddingEmail(email) {
      if (email.match(validRegex)) {
        this.errors.emails = '';
        return true;
      }
      this.errors.emails = 'El email ingresado no es válido'
      return false;
    },
    onEmailChange(email) {
      if (email === '') {
        this.errors.emails = '';
      } else {
        this.beforeAddingEmail(email);
      }
    },
    // setEndDateRange(value) {
    //   this.dataForm.endDate = undefined;
    //   let newDate = new Date(value.getTime())
    //   if (this.dataForm.typeTimeLapse === 'm') {
    //     this.endDateConfig.minDate = newDate.setMonth(newDate.getMonth() + 1)
    //   } else {
    //     this.endDateConfig.minDate = newDate.setDate(newDate.getDate() + 7)
    //   }
    //   this.endDateConfig.minDate = new Date(this.endDateConfig.minDate);
    //   if (this.endDateConfig.disabled) {
    //     this.endDateConfig.disabled = false;
    //   }
    // },
    // changeEndDate() {
    //   if (this.dataForm.startDate) {
    //     this.setEndDateRange(this.dataForm.startDate);
    //   }
    // },
    // convertInputToNumber(value) {
    //   this.dataForm.timeLapse = Number.parseInt(value);
    //   this.changeEndDate()
    // },
    changeState(value) {
      this.stateText = value ? 'Activo' : 'Inactivo';
    },
    changeExportOptions(value) {
      this.tipodelog = []
      this.tipodelogeo = null
      this.tipodeformato = []
      const subReporte = this.datoscompletos.find(objeto => objeto.Reporte === this.opcionreporteseleccionada)?.SubReporte;
      const subReporteformatos = this.datoscompletos.find(objeto => objeto.Reporte === this.opcionreporteseleccionada)?.Formatos;

      if (subReporte.length !== 0) {
        for (let i = 0; i < subReporte.length; i++) {
          this.tipodelog.push(subReporte[i].join(", "));
        }
        const subReporteformatosanidados = this.datoscompletos.find(objeto => objeto.Reporte === this.opcionreporteseleccionada)?.Formatos;
        console.log("subReporteformatosanidados")
        console.log(subReporteformatosanidados)
      } else {
        for (let y = 0; y < 2; y++) {
          this.tipodeformato.push(subReporteformatos[y]);
        }

      }
      for (const item of this.reportTypeOptions) {
        if (item.value === value) {
          this.exportTypeOptions = item.exportOptions;
          this.exportOptionDisabled = false;
          this.dataForm.exportType = item.exportOptions[0];
          break
        }
      }
    },
    changeExportOptionstipes() {
      this.tipodeformato = []
      console.log(this.opcionreporteseleccionada)
      console.log(this.tipodelogseleccionado)
      const subReporteformatosanidados = this.datoscompletos.find(objeto => objeto.Reporte === this.opcionreporteseleccionada)?.Formatos;
      console.log("subReporteformatosanidados")
      console.log(subReporteformatosanidados[this.tipodelogseleccionado])
      //this.tipodeformato.push(subReporteformatosanidados[this.tipodelogseleccionado])
      for (let z = 0; z < subReporteformatosanidados[this.tipodelogseleccionado].length; z++) {
        this.tipodeformato.push(subReporteformatosanidados[this.tipodelogseleccionado][z])
      }

    },
    resetErrorMessages() {
      for (const key in this.errors) {
        this.errors[key] = '';
      }
    },
    formatDate(date) {
      return dateFormatting(date);
    },
    loadReportData(reportId) {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/reporterere/${reportId}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`,
          'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
        },
      }
      axios(options)
        .then((response) => {
          if (response.status === 200) {
            let reportData = response.data;
            const exportType = reportData.exportType;
            reportData.startDate = new Date(reportData.startDate);
            // reportData.endDate = new Date(reportData.endDate);
            // const endDate = reportData.endDate;
            reportData.executionHour = new Date(reportData.startDate.getTime());
            reportData.startDate = new Date(reportData.startDate.setHours(0, 0));
            this.dataForm = reportData;
            this.changeExportOptions(reportData.reportType);
            this.changeState(reportData.isActive);
            // this.setEndDateRange(reportData.startDate)
            this.dataForm.exportType = exportType;
            this.dataForm.typeTimeLapse = this.dataForm.typeTimeLapse.toUpperCase();
            // this.dataForm.endDate = endDate;
            this.exportOptionDisabled = true;
            // this.endDateConfig.disabled = true;
          }
        })
        .finally(() => this.isLoading = false);
    },
    deleteReport() {
      const reportId = this.$route.params.reportId;
      this.$buefy.dialog.confirm({
        title: 'Reporte automático',
        message: '¿Está seguro de eliminar el reporte identificado con el ID: ' + reportId + '?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          console.log("Error");
          const opciones = {}; // Corrección: Cambiar [] por {}
          this.isLoading = true;
          opciones.method = 'delete';
          opciones.url = urlBaseTiposdeDocumento + "/delete_record_suscription?id=" + reportId + '&user='+ this.getUsuario();
          opciones.headers = {
            'Authorization': `Bearer ${this.token.Bearer}`,
            'Api-key': config["Api-Key-types"] // Reemplaza 'yourApiKey' con tu API key real
          },
            console.log("Error" + opciones);
          console.log(opciones);
          axios(opciones) // Corrección: Cambiar this.options por opciones
            .then((response) => {
              if (response.status === 204) {
                this.data = this.data.filter((value) => value.id !== reportId);
              }
            })
            .finally(() => {
              this.isLoading = false;
              setTimeout(() => {
                location.reload(); // Actualizar la página después de 1.8 segundos (1800 milisegundos)
              }, 1000);
            });
          this.$router.push({ path: '/sendreportsv2' });
          this.isLoading = false;
        },
      });
    },
    cancel() {
      console.log(this.dataForm)
      if (!this.dataForm.id) {
        this.$router.push('/sendreportsv2');
      }
      this.editDisabled = true;
      this.isDisabled = true;
      this.exportOptionDisabled = true;
    },
    editar_nuevo_reporte() {
      const reportId = this.$route.params.reportId;
      this.$router.push('/UpdateReportv2/' + reportId);
    },
    isEditable() {
      return this.roleList.includes(41)
    },
      ...mapGetters(["getUsuario", "getRoleIdList"])
  }
}
</script>

<style scoped>
p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}
</style>