import {
    DATE_FORMAT_OPTIONS,
    DOCUMENT_TYPES,
    MESSAGE_CODES, MESSAGE_CODES_USER_MODIFY,
    MESSAGE_CODES_USER_REGISTER,
    STATES, TIME_LAPSE_TYPE_OPTIONS,
    VU_SERVICE_PROVIDER
} from "./constants/GeneralConstants";

import Store from "../../store";

export function dateFormatting(date) {
    const dateFormatted = new Date(date).toLocaleString('es-CO', DATE_FORMAT_OPTIONS);
    return dateFormatted[0].toUpperCase() + dateFormatted.slice(1);
}

export function getFecha(date) {
    const dateFormatted = new Date(date).toISOString().slice(0, 10);
    return dateFormatted;
}

export function getHour(date) {
    const hourFormatted = new Date(date).toLocaleString('es-CO').slice(10);
    return hourFormatted;
}

export function userMail(mail) {
    var user = mail.split('@')[0];
    return user;
}

export function estadoReporte(state) {
    var estado = 'Inactivo';
    if (state) {
        estado = 'Activo';
    }
    return estado;
}

export function getTagState(status) {
    return status === 201 || status === 200 || status === 701 ? 'is-success' : 'is-danger';
}

export function tagRegistraduria(status) {
    console.log(status);
    /*if (status === "ERROR_VALIDACION") return "is-danger";
      else if (status === "IDENTIDAD_VALIDADA") return "is-success";
      else return "is-warning";*/

    return status === 'IDENTIDAD_VALIDADA' ? 'is-success' : 'is-danger';
}

export function getStatusText(status) {
    return status === 201 || status === 200 || status === 701 ? 'Exitosa' : 'Fallida';
}

export function typeDocFormatting(user) {
    const regex = /(\D+)/g;
    const type = user.match(regex)[0];
    return DOCUMENT_TYPES[type] ? DOCUMENT_TYPES[type] : 'No Disponible';
}

export function messageFormatting(message, type = 'default') {
    if (message.indexOf('{') === -1) {
        return 'Error de Servidor'
    } else {
        const newMessage = JSON.parse(message);
        let message_codes;
        if (type === 'register') {
            message_codes = MESSAGE_CODES_USER_REGISTER;
        } else if (type === 'modify') {
            message_codes = MESSAGE_CODES_USER_MODIFY;
        } else {
            message_codes = MESSAGE_CODES;
        }
        console.log(newMessage);
        return message_codes[newMessage.code] ? message_codes[newMessage.code] : 'Fallida';
    }
}

export function numDocFormatted(user) {
    const regex = /(\d+)/g;
    return user.match(regex)[0];
}

export function userFormatted(user) {
    const data = user.toLowerCase().split(/(\d+)/)
    return [data[0], data[1]];
}

export function countryFormatted(country) {
    const data = country.split(/-(.*)/s)
    return [data[0], data[1]];
}

export function percentFormatted(number) {
    let newNumber = (number * 100).toFixed(2);
    return `${newNumber} %`;
}

//validar este punto
export function formatData(data) {
    let newData = [];
    let splitString;
    for (const item of data) {
        if (item['usuario'] || item['identificacion']) {
            splitString = userFormatted(item[item['usuario'] ? 'usuario' : 'identificacion'])
            item['userIdType'] = DOCUMENT_TYPES[splitString[0]];
            item['userId'] = splitString[1];
            if (item['userIdType'] == "" || item['userIdType'] == null) {
                item['userIdType'] = item['tipoDoc'];
            }
        }
        if (item['cliente']) {
            item.cliente = getTextCamelCase(item.cliente);
        }
        if (item['lastEvent']) {
            item.lastEvent = getTextCamelCase(item.lastEvent);
        }
        newData.push(item);
    }
    return newData;
}

export function formatDataLogs(data) {
    let newData = [];
    let splitString;
    for (const item of data) {
        item['Funcionalidad'] = splitString[2]
        if (item['usuario'] || item['identificacion']) {
            splitString = userFormatted(item[item['usuario'] ? 'usuario' : 'identificacion'])
            item['userIdType'] = DOCUMENT_TYPES[splitString[0]];
            item['userId'] = splitString[1];
            if (item['userIdType'] == "" || item['userIdType'] == null) {
                item['userIdType'] = item['tipoDoc'];
            }
        }
        if (item['cliente']) {
            item.cliente = getTextCamelCase(item.cliente);
        }
        if (item['lastEvent']) {
            item.lastEvent = getTextCamelCase(item.lastEvent);
        }
        newData.push(item);
    }
    return newData;
}

export function formatAutomaticReportData(data) {
    let newData = [];
    for (const item of data) {
        item.emails = item.emails.join(', ');
        for (const itemElement of TIME_LAPSE_TYPE_OPTIONS) {
            if (itemElement.value === item.typeTimeLapse.toUpperCase()) {
                item.typeTimeLapse = itemElement.label;
                break
            }
        }
        item.isActive = item.isActive ? 'Activo' : 'Inactivo';
        newData.push(item);
    }
    return newData;
}

export function getTagFace(scoreConfigured, scoreTotal) {
    if ((parseFloat(scoreConfigured) * parseFloat(scoreTotal)) <= 0) return 'is-warning';
    else if (scoreConfigured > scoreTotal) return 'is-danger';
    return 'is-success';
}

export function getStatusFace(scoreConfigured, scoreTotal) {
    if ((parseFloat(scoreConfigured) * parseFloat(scoreTotal)) <= 0) return 'No autorizado';
    else if (scoreConfigured > scoreTotal) return 'No autorizado';
    return 'Autorizado';
}

export function getTagFaceYN(autorizado) {
    if (autorizado != "Sí") return 'is-danger';
    return 'is-success';
}

export function getStatusFaceTwo(autorizado) {
    if (autorizado != "Sí") return 'No';
    return 'Si';
}

export function getStatusTagSOP(scoreConfigured, scoreTotal, status) {
    if (scoreConfigured > scoreTotal) return 'is-danger';
    if (STATES[status]) return STATES[status].tag;
    return 'is-success';
}

export function getStatusTagFinan(status) {
    if (status == 'Pendiente') return 'is-warning';
    if (status == 'Cancelado') return 'is-warning';
    if (status == 'Rechazado') return 'is-danger';
    return 'is-success';
}

export function getTextCamelCase(str) {
    if (str !== "undefined") {
        let newStr = "";
        for (let word of str.split(" ")) {
            newStr += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
        }
        return newStr;
    }
    return str;
}

export function getDateRange(dateList) {
    const firstDate = new Date(dateList[0]);
    const secondDate = new Date(dateList[1]);
    firstDate.setHours(firstDate.getHours());
    secondDate.setHours(18, 59, 59, 999);

    dateList = [firstDate, secondDate]

    return dateList;
}

export function getPortal(serviceProvider) {
    return VU_SERVICE_PROVIDER.get(serviceProvider);
}

export function redirectAuthenticated(to, from, next) {
    if (Store.getters.getTokenAuth === '') {
        next();
        return;
    }
    next("/");
}

export function removeEditButtons(buttonList) {
    return buttonList.filter((buttonData) => buttonData["action"] !== 'delete')
}