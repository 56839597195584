<template>
  <main-layout itemMenuActive="31" tituloMenu="Logs de Auditoría">
    <div class="container is-full">
      <br />
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div>
                  <b-field grouped label="Logs de Auditoría">
                    <b-field label="">
                      <h1 for="" style="margin-right: 1rem; line-height: 2.5rem;">
                        Tipo de Log:
                      </h1>
                      <b-select id="documento" placeholder="Seleccione un tipo de Log" v-model="opcionSele" expanded
                        v-on:change.native="cambio">
                        <option disabled value="Seleccione un tipo de Log">Seleccione un tipo de Log</option>
                        <option value="habilitacion">Habilitación de documentos</option>
                        <option value="parametrizacion">Parametrización tipos de documento</option>
                        <option value="textParametrization">Parametrización de Textos</option>
                        <option value="exportLogs">Exportación de Reportes</option>
                        <option value="envioProgramados">Envío Programados</option>
                        <option value="iconsParametrization">Parametrización de Iconos</option>
                        <option value="asignacionRoles">Asignación roles</option>
                      </b-select>
                    </b-field>
                    <div v-if="isAbleExport()" class="uk-navbar-right">
                      <b-field label="">
                        <b-button :disabled="this.dataTable.length === 0" v-on:click="exportar_csv_general">CSV</b-button>
                        <b-button :disabled="this.dataTable.length === 0"
                          v-on:click="exportar_xlsx_general">Excel</b-button>
                        <b-button :disabled="this.dataTable.length === 0" v-on:click="exportar_pdf_general">PDF</b-button>
                      </b-field>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <b-field grouped  label="">
                    <b-field label="Fecha">
                      <b-datepicker placeholder="Seleccione fecha..." v-model="fecha" range
                        :max-date="maxDate"></b-datepicker>
                    </b-field>

                    <b-field label="Usuario">
                      <b-select v-model="opcionSeleccionada" placeholder="Seleccione Usuario">
                        <option v-for="item in usuarioslogs" :value="item" :key="item.id">
                            {{ item.email }}
                          </option>
                      </b-select>
                    </b-field>

                    <div class="uk-navbar-right">
                      <b-field label="">
                        <b-button native-type="submit" type="is-info" style="width: 93%;" @click="consultar"
                          :disabled="!(fecha.length > 0 || opcionSeleccionada || selectedOption.length > 0)">Consultar</b-button>
                      </b-field>
                      <br>
                      <b-field label="">
                        <b-button native-type="submit" type="is-info" style="width: 90%;"
                          @click="limpiar">Limpiar</b-button>
                      </b-field>
                    </div>

                </b-field>
                <b-field>
                  <div class="columns">
                  <div class="column is-two-sixths">
                    <label class="label">Tipo de documento:</label>
                    <div class="control has-icons-left">
                      <div>
                        <ul>
                          <li v-for="(pareja, index) in parejas.slice(0, Math.ceil(parejas.length / 2))" :key="index">
                            <label class="checkbox">
                              <input type="checkbox" :value="pareja.code" v-model="selectedOption">
                              {{ pareja.Label }}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="column is-two-sixths">
                    <label class="label">Tipo de documento:</label>
                    <div class="control has-icons-left">
                      <div>
                        <ul>
                          <li v-for="(pareja, index) in parejas.slice(Math.ceil(parejas.length / 2))" :key="index">
                            <label class="checkbox">
                              <input type="checkbox" :value="pareja.code" v-model="selectedOption">
                              {{ pareja.Label }}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <TableCard messageEmpty="" :columns="columns" :info="dataTable" detailKey="id" :tableConfig="tableConfig"
                :haveFilter="false"></TableCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
//import {formatDataLogs} from "../../assets/config/utilities";
import { COLUMNS_REPORTES_PARAMETRIZACION } from "../../assets/config/constants/TableConstants";
import axios from 'axios';
import iconv from 'iconv-lite';
import { config } from "../../assets/config/jsonconfig.json";
import {saveLog} from "../../services/exportLogs";

const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

export default {

  name: "SopTransaction",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {
      numeroDocumento: null,
      selectedOption: [],
      options: [],
      opcionSele: "parametrizacion",
      parejas: [],
      usuarioslogs: [],
      opcion: '',
      tiposSeleccionados: [],
      usuarioSeleccionado: null,
      opcionesUsuarios: [],
      opcionesTipos: [],
      opcionSeleccionada: null,
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      total_exitosas: 0,
      total_fallidas: 0,
      isLoading: false,
      dataTable: [],
      counter: 0,
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      locale: "en-CA",
      minDate: new Date(),
      maxDate: new Date(),
      columns: COLUMNS_REPORTES_PARAMETRIZACION,
      roleList: this.getRoleIdList(),
      token: null
    };
  }, created() {
    axios.get(urlBaseTiposdeDocumento + '/get_documents_code', {
      headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
      },
    })
      .then(response => {
        this.parejas = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },

  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.obtenerRangoFecha();
    this.get_users();
  },

  methods: {
    ...mapGetters(["getRoleIdList"]),
    isAbleExport() {
      return this.roleList.includes(49)
    },
    exportar_csv_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_csv';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');

      const data = {
        data: {
          "Fecha": this.fecha,
          "Tipo de Documento": this.selectedOption,
          "Usuario": this.opcionSeleccionada.email
        },
        url: urlBaseTiposdeDocumento + "/get_reporter_parametrization_types",
        filename: "data.csv",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de Documento", "Accion", "Funcionalidad", "Valor Anterior", "Valor Nuevo"]
      };
        this.saveExportLog("csv")

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.text();
        })
        .then(text => {
          const buffer = iconv.encode(text, 'latin1');
          const blob = new Blob([buffer], { type: 'text/csv' });
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const fileName = `data_${formattedDate}.csv`;
          const encodedFileName = encodeURI(fileName);
          link.setAttribute('download', encodedFileName);
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    exportar_xlsx_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_excel';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');

      const data = {
        data: {
          "Fecha": this.fecha,
          "Tipo de Documento": this.selectedOption,
          "Usuario": this.opcionSeleccionada.email
        },
        url: urlBaseTiposdeDocumento + "/get_reporter_parametrization_types",
        filename: "data.xlsx",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de Documento", "Accion", "Funcionalidad", "Valor Anterior", "Valor Nuevo"]
      };
        this.saveExportLog("excel")
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.xlsx`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    exportar_pdf_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento + '/download_pdf';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');

      const data = {
        data: {
          "Fecha": this.fecha,
          "Tipo de Documento": this.selectedOption,
          "Usuario": this.opcionSeleccionada.email
        },
        url: urlBaseTiposdeDocumento + "/get_reporter_parametrization_types",
        filename: "data.pdf",
        columns: ["Nro", "Fecha y hora", "Usuario", "Tipo de Documento", "Accion", "Funcionalidad", "Valor Anterior", "Valor Nuevo"]
      };
        this.saveExportLog("pdf")
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.pdf`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    sumar() {
      if (this.fecha.length !== 0) {
        let ultimaFecha = this.fecha[this.fecha.length - 1];
        let fechaObjeto = new Date(ultimaFecha);
        fechaObjeto.setDate(fechaObjeto.getDate() + 1);
        let fechaSumadaFormateada = fechaObjeto.toISOString();
        this.fecha[this.fecha.length - 1] = fechaSumadaFormateada;
      }
    },
    consultar() {
      this.sumar()
      const ruta = urlBaseTiposdeDocumento + '/get_reporter_parametrization_types';
      const data = {
        Fecha: this.fecha,
        "Tipo de Documento": this.selectedOption,
        "Usuario": this.opcionSeleccionada.email
      }

      axios.post(ruta, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token.Bearer}`
        }
      }).then((response) => {
        response.data.forEach((item) => {
          this.dataTable.push(item);
        });
      })
        .catch((error) => {
          console.log(error);
        });
    },
    convertirFechadata(fechaString) {
      const fecha = new Date(fechaString);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    },
    sumarDia(fechaString) {
      const fecha = new Date(fechaString);
      fecha.setDate(fecha.getDate() + 1);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    }
    ,
    limpiar() {
      this.usuarioSeleccionado = null;
      this.opcionSeleccionada = null;
      this.selectedOption = [];
      this.numeroDocumento = "";
      this.fecha = []
    },
    get_users() {
      const options = {
        method: "get",
        uri: "auth/ListUsers",
      };
      this._axios(options)
        .then((response) => {
          this.usuarioslogs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerRangoFecha() {
      const options = {
        method: "get",
        uri: "boards/getRangeDate",
      };
      this._axios(options)
        .then((response) => {
          this.minDate = new Date(response.data[0]);
          this.maxDate = new Date(response.data[1]);
        })
        .catch((error) => {
          this.isLoadingTable = false;
          console.log(error);
        })
        .finally(() => this.cancelarLoading());
    },
    handleSubmit(data) {

      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;
        this.dataTable = [];
        this.counter = 0;

        axios.get(data)
          .then((response) => {
            response.data.forEach((item) => {
              this.dataTable.push(item);
            });
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    cambio() {
      if (this.opcionSele === "habilitacion") {
        this.$router.push('/logs_habilitacion')
      }
      if (this.opcionSele === "parametrizacion") {
        this.$router.push('/ReportesParametrizacion')
      } else if (this.opcionSele === "textParametrization") {
        this.$router.push('/textParametrizationLogs')
      } else if (this.opcionSele === "exportLogs") {
          this.$router.push('/exportLogs')
      } else if (this.opcionSele === "envioProgramados"){
          this.$router.push('/envioProgramados')
      } else if (this.opcionSele === "iconsParametrization"){
        this.$router.push('/iconParametrizationLogs')
      } else if (this.opcionSele === "asignacionRoles"){
        this.$router.push('/asignacionRolesLogs');
      }
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    convertFecha: function (fecha) {
      const dtf = new Intl.DateTimeFormat(this.locale);
      return dtf.format(new Date(fecha));
    },
      saveExportLog(type) {
          let logData = {
              "user": this.getUsuario(),
              "sectionId": 4,
              "reportId": 1,
              "subReport": "Parametrización tipos de documento",
              "subReportId": 2,
              "format": type
          }
          if (this.fecha.length !== 0) {
              logData.startDate = this.fecha[0]
              logData.endDate = new Date(this.fecha[1])
              logData.endDate.setDate(logData.endDate.getDate() - 1)
          }
          saveLog(logData);
      },
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
      ...mapGetters(["getUsuario"])
  },
};
</script>
<style>
:root {
  --primary: #00522a;
}

b-table {
  font-size: small;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}

.has-text-primary {
  color: #00522a !important;
}

.styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}

.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}

.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>