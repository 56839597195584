var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"uk-button uk-text-capitalize router-item",style:({
            padding: '.4rem',
            borderRadius: '1px',
            width: '100%',
            textAlign: 'left',
            // background: optionSelected == optionNum ? '#304156' : '#304156',
            color: this.getOptionSelected() === this.getOptionNum() ? '#409EFF' : '#BFCBD9'
          }),attrs:{"to":_vm.route}},[_c('i',{class:_vm.getIconClass(),staticStyle:{"margin-right":"0.1rem"}}),_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }