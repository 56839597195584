<template>
  <main-layout :itemMenuActive="35" tituloMenu="Habilitación tipos de documento">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <p class="title is-4 centered-text">Habilitación tipos de documento</p>
          <br>
          <div class="columns">
            <div class="column">
              <b-field horizontal label="Tipo de Usuario">
                <b-select placeholder="Seleccione una opción" v-model="processSelected" expanded  class="select-field">
                  <option>Persona</option>
                  <option>Empresa</option>
                </b-select>
              </b-field>
            </div>
            <div></div>
          </div>
          <div style="margin: 1cm;">
          
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
    </div>  
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import axios from "axios";
import { setLocale } from "yup";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});

export default {
  name: "Parametrization",
  components: {
    MainLayout
  },
  data() {
    return {
      guardarHabilitado: false,
      processSelected:null,
      isLoading: false,
      documentTypes: [], // nuevo campo de datos para los tipos de documento
      selectedDocuments: [], // campo de datos para almacenar los tipos de documento seleccionados
      token: null
    }
  },
  watch: {
    processSelected(newVal) {
      if (newVal === 'Persona') {
        this.$router.push('/habilitacionTDPersona');
      }
      if (newVal === 'Empresa') {
        this.$router.push('/habilitacionTDEmpresa');
      }
      else {
        this.fetchDocumentTypes();
      }
      },
      selectedDocuments(newSelectedDocuments) {
        this.guardarHabilitado = newSelectedDocuments.length > 0 || this.checkOriginalCheckboxes();
      }
  },
  computed: {

  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
  },
  methods: {
    
    checkOriginalCheckboxes() {
    let check = false;
    this.documentTypes.forEach((documentType) => {
      if (documentType.persona.Autenticacion || documentType.persona.Recuperacion || documentType.persona.Creacion || documentType.persona['Administracion de Delegados']) {
        check = true;
      }
    });
    return check;
  },
  descartar() {
    this.selectedDocuments = [];
    this.documentTypes.forEach((documentType) => {
      documentType.persona.Autenticacion = false;
      documentType.persona.Recuperacion = false;
      documentType.persona.Creacion = false;
      documentType.persona['Administracion de Delegados'] = false;
    });
  },
  guardar() {
    // aquí se guarda la configuración de los tipos de documento seleccionados
  },

    async fetchDocumentTypes() {
      this.isLoading = true;
      try {
        const response = await axios.get(urlBaseTiposdeDocumento+"/tiposdedocumento", {
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          },
        });
        this.documentTypes = response.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
}
.select-field{
  max-width: 300px; /* Ajusta el ancho máximo según sea necesario */
}
</style>