<template>
    <main-layout :itemMenuActive="0" tituloMenu="Switch Servicios Creación">
        <br>
        <div class="container">
            <div class="card">
                <div class="columns">
                    <div class="column">
                        <div class="card-content">
                            <b-table :data="dataTable" :narrowed="true" :isBordered="true" default-sort-direction="asc">
                                <b-table-column field="Servicio" label="Servicio" v-slot="props" centered>
                                    {{ props.row.form }}
                                </b-table-column>

                                <b-table-column label="Osmod" custom-class="is-centered">
                                    <template slot-scope="props">
                                        <b-radio :native-value="1" v-model="props.row.system"
                                            :name="'system-' + props.row.id"
                                            :disabled="!isEditing(props.row.id)"></b-radio>
                                    </template>
                                </b-table-column>
                                <b-table-column label="Oscon" custom-class="is-centered">
                                    <template slot-scope="props">
                                        <b-radio :native-value="2" v-model="props.row.system"
                                            :name="'system-' + props.row.id"
                                            :disabled="!isEditing(props.row.id)"></b-radio>
                                    </template>
                                </b-table-column>

                                <b-table-column field="justification" label="Justificación" custom-class="is-centered">
                                    <template slot-scope="props">
                                        <input v-if="isEditing(props.row.id)" placeholder="Diligencie el motivo del cambio" v-model="props.row.justification"
                                            type="text" class="input" />
                                        <span v-else>{{ props.row.justification }}</span>
                                    </template>
                                </b-table-column>

                                <b-table-column label="Acción" custom-class="is-centered" v-if="isDisable()">
                                    <template slot-scope="props">
                                        <div :id="props.row.id">
                                            <div v-if="isEditing(props.row.id)">
                                                <button class="btn btn-outline-secondary  border-0"
                                                    @click="confirmarCambios(props.row)">
                                                    <i style="font-size: 20px; color: #666"
                                                        class="bx bx-check-circle"></i>
                                                </button>
                                                <button class="btn btn-outline-secondary  border-0"
                                                    @click="cancelarEdicion()">
                                                    <i style="font-size: 20px; color: #666" class="bx bx-x-circle"></i>
                                                </button>
                                            </div>
                                            <div v-else>
                                                <button class="btn btn-outline-secondary  border-0"
                                                    @click="editar(props.row.id)">
                                                    <i style="font-size: 20px; color: #666" class="bx bx-edit-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </b-table-column>
                                <template #bottom-left>
                                    <b>Total registros encontrados</b>: {{ dataTable.length }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import { mapGetters } from "vuex";
import axios from "axios";
//import TableCard from "../../components/cards/TableCard.vue";
import { COLUMNS_SYNTETHICUSERS } from "../../assets/config/constants/TableConstants";
import { config } from "../../assets/config/jsonconfig.json";
import Swal from 'sweetalert2';
const urlFlaskBackend = config.urlFlaskBackend;

export default {
    name: "Syntetic",
    components: {
        MainLayout,
        //TableCard
    },
    data() {
        return {
            radio: 'Oscon',
            isSwitchedCustom: false,
            form: '',
            descripcion: '',
            isAddActive: false,
            idseleccionado: 0,
            id: null,
            state: false,
            isPaginated: true,
            isPaginationRounded: true,
            perPage: 10,
            isLoading: false,
            isActive: undefined,
            isActiveText: "",
            dataTable: [],
            roleList: this.getRoleIdList(),
            token: null,
            edicion: null
        }
    },
    props: {
        columns: COLUMNS_SYNTETHICUSERS,
        info: undefined,
        tableConfig: {
            type: Object,
            default: () => {
                return {
                    agregar: true,
                    idseleccionado: 0,
                    edicion: true,
                    isPaginated: true,
                    isPaginationRounded: true,
                    perPage: 10,
                    backendSorting: true,
                    isDetailed: true,
                }
            }
        },
        type: {
            type: Number,
            default: 1
        },
    },
    watch: {
        isActive(newValue) {
            this.isActiveText = newValue ? "Activo" : "Inactivo";
        }
    },
    async mounted() {
        this.getToken();
    },
    methods: {
        getToken() {
            const options = {
                method: 'get',
                url: urlFlaskBackend + "switch/getToken",
                headers: {
                    "x-api-key": config["Api-key_Flaskbacked"]
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status === 200) {
                        this.token = response.data.token;
                        this.loadReportList()
                    }
                });
        },
        loadReportList() {
            this.isLoading = true;
            const options = {
                method: 'get',
                url: urlFlaskBackend + "switch/getSystemParams",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                },
            }
            axios(options)
                .then((response) => {
                    if (response.status === 200) {
                        response.data.forEach((obj) => {
                            obj.justification = '';
                            this.dataTable.push(obj);
                            console.log(obj);
                        });
                    }
                })
                .finally(() => this.isLoading = false);
        },
        isEditing(id) {
            return this.edicion === id;
        },
        editar(id) {
            this.edicion = id;
        },
        cancelarEdicion() {
            this.edicion = null;
            this.dataTable = [];
            this.loadReportList();
            // Opción: restablecer los datos originales si es necesario
        },
        /*async confirmarCambios(row) {
            // Lógica para enviar datos al backend
           console.log(row);
           const params = {
                "usuario": this.getUsuario(),
                "formulario": row.form,
                "valor": row.system,
                "description": row.descripcion
            }
            try {
                await axios.put(urlFlaskBackend + "switch/updateSystemParams", params,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    }
                )
                const result = await Swal.fire({
                    title: 'Cambios realizados exitosamente',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })

                if (result.isConfirmed) {
                    this.dataTable = [];
                    this.loadReportList();
                    this.edicion = true;
                }

            } catch (error) {
                const result = await Swal.fire({
                    title: 'Error en la base de datos - ' + error.response.data.error,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

                if (result.isConfirmed) {
                    console.error(error);
                }
                console.error(error);
            }
        },*/
        /*editar(id, paramAlfanumeric,form) {
            console.log("parametros editar:",id,paramAlfanumeric,form);
            this.state = null;
            this.form = form;
            this.primeravez = true;
            this.idseleccionado = id;
            this.edicion = !this.edicion;
            this.state = paramAlfanumeric;
            if (paramAlfanumeric === 'false') {
                this.state = false;
            } else {
                this.state = Boolean(paramAlfanumeric);
            }
        },*/
        async confirmarCambios(row) {
            const result = await Swal.fire({
                title: '¿Estás seguro de realizar los cambios?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                cancelButtonColor: '#d33',
                reverseButtons: true
            })
            if (result.isConfirmed) {
                this.guardarCambios(row)
            }else {
                this.cancelarEdicion();   
            }
        },
        async guardarCambios(row) {
            const params = {
                "usuario": this.getUsuario(),
                "formulario": row.form,
                "valor": row.system,
                "descripcion": row.justification
            }
            try {
                await axios.put(urlFlaskBackend + "switch/updateSystemParams", params,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    }
                )
                const result = await Swal.fire({
                    title: 'Cambios realizados exitosamente',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })

                if (result.isConfirmed) {
                    this.dataTable = [];
                    this.loadReportList();
                    this.edicion = true;
                }

            } catch (error) {
                const result = await Swal.fire({
                    title: 'Error en la base de datos - ' + error.response.data.error,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })

                if (result.isConfirmed) {
                    console.error(error);
                }
                console.error(error);
            }

        },
        ...mapGetters(["getRoleIdList"]),
        ...mapGetters(["getUsuario"]),
        isDisable() {
            return this.roleList.includes(82)
        },
    }
}
</script>

<style>
.btn-outline-secondary {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    outline: none;
}

.my-swal-popup {
    width: 800px;
}

.labeled {
    border-radius: 5px;
    border: 1px solid grey;
    padding: 5px;
}
</style>