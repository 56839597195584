<template>
  <main-layout :itemMenuActive="21" tituloMenu="Evidente Master">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content"><p class="title is-4 centered-text">Configuración</p>
          <div class="columns">
            <div class="column is-half">
              <b-switch @input="changeState" v-model="isActive" :disabled="isDisable()">{{ isActiveText }}</b-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Evidente",
  components: {
    MainLayout
  },
  data() {
    return {
      isLoading: false,
      isActive: undefined,
      isActiveText: "",
      roleList: this.getRoleIdList()
    }
  },
  watch: {
    isActive(newValue) {
      this.isActiveText = newValue ? "Activo" : "Inactivo";
    }
  },
  mounted() {
    this.loadState()
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isDisable() {
      return !this.roleList.includes(62)
    },
    loadState() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/evidente/state`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        }
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.isActive = response.data.data.state;
            }
          })
          .finally(() => this.isLoading = false);
    },
    changeState(value) {
      this.isLoading = true;
      const options = {
        method: 'post',
        url: `${HOST_API}/evidente/state`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        }
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.$buefy.toast.open({
                message: `El cambio se guardo exitosamente`,
                type: 'is-success'
              })
            }
          })
          .catch(() => {
            this.isActive = !value;
          })
          .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style scoped>

</style>