<template>
  <main-layout :itemMenuActive="36" tituloMenu="Parametrización de Tipos de Documentos">
    <br>
    <div class="container">
  
    <div class="columns is-mobile is-multiline is-centered" >
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <TableCard messageEmpty=""
                         :columns="columns" :info="dataTable"
                         detailKey="id" :tableConfig="tableConfig"
                         :haveFilter="false"></TableCard>
            </div>
          </div>
        </div>
      </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
    </div>  
  </main-layout>
</template>
<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
import { COLUMNS_PARAMETRIZACION_HABILITACION } from "../../assets/config/constants/TableConstants";
import axios from 'axios';
import { config } from "../../assets/config/jsonconfig.json";
import Swal from 'sweetalert2'
import { getToken } from "../../assets/config/getToken.js";

const url = config.urlBaseTiposdeDocumento

export default {
  name: "SopTransaction",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {
      showAlert: false,
      showEditButton: true,
      parejas: [],
      usuarioslogs:[],
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      isLoading: false,
      dataTable: [],
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      columns: COLUMNS_PARAMETRIZACION_HABILITACION,
      roleList: this.getRoleIdList(),
      token: null
    };
  },
  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.checkRoles();
    this.handleSubmit(url+'/tiposdedocumento')
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isDisabled() {
      return !this.roleList.includes(58)
    },
    checkRoles() {
      if (this.isDisabled()) {
        delete this.columns["Dashboard"];
        this.columns["AlfaNumerico"].disabled = true;
      }
    },
    async mostrarAlerta() {
      const result = await Swal.fire({
        title: '¿Estás seguro de realizar los cambios?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Descartar'
      })
      if (result.isConfirmed) {
        this.guardar()
      } else {
        this.descartar()
      }
    },
    editButtonClicked() {
    },
    checkButtonClicked() {
      // Lógica para el botón de chulo
    },
    cancelButtonClicked() {
      // Lógica para el botón de x
    },
       
    handleSubmit(data) {
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;     
        this.dataTable = [];
        this.counter = 0;       
        axios.get(data, {
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          },
        })
            .then((response) => {
              response.data.forEach((item) => {
                const link = `http://www.example.com/${item.id}`;
                item["prueba"] = (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    Enlace
                  </a>
                );
                this.dataTable.push(item);
              });
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    }
    ,  
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
  },
};
</script>
<style>
.edit-button {
  background: none;
  border: none;
}

.check-button,
.cancel-button {
  display: none;
}

.check-button.show,
.cancel-button.show {
  display: inline-block;
}

.check-button i,
.cancel-button i {
  margin-right: 0.5em;
}
</style>