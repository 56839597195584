<template>
  <main-layout :itemMenuActive="25" tituloMenu="Consultar Delegados">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <p class="column is-half title is-4 centered-text">Usuario</p>
          </div>
          <div class="columns">
            <div class="column is-half">
              <b-field label="Búsqueda por" horizontal :type="formErrors.represent ? 'is-danger' : ''"
                :message="formErrors.represent">
                <b-select ref="represent" placeholder="Seleccione una opción" expanded
                          v-model="formData.represent" v-on:change.native="cambioTipo">
                  <option
                      v-for="(value, key) in represents"
                      :value="value.value"
                      :key="key">
                    {{ value.text }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <b-field label="Tipo de documento" horizontal :type="formErrors.documentType ? 'is-danger' : ''"
                       :message="formErrors.documentType">
                <b-select ref="tipoSelect" placeholder="Seleccione una opción" expanded
                          v-model="formData.documentType" v-on:change.native="cambioTipo">
                  <option
                      v-for="(value, key) in documentTypes"
                      :value="value.tipo"
                      :alfanumerico="value.alfa"
                      :key="key">
                    {{ value.text }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-two-fifths">
              <b-field horizontal label="Número de documento" :type="formErrors.documentNumber ? 'is-danger' : ''"
                       :message="formErrors.documentNumber">
                <b-input 
                ref="numeroDocumento"
                pattern="[0-9]+"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                name="subject" expanded v-model="formData.documentNumber"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-button type="is-primary" @click="submit" :loading="isLoading">Buscar</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div v-if="userData && userData.ciamId" class="container">
      <div v-if="userData && userData.ciamId" class="card">
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column is-7">
                <p class="title is-4" style="margin-bottom: 5px;">
                  {{ userData.legalName !== undefined ? userData.legalName : userData.name }}
                </p>
              </div>
              <div class="column uk-text-right">
                <label class="uk-form-label"></label>
                <b-button v-on:click="exportCSV">CSV</b-button>
                <b-button v-on:click="exportExcel">XLSX</b-button>
                <!--<b-button v-on:click="exportPDF">PDF</b-button>-->
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b>Documento: </b> {{ userData.document }}
                <br>
                <b>CIAM ID: </b> {{ userData.ciamId }}
                <br>
                <b>Correo: </b> {{ userData.email }}
                <br>
                <b>Teléfono: </b> {{ userData.phone }}
              </div>
              <div class="column" v-if="userData.legalName === undefined && userData.maritalStatusResponse">
                <b>Estado civil: </b> {{ userData.maritalStatusResponse.description }}
                <br>
                <b>Fecha de nacimiento: </b> {{ userData.birthday }}
                <br>
                <b>Género: </b> {{ userData.gender }}
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card">
        <div class="card-content">
          <TableCard messageEmpty="No tiene delegados"
                     :columns="columns" :info="userData.tabla"
                     detailKey="ciamId"
                     :ciamId="userData.ciamId"
                     :represent="formData.represent"
                     :haveFilter="false"
                     :tableType="5"
                     :tableConfig="tableConfig"></TableCard>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import axios from "axios";
import {setLocale, object, string} from "yup";
import TableCard from "../../components/cards/TableCard";
import {COLUMNS_PROXY_QUERY_TABLE} from "../../assets/config/constants/TableConstants";
import {saveLog} from "../../services/exportLogs";
import {mapGetters} from "vuex";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});

export default {
  name: "SearchProxies",
  components: {
    MainLayout,
    TableCard
  },
  data() {
    return {
      userData: undefined,
      isLoading: false,
      formData: {
        documentType: undefined,
        documentNumber: undefined,
        represent: undefined,
      },
      documentTypes: null,
      represents: [{
        value: 'representation',
        text: 'Representado',
      }, {
        value: 'delegation',
        text: 'Representante',
      }],
      schema: object().shape({
        documentType: string().required().max(6),
        documentNumber: string().required().min(1),
        represent: string().required().min(1),
      }),
      formErrors: this.getFormErrorsScheme(),
      columns: COLUMNS_PROXY_QUERY_TABLE,
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: true,
      },
      token: null
    }
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.apiTiposDocumento().then((res) => {
      let opTipos = res.filter(tipo => {
        return tipo.empresa['Administracion de Delegados'] == true
      }).map(function (tipo) {
        return ({
          tipo: tipo.code.toLowerCase(),
          text: tipo.Title,
          alfa: tipo.AlfaNumerico,
        })
      }).sort((x, y) => x.text.localeCompare(y.text));

      this.documentTypes = opTipos
      this.formData.represent = 'representation';
    });
  },
  methods: {
    async apiTiposDocumento() {
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento+'/tiposdedocumento',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
      }).then((res) => {
        return res.data;
      }).catch((error) => {
          console.error(error)
      })
    },
    cambioTipo() {
      let alfaNumerico = this.$refs.tipoSelect.$refs.select.selectedOptions[0].getAttribute('alfanumerico')
      let pattern = '[0-9]+';
      let oninput = "this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\..*)\\./g, '$1');"
      if (alfaNumerico == 'true') {
          pattern = '[0-9A-Za-zÑñ]+';
          oninput = "this.value = this.value.replace(/[^0-9A-Za-zÑñ]/g, '');"
      }
      this.$refs.numeroDocumento.$refs.input.value = '';
      this.$refs.numeroDocumento.$refs.input.pattern = pattern
      this.$refs.numeroDocumento.$refs.input.setAttribute('oninput', oninput);
    },
    submit() {
      this.isLoading = true;
      this.formErrors = this.getFormErrorsScheme();
      this.schema.validate(this.formData, {abortEarly: false})
          .then(() => {
            this.loadProxies();
          }).catch((err) => {
        err.inner.forEach((error) => {
          this.formErrors = {...this.formErrors, [error.path]: error.message};
          this.isLoading = false;
        });
      });
    },
    async cargarDta() {
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento+`/control_delegados/`+ this.formData.represent +`/`+ this.formData.documentType +`/`+ this.formData.documentNumber,
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
      }).then((response) => {
        return response;
      }).catch((error) => {
        return error;
      }).finally(() => this.isLoading = false);
    },
    async loadProxies() {
      this.userData = undefined;
      this.user = {
        document: this.formData.documentNumber,
        documentType: this.formData.documentType,
        represent: this.formData.represent
      }

      let res = await this.cargarDta();
      if (res.status === 200) {
        const data = res.data;
        if (data.code === 401) {
          this.showAlert();
        } else {
          this.userData = data;
          if (this.userData.proxyList) {
            this.userData.tabla = this.formatData(data.proxyList);
          }else if (this.userData.representedList){
            this.userData.tabla = this.formatData(data.representedList);
          }

          this.userData.tabla.forEach(e => {
            if (e.legalName && e.legalName != ' - ') {
              e.name = e.legalName
            } else {
              e.name = e.firstName +' '+ e.lastName
            }
          });

          if (this.userData.legalName === undefined) {
            this.formatPersonName(this.userData);
            this.userData.name = this.userData.firstName + " " + this.userData.lastName;
            if (this.userData.genderResponse) {
              switch (this.userData.genderResponse.description) {
                case "Male":
                  this.userData.gender = "Masculino";
                  break;
                case "Female":
                  this.userData.gender = "Femenino";
                  break;
                default:
                  this.userData.gender = "Otro";
                  break;
              }
            }
          }
        }
      }else{
        this.showAlert();
      }
    },
    getFormErrorsScheme() {
      return {
        documentType: '',
        documentNumber: ''
      }
    },
    showAlert() {
      this.$buefy.dialog.alert({
        title: 'Error',
        message: 'No existe el usuario',
        type: 'is-danger',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    formatData(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].legalName = data[i].legalName != null ? data[i].legalName : " - ";
        this.formatPersonName(data[i]);
      }
      return data;
    },
    formatPersonName(userData) {
      userData.firstName = userData.firstName != null ? userData.firstName : " - ";
      userData.lastName = userData.lastName != null ? " " + userData.lastName : "";
      userData.paternalSurname = userData.paternalSurname != null ? userData.paternalSurname : " - ";
      userData.maternalSurname = userData.maternalSurname != null ? " " + userData.maternalSurname : "";
      userData.firstName = userData.firstName + userData.lastName;
      userData.lastName = userData.paternalSurname + userData.maternalSurname;
    },
    exportExcel: function () {
      this.exportFile("xlsx");
    },
    exportCSV() {
        this.exportFile("csv");
    },
    exportPDF() {
        this.exportFile("pdf");
    },
    exportFile(type) {
      let tipo = this.user.documentType;
      let numero = this.user.document;
      let deleRepre = this.user.represent;

      const options = {
        method: 'get',
        url: urlBaseTiposdeDocumento+`/control_delegados/${deleRepre}/${tipo}/${numero}/export/${type}`,
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${this.token.Bearer}`
        },
      }
      axios(options)
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let fileName = `consulta_delegado_${deleRepre}_${new Date().toLocaleDateString()}.${type}`;

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.saveExportEvent(type)
        })
        .catch(error => {
            console.log('error: ', error);
        });
    },
      saveExportEvent(type) {
          saveLog({
              "user": this.getUsuario(),
              "sectionId": 1,
              "reportId": 11,
              "format": type === "xlsx" ? "excel" : type,
              "document": this.formData.documentType + this.formData.documentNumber
          })
      },
      ...mapGetters(["getUsuario"])
  }
}
</script>

<style scoped>

</style>