import axios from 'axios'
import {config} from "../assets/config/jsonconfig.json";
const URL_DOCUMENT_TYPES = config.urlBaseTiposdeDocumento;
import { getToken } from "../assets/config/getToken.js";

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

export function saveLog(logData) {
    getToken(config.DOMINIO).then(function (token) {
        if (logData.startDate) {
            logData.startDate = formatDate(logData.startDate)
        }
        if (logData.endDate) {
            logData.endDate = formatDate(logData.endDate)
        }
        const options = {
            method: 'post',
            url: `${URL_DOCUMENT_TYPES}/export/logs`,
            data: logData,
            headers: {
                'Authorization': `Bearer ${token.Bearer}`
            }
        }
        axios(options)
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                }
            })
            .catch(error => {
                console.log('error: ', error);
            });
    });
}