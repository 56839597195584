<template>
  <main-layout itemMenuActive="18" tituloMenu="Envío de Reportes">
    <br />
    <div class="container is-full">
      <div class="card">
        <div class="card-content">
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <b-button type="is-text" tag="router-link" to="/sendreportsv2">
                <i class="bx bx-left-arrow"></i>
              </b-button>
              <p class="title is-4 centered-text">Histórico de Ejecuciones</p>
            </div>
            <div>
              <form v-on:submit.prevent="submitForm"></form>
              <br />
            </div>
          </div>
        </div>
      </div>
      <TableCardSendReport :columns="tableColumns" :dataList="tableData.data" :total="tableData.totalCount" :page-change="pageChange"></TableCardSendReport>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </main-layout>
</template>
<script>
import MainLayout from "../../components/Layout/MainLayout";
import axios from "axios";
import { COLUMNS_HISTORICO_ENVIO_REPORTES } from "../../assets/config/constants/TableConstants";
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento;
import TableCardSendReport from "../../components/cards/TableCardSendReport.vue";
import { getFecha,getHour,estadoReporte } from "../../assets/config/utilities";
import { getToken } from "../../assets/config/getToken.js";

export default {
  name: "SendReports",
  components: {
    MainLayout,
    TableCardSendReport,
  },
  data() {
    return {
      num : 1,
      tableData: undefined,
      tableColumns: COLUMNS_HISTORICO_ENVIO_REPORTES,
      rangeDate: [],
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      token: null
    };
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    if (this.$route.params.reportId) {
      this.loadReportData(this.$route.params.reportId);
    }
  },
  methods: {
    loadReportData(reportId) {
      this.isLoading = true;
      this.tableData = undefined;
      //console.warn("Inside reportId: ", reportId);
      const options = {
        method: "get",
        url: urlBaseTiposdeDocumento + "/report/mail/execution/" + reportId + "?size=10&page=1",
        headers: {
          "Api-key": config["Api-Key-types"],
          'Authorization': `Bearer ${this.token.Bearer}`,
        },
      };
      axios(options)
        .then((response) => {
          var valor = this.num;
          if (response.status === 200) {
            let reportData = response.data;
            reportData.data = this.formatData(reportData.data);

            var arreglo = reportData.data;
            for (var i = 0; i < arreglo.length; i++) {
              arreglo[i].nro = valor;
              valor++;
              this.num = valor;
            }

            this.tableData = reportData;
            this.isLoading = false;
          }
        })
        .finally(() => {this.isLoading = false});
    },
    formatData(eventList) {
      for (let eventItem of eventList) {
        //console.log(eventItem.createdAt);
        eventItem.createdAt = getFecha(eventItem.createdAt);
        eventItem.executionDateTime = getHour(eventItem.executionDateTime);
        eventItem.active = estadoReporte(eventItem.active);
      }
      return eventList;
    },
    pageChange(page) {
      this.filters.page = page;
      this.loadReportData(this.$route.params.reportId);
    },
  },
};
</script>