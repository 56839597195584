export const COLUMNS_CHANGE_PASSWORD_TABLE = {
    log_id: {
        label: 'Id',
        type: 'tag',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    accion: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
        isVisible: true
    },
    estado: {
        label: 'Estado',
        type: 'status',
        isVisible: true
    }
}

export const CONFIG_FILTER_CHANGE_PASSWORD = {
    label: 'Búsqueda por Id o por estado',
    placeholder: 'Escriba Id o estado...',
    columns: ['log_id', 'resultado']
}

export const COLUMNS_CREATE_ACCOUNT_TABLE = {
    /*counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true,
        isVisible: true
    },*/
    log_id: {
        label: 'ID CIAM',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
    },
    estado: {
        label: 'Estado',
        type: 'status',
        keys: ['resultado'],
        isVisible: true
    }
}

export const CONFIG_FILTER_CREATE_ACCOUNT = {
    label: 'Búsqueda por Id o por estado',
    placeholder: 'Escriba Id o estado...',
    columns: ['log_id_creacion', 'resultado']
}

export const COLUMNS_LOGIN_TABLE = {
    log_id: {
        label: 'Id',
        type: 'tag',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    /**formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },*/
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
    },
    pais: {
        label: 'País',
        type: 'text',
        isVisible: false
    },
    estado: {
        label: 'Estado',
        type: 'status',
        isVisible: true
    }
}

export const CONFIG_FILTER_LOGIN = {
    label: 'Búsqueda por Id o por estado',
    placeholder: 'Escriba Id o estado...',
    columns: ['log_id', 'resultado']
}

export const COLUMNS_FACE_RECOGNITION_TABLE = {
    num: {
        label: 'Nro.',
        type: 'number'
    },
    transactionid: {
        label: 'ID Operación',
        type: 'tag',
        sortable: true
    },
    fecha: {
        label: 'Fecha',
        type: 'composedDate',
        keys: ['fecha', 'hora'],
        sortable: true
    },
    usuario: {
        label: 'Usuario',
        type: 'text',
        isVisible: false
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true
    },
    descripcion_respuesta: {
        label: 'Respuesta',
        type: 'composedScoreDesc',
        keys: ['confidenceconfigured', 'confidence']
    },
    respuesta: {
        label: 'País',
        type: 'composedScoreAnswer',
        keys: ['confidenceconfigured', 'confidence'],
        isVisible: false
    }
}

export const CONFIG_FILTER_FACE_RECOGNITION = {
    label: 'Búsqueda por Id o por respuesta',
    placeholder: 'Escriba Id o respuesta...',
    columns: ['transactionid', 'descripcion_respuesta']
}

export const COLUMNS_ONBOARDING_TABLE = {
    num: {
        label: 'Nro.',
        type: 'number'
    },
    operationGuid: {
        label: 'ID Operación',
        type: 'tag',
        sortable: true
    },
    createdAt: {
        label: 'Fecha',
        type: 'date',
        sortable: true
    },
    names: {
        label: 'Nombre y apellido',
        type: 'composedText',
        keys: ['names', 'lastNames']
    },
    lastEvent: {
        label: 'Última operación',
        type: 'text',
    },
    status: {
        label: 'Estado',
        type: 'composedState',
        keys: ['scoreConfigured', 'scoreTotal']
    }
}

export const COLUMNS_ONBOARDING_TABLE_FINAN = {
    num: {
        label: 'Nro.',
        type: 'number'
    },
    id: {
        label: 'ID Operación',
        type: 'tag',
        sortable: true
    },
    fecha_hora: {
        label: 'Fecha',
        type: 'date',
        sortable: true
    },
    cliente: {
        label: 'Nombre y apellido',
        type: 'text',
    },
    lastEvent: {
        label: 'Última operación',
        type: 'text',
    },
    estado: {
        label: 'Estado',
        type: 'text',
    }
}

export const CONFIG_FILTER_ONBOARDING_FINAN = {
    label: 'Búsqueda por Id o por estado',
    placeholder: 'Escriba Id o estado...',
    columns: ['id', 'estado']
}

export const CONFIG_FILTER_ONBOARDING = {
    label: 'Búsqueda por Id o por estado',
    placeholder: 'Escriba Id o estado...',
    columns: ['operationGuid', 'status']
}

export const COLUMNS_SECURE_ONBOARDING_TABLE = {
    id: {
        label: 'ID',
        type: 'number',
        sortable: true
    },
    fecha: {
        label: 'Fecha y hora',
        type: 'composedDate',
        keys: ['fecha', 'hora'],
        sortable: true
    },
    score: {
        label: 'Score Biometria',
        type: 'percentage'
    },
    scoredocument: {
        label: 'Score Documento',
        type: 'percentage'
    },
    scoretotal: {
        label: 'Score Total',
        type: 'percentage'
    },
    scoreconfigured: {
        label: 'Score configurado',
        type: 'percentage'
    },
    autorizado: {
        label: 'Autorizado',
        type: 'composedScoreAnswer',
        keys: ['autorizado']
    },
    tipoDoc: {
        label: 'Tipo documento',
        type: 'text',
        isVisible: false
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
    },
    userId: {
        label: 'Identificación',
        type: 'text',
        sortable: true
    },
    cliente: {
        label: 'Cliente',
        type: 'text'
    },
    lastEvent: {
        label: 'Ultima operación',
        type: 'alertError',
        keys: ['lastOperationCode', 'responseLastEvent']
    },
    estado: {
        label: 'Estado',
        type: 'text'
    }
}
export const COLUMNS_LOGS_ENABLED = {
    "Nro": {
        label: 'Nro.',
        type: 'text',
        sortable: true
    },
    "Fecha y hora": {
        label: 'Fecha y hora',
        type: 'text',
        sortable: true
    },
    "Usuario": {
        label: 'Usuario',
        type: 'text',
        sortable: true
    },
    "Tipo de transacción": {
        label: 'Tipo de transacción',
        type: 'text',
        sortable: true
    },
    "Funcionalidad": {
        label: 'Funcionalidad',
        type: 'text',
        sortable: true
    },
    "Tipo de usuario": {
        label: 'Tipo de usuario',
        type: 'text',
        sortable: true
    },
    "Tipo de Documento": {
        label: 'Tipo de Documento',
        type: 'text',
        sortable: true
    },
    
}
export const COLUMNS_EVIDENTE_TEMPS = {
    "Nro": {
        label: 'Nro.',
        type: 'text',
        sortable: true
    },
    "Tipo de Documento": {
        label: 'Tipo de Documento',
        type: 'text',
        sortable: true
    },
    "Número de Documento": {
        label: 'Número de Documento',
        type: 'text',
        sortable: true
    },
    "Tipo Fallo": {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true
    },
    "Motivo Fallo": {
        label: 'Motivo Fallo',
        type: 'text',
        sortable: true
    },
    "Fecha y Hora del consumo": {
        label: 'Fecha y Hora del consumo',
        type: 'text',
        sortable: true
    }
}
export const COLUMNS_EVIDENTE_BLOCKS = {
    "Nro": {
        label: 'Nro.',
        type: 'text',
        sortable: true
    },
    "Tipo de Documento": {
        label: 'Tipo de Documento',
        type: 'text',
        sortable: true
    },
    "Número de Documento": {
        label: 'Número de Documento',
        type: 'text',
        sortable: true
    },
    "Tipo Fallo": {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true
    },
    "Fecha y Hora Bloqueo": {
        label: 'Fecha y Hora Bloqueo',
        type: 'text',
        sortable: true
    },
    "Tiempo de Bloqueo": {
        label: 'Tiempo de Bloqueo',
        type: 'text',
        sortable: true
    }
}
export const COLUMNS_REPORTES_PARAMETRIZACION = {
    "Nro": {
        label: 'Nro.',
        type: 'text',
        sortable: true
    },
    "Fecha y hora": {
        label: 'Fecha y hora',
        type: 'text',
        sortable: true
    },
    "Usuario": {
        label: 'Usuario',
        type: 'text',
        sortable: true
    },
    "Tipo de Documento": {
        label: 'Tipo de Documento',
        type: 'text',
        sortable: true
    },
    "Accion": {
        label: 'Acción',
        type: 'text',
        sortable: true
    },
    "Funcionalidad": {
        label: 'Funcionalidad',
        type: 'text',
        sortable: true
    },
    "Valor Anterior": {
        label: 'Valor Anterior',
        type: 'text',
        sortable: true
    },
    "Valor Nuevo": {
        label: 'Valor Nuevo',
        type: 'text',
        sortable: true
    }
}
export const COLUMNS_PARAMETRIZACION_HABILITACION = {
    "id-compensar": {
      label: '',
      type: 'text',
      sortable: true
    },
    "code": {
      label: 'Tipo de documento',
      type: 'text',
      sortable: true
    },
    "Title": {
      label: 'Texto',
      type: 'label',
      sortable: true
    },
    "max": {
      label: 'Longitudes',
      type: 'detalles',
      sortable: true
    },
    "AlfaNumerico": {
      label: 'Tipo de campo',
      type: 'switched',
      sortable: true,
      disabled: false
    },
    "Dashboard": {
        label: 'Acción',
        type: 'accion',
        sortable: true
    }
  };

export const COLUMNS_USER_REGISTER_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    log_id: {
        label: 'ID CIAM',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    /**accion: {
        label: 'Service Provider',
        type: 'text',
    },*/
    portal: {
        label: 'Portal/App',
        type: 'text',
    },
    estado: {
        label: 'Estado',
        type: 'status',
        keys: ['resultado'],
        isVisible: true
    }
}

export const COLUMNS_USER_MODIFY_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    log_id: {
        label: 'ID CIAM',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
        isVisible: true
    },
    /**pais: {
        label: 'País',
        type: 'text',
        isVisible: true
    },*/
    estado: {
        label: 'Estado',
        type: 'status',
        keys: ['resultado'],
        isVisible: true
    },
}

export const COLUMNS_ONBOARDING_EVENT_TYPES_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true
    },
    cedula: {
        label: 'Cédula',
        type: 'tag',
        alternMessage: 'No registra cédula',
        sortable: true
    },
    documentBack: {
        label: 'Dorso de documento',
        type: 'text',
        sortable: true
    },
    documentFront: {
        label: 'Frente de documento',
        type: 'text',
        sortable: true
    },
    documentImage: {
        label: 'Imagen del documento',
        type: 'text',
        sortable: true
    },
    documentFrontFlash: {
        label: 'Operación cancelada',
        type: 'text',
        sortable: true
    },
    endOperation: {
        label: 'Operación finalizada',
        type: 'text',
        sortable: true
    }
}

export const COLUMNS_FACE_RECOGNITION_PAGE_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number'
    },
    fecha: {
        label: 'Fecha y hora',
        type: 'composedDate',
        keys: ['fecha', 'hora'],
        sortable: true
    },
    confidence: {
        label: 'Confidence',
        type: 'percentage'
    },
    confidenceconfigured: {
        label: 'Confidence configurado',
        type: 'percentage'
    },
    transactionid: {
        label: 'ID Operación',
        type: 'tag',
        sortable: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true
    },
    codigo_respuesta: {
        label: 'Cod. Descripción',
        type: 'text'
    },
    descripcion_respuesta: {
        label: 'Respuesta',
        type: 'composedScoreDesc',
        keys: ['confidenceconfigured', 'confidence']
    }
}

export const COLUMNS_LOGIN_PAGE_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    log_id: {
        label: 'ID CIAM',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    /**formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },*/
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    /*mensaje: {
        label: 'Mensaje',
        type: 'message',
        messageType: 'login',
        isVisible: true
    },*/
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
        isVisible: true
    },
    pais: {
        label: 'País',
        type: 'text',
        isVisible: true
    },
    estado: {
        label: 'Estado',
        type: 'status',
        keys: ['resultado'],
        isVisible: true
    },
}

export const COLUMNS_RECUPERAR_PAGE_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    log_id: {
        label: 'ID CIAM',
        type: 'number',
        sortable: true,
        isVisible: true
    },
    fechaHora: {
        label: 'Fecha',
        type: 'date',
        sortable: true,
        isVisible: true
    },
    userIdType: {
        label: 'Tipo Identificación',
        type: 'text',
        isVisible: true
    },
    userId: {
        label: '#Identificación',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    formulario: {
        label: 'Formulario',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    servicio: {
        label: 'Servicio',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    tipo_error: {
        label: 'Tipo Fallo',
        type: 'text',
        sortable: true,
        isVisible: true
    },
    resultado: {
        label: 'Resultado',
        type: 'text',
        isVisible: true
    },
    mensaje: {
        label: 'Mensaje',
        type: 'text',
        isVisible: true
    },
    service_provider: {
        label: 'Service Provider',
        type: 'text',
        isVisible: true
    },
    portal: {
        label: 'Portal/App',
        type: 'text',
        isVisible: true
    },
    /**pais: {
        label: 'País',
        type: 'text',
        isVisible: true
    },*/
    estado: {
        label: 'Estado',
        type: 'status',
        keys: ['resultado'],
        isVisible: true
    },
}

export const COLUMNS_AUTOMATIC_REPORTS_TABLE_v2 = {
    "Nro": {
        label: 'ID',
        type: 'number',
        sortable: true
    },
    "Periodo ejecución": {
        label: 'Ejecución',
        type: 'text'
    },
    "Fecha Inicio": {
        label: 'Fecha inicio',
        type: 'date',
        sortable: true
    },
    // endDate: {
    //     label: 'Fecha fin',
    //     type: 'date',
    //     sortable: true
    // },
    "Correos": {
        label: 'Emails',
        type: 'text',
        sortable: true
    },
    "Reporte": {
        label: 'Reporte',
        type: 'text'
    },
    "Formato": {
        label: 'Formato',
        type: 'text'
    },
    "Fecha creación": {
        label: 'Fecha creación',
        type: 'date',
        sortable: true
    },
    "Activo": {
        label: 'Estado',
        type: 'text'
    },
    "_id": {
        label: 'Acciones',
        type: 'buttonGroup',
        data: [
            {
                key: '_id',
                properties: 'is-info',
                action: 'editar_nuevo_reporte',
                icon: 'bx bx-show'
            },
            {
                key: '_id',
                properties: 'is-danger',
                action: 'delete',
                icon: 'bx bx-trash'
            },
            {
                key: '_id',
                properties: 'is-warning',
                action: 'historico',
                icon: 'bx bx-rotate-left'
            }
        ]
    }
}
export const COLUMNS_AUTOMATIC_REPORTS_TABLE = {
    id: {
        label: 'ID',
        type: 'number',
        sortable: true
    },
    typeTimeLapse: {
        label: 'Ejecución',
        type: 'text'
    },
    startDate: {
        label: 'Fecha inicio',
        type: 'date',
        sortable: true
    },
    // endDate: {
    //     label: 'Fecha fin',
    //     type: 'date',
    //     sortable: true
    // },
    emails: {
        label: 'Emails',
        type: 'text',
        sortable: true
    },
    reportType: {
        label: 'Reporte',
        type: 'text'
    },
    exportType: {
        label: 'Formato',
        type: 'text'
    },
    createdAt: {
        label: 'Fecha creación',
        type: 'date',
        sortable: true
    },
    isActive: {
        label: 'Estado',
        type: 'text'
    },
    actions: {
        label: 'Acciones',
        type: 'buttonGroup',
        data: [
            {
                key: 'id',
                properties: 'is-info',
                action: 'edit',
                icon: 'bx bx-show'
            },
            {
                key: 'id',
                properties: 'is-danger',
                action: 'delete',
                icon: 'bx bx-trash'
            }
        ]
    }
}


export const COLUMNS_USERS_TABLE = {
    firstName: {
        label: 'Nombre',
        type: 'text',
        sortable: true
    },
    lastName: {
        label: 'Apellido',
        type: 'text',
        sortable: true
    },
    email: {
        label: 'Correo',
        type: 'text'
    },
    profile: {
        label: 'Perfil',
        type: 'text'
    },
    actions: {
        label: 'Acciones',
        type: 'buttonGroup',
        data: [
            {
                key: 'id',
                properties: 'is-info',
                action: 'edit',
                icon: 'bx bx-show'
            },
            {
                key: 'id',
                properties: 'is-danger',
                action: 'delete',
                icon: 'bx bx-trash'
            }
        ]
    }
}

export const COLUMNS_DAILY_ONBOARDING_TABLE = {
    counter: {
        label: 'Nro.',
        type: 'text',
        sortable: true
    },
    id: {
        label: 'ID',
        type: 'text',
        sortable: true
    },
    fecha: {
        label: 'Fecha y hora',
        type: 'composedDate',
        keys: ['fecha', 'hora'],
        sortable: true
    },
    score: {
        label: 'Score Biometria',
        type: 'percentage',
        sortable: true
    },
    scoredocument: {
        label: 'Score Documento',
        type: 'percentage',
        sortable: true
    },
    scoretotal: {
        label: 'Score Total',
        type: 'percentage',
        sortable: true
    },
    scoreconfigured: {
        label: 'Score configurado',
        type: 'percentage',
        sortable: true
    },
    autorizado: {
        label: 'Autorizado',
        type: 'composedScoreDesc',
        keys: ['scoreconfigured', 'scoretotal']
    },
    tipoDoc: {
        label: 'Tipoo',
        type: 'text',
        isVisible: false
    },
    userIdType: {
        label: 'Tipo documento',
        type: 'text'
    },
    userId: {
        label: 'Identificación',
        type: 'text',
        sortable: true
    },
    cliente: {
        label: 'Cliente',
        type: 'text'
    },
    lastEvent: {
        label: 'Ultima operación',
        type: 'alertError',
        keys: ['lastOperationCode', 'responseLastEvent']
    },
    estado: {
        label: 'Estado',
        type: 'text'
    }
}

export const COLUMNS_PROFILE_TABLE = {
    name: {
        label: 'Nombre',
        type: 'text',
        sortable: true
    },
    roleList: {
        label: 'Roles',
        type: 'text',
        sortable: true
    },
    actions: {
        label: 'Acciones',
        type: 'buttonGroup',
        data: [
            {
                key: 'id',
                properties: 'is-info',
                action: 'edit',
                icon: 'bx bx-show'
            },
            {
                key: 'id',
                properties: 'is-danger',
                action: 'delete',
                icon: 'bx bx-trash'
            }
        ]
    }
}

export const COLUMNS_PROXY_EVENTS_TABLE = {
    created: {
        label: 'Fecha',
        type: 'date',
        sortable: true
    },
    proxydoctype: {
        label: 'Tipo Doc. Representante',
        type: 'text',
        sortable: true
    },
    proxydocnum: {
        label: '# Doc. Representante',
        type: 'text',
        sortable: true
    },
    doctype: {
        label: 'Tipo Doc. Representado',
        type: 'text',
        sortable: true
    },
    docnum: {
        label: '# Doc. Representado',
        type: 'text',
        sortable: true
    },
    eventype: {
        label: 'Tipo de Evento',
        type: 'text',
        sortable: true
    },
    newproxydoctype: {
        label: 'Tipo Doc. Representante Modificado',
        type: 'text',
        sortable: true
    },
    newproxydocnum: {
        label: '# Doc. Representante Modificado',
        type: 'text',
        sortable: true
    },
    responsetype: {
        label: 'Respuesta',
        type: 'text'
    },
    responsestatus: {
        label: 'Estado',
        type: 'text',
        sortable: true
    },
    // error_description: {
    //     label: 'Error',
    //     type: 'text',
    //     sortable: true
    // },
}

export const COLUMNS_PROXY_QUERY_TABLE = {
    ciamId: {
        label: 'CIAM ID',
        type: 'text',
    },
    documentType: {
        label: 'Tipo Documento',
        type: 'text',
        sortable: true
    },
    documentNumber: {
        label: 'N° Documento',
        type: 'text',
        sortable: true
    },
    name: {
        label: 'Nombres / Razón Social',
        type: 'text'
    },
    created: {
        label: 'Fecha Creación',
        type: 'text',
    },
    edited: {
        label: 'Fecha Edición',
        type: 'text',
    },
    /*legalName: {
        label: 'Razón Social',
        type: 'text'
    },*/
    enabled: {
        label: 'Estado',
        type: 'statusBool'
    },
}

export const COLUMNS_CONSOLIDADO = {
    nombre: {
        label: 'Acción',
        type: 'text',
    },
    cantidad: {
        label: 'Cantidad',
        type: 'text',
    }
}

export const COLUMNS_TEXT_PARAMETRIZATION_EVENTS = [
   {
       field: 'id',
       label: 'Nro',
       centered: true
    },
    {
        field: 'createdAt',
        label: 'Fecha y hora',
        centered: true
    },
    {
        field: 'user',
        label: 'Usuario',
        centered: true
    },
    {
        field: 'process',
        label: 'Proceso',
        centered: true
    },
    {
        field: 'form',
        label: 'Formulario',
        centered: true
    },
    {
        field: 'field',
        label: 'Campo',
        centered: true
    },
    {
        field: 'subFieldStr',
        label: 'Subcampo',
        centered: true
    },
    {
        field: 'lastValue',
        label: 'Valor Anterior',
        centered: true
    },
    {
        field: 'newValue',
        label: 'Valor Nuevo',
        centered: true
    }
]

export const COLUMNS_EXPORT_LOGS_TABLE = [
    {
        field: 'sequenceId',
        label: 'Nro',
        centered: true
    },
    {
        field: 'createdAt',
        label: 'Fecha y hora',
        centered: true
    },
    {
        field: 'user',
        label: 'Usuario',
        centered: true
    },
    {
        field: 'section',
        label: 'Sección',
        centered: true
    },
    {
        field: 'report',
        label: 'Reporte',
        centered: true
    },
    {
        field: 'subReport',
        label: 'Sub Reporte',
        centered: true
    },
    {
        field: 'startDate',
        label: 'Filtro Fecha Inicio',
        centered: true
    },
    {
        field: 'endDate',
        label: 'Filtro Fecha Fin',
        centered: true
    },
    {
        field: 'document',
        label: 'Filtro Tipo y Num Doc',
        centered: true
    },
    {
        field: 'format',
        label: 'Formato',
        centered: true
    }
]

export const COLUMNS_SEND_REPORT_TABLE = [
    {
        field: 'nro',
        label: 'Nro',
        centered: true
    },
    {
        field: 'createdAt',
        label: 'Fecha y hora',
        centered: true
    },
    {
        field: 'user',
        label: 'Usuario',
        centered: true
    },
    {
        field: 'id',
        label: 'ID',
        centered: true,
        isVisible: false,
        type: 'tag',
    },
    {
        field: 'frequency',
        label: 'Periodo Ejecución',
        centered: true
    },
    {
        field: 'report',
        label: 'Reporte',
        centered: true
    },
    {
        field: 'startDate',
        label: 'Fecha Inicio',
        centered: true,
    },
    {
        field:'executionHour',
        label:'Hora Ejecución',
        centered:true
    },
    {
        field: 'emails',
        label: 'Correos',
        centered: true
    },
    {
        field: 'format',
        label: 'Formato',
        centered: true
    },
    {
        field:'active',
        label: 'Estado',
        centered: true
    },
    {
        field:'action',
        label: 'Acción',
        centered: true
    },
]
export const COLUMNS_SEND_REPORT_TABLE_DETAILS = [
    {
        field: 'field',
        label: 'Campo',
        centered: true
    },
    {
        field: 'lastValue',
        label: 'Valor Anterior',
    },
    {
        field: 'newValue',
        label: 'Valor Nuevo',
    },
]
export const COLUMNS_HISTORICO_ENVIO_REPORTES =[
    {
        field: 'nro',
        label: 'ID',
        centered: true
    },
    {
        field: 'state',
        label: 'Estado',
        centered: true
    },
    {
        field: 'createdAt',
        label: 'Fecha Ejecución',
    },
    {
        field: 'executionDateTime',
        label: 'Hora Ejecución',
    },
    {
        field: 'duration',
        label: 'Duración'
    },
    { 
        field:'response.count',
        label:'Cantidad Registros'
    },
    {
        field: 'response.Success',
        label: 'Mensaje'
    }
]
export const CONFIG_FILTER_REGISTRADURIA = {
    label: 'Búsqueda por nro o por estado',
    placeholder: 'Escriba nro o estado...',
    columns: ['id', 'estado']
}
export const COLUMNS_REGISTRADURIA ={
    id: {
        label: 'Nro',
        type: 'text',
        sortable: true
    },
    fecha: {
        label: 'Fecha',
        type: 'date',
        sortable: true
    },
    tiposolicitud: {
        label: 'Vigencia Documento',
        type: 'text',
        sortable: true
    },
    tipodocumento: {
        label: 'Tipo Documento',
        type: 'text',
        sortable: true
    },
    identificacioncliente: {
        label: 'Identificación',
        centered: true
    },
    transactionid: {
        label: 'Ciudad expedición',
        centered: true
    },
    fechaexpedicion: {
        label: 'Fecha expedición',
        type: 'date',
        centered: true
    },
    nombrecompletocliente:{
        label: 'Cliente',
        type: 'text'
    },
    nacionalidad:{
        label: 'Nacionalidad',
        type: 'text'
    },
    estado:{
        label:'Estado',
        type: 'statusRegistraduria',
        sortable: true
    }
    
}

export const COLUMNS_ICONS_LOGS_TABLE = [
    {
        field: 'id',
        label: 'Nro',
        centered: true
    },
    {
        field: 'createdAt',
        label: 'Fecha y hora',
        centered: true
    },
    {
        field: 'user',
        label: 'Usuario',
        centered: true
    },
    {
        field: 'process',
        label: 'Proceso',
        centered: true
    },
    {
        field: 'iconName',
        label: 'Icono',
        centered: true
    },
    {
        field: 'lastValue',
        label: 'Valor Anterior',
        centered: true
    },
    {
        field: 'newValue',
        label: 'Valor Nuevo',
        centered: true
    },
]



export const COLUMNS_SYNTETHICUSERS = {
    'id':{
        label: 'Nro',
        sortable: true
    },
    'functionality': {
        label: 'Funcionalidad',
        sortable: true
    },
    'username': {
        label: 'Usuario sistético',
        type: 'labelUsuarios',
        sortable: true
    },
    'state': {
        label: 'Estado',
        sortable: true,
        type: 'switchedUsuarios',
        disabled: false
    },
    'phone': {
        label: 'Telefono móvil',
        sortable: true
    },
    'mail': {
        label: 'Correo',
        sortable: true
    },
    "Dashboard": {
        label: 'Acción',
        type: 'accionUsuarios',
        sortable: true
    }
}