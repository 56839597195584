<template ref="Home">
  <main>
    <main-layout itemMenuActive="0" tituloMenu="Dashboard">
      <div class="home-container">
        <img src="../assets/Infomedia-logo.png" width="150" alt=""/>
      </div>
    </main-layout>
  </main>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";

export default ({
  name: "Home",
  components: {
    MainLayout
  }
})
</script>


<style scoped>

.home-container {
  height: 800px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

</style>