<template>
  <main-layout :itemMenuActive="35" tituloMenu="Habilitación tipos de documento">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <p class="title is-4 centered-text">Habilitación tipos de documento</p>
          <br>
          <div class="columns">
            <div class="column">
              <b-field horizontal label="Tipo de Usuario">
                <b-select placeholder="Seleccione una opción" v-model="processSelected" expanded  class="select-field">
                  <option>Persona</option>
                  <option>Empresa</option>
                </b-select>
              </b-field>
            </div>
            <div></div>
          </div>
          <div style="margin: 1cm;">
          <div v-if="processSelected === 'Empresa'">
            <div class="columns">
              <div class="column has-background-grey-lighter">
                <div class="field">
                  <label class="label">Autenticación</label>
                  <div class="control">
                    <div v-if="isLoading">
                      Cargando tipos de documento...
                    </div>
                    <div v-else>
                      <div v-for="documentType in documentTypes" :key="documentType._id">
                        <div class="field">
                          <b-form-checkbox v-model="selectedDocuments">
                            <input type="checkbox" :id="documentType['id-compensar']" :checked="documentType.empresa['Autenticacion']" :value="'Autenticacion'"  @change="updateAdministracionAutenticacion(documentType, $event.target.checked)"
                              :disabled="isDisabled()">
                          {{ documentType.Label }}
                        </b-form-checkbox>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                  <div class="field">
                    <label class="label">Recuperación</label>
                    <div class="control">
                      <div v-if="isLoading">
                        Cargando tipos de documento...
                      </div>
                      <div v-else>
                        <div v-for="documentType in documentTypes" :key="documentType._id">
                          <div class="field">
                            <b-form-checkbox v-model="selectedDocuments">
                              <input type="checkbox" :id="documentType['id-compensar']" :checked="documentType.empresa['Recuperacion']" :value="'Recuperacion'"   @change="updateAdministracionRecuperacion(documentType, $event.target.checked)"
                                :disabled="isDisabled()">
                              {{ documentType.Label }}
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="column has-background-grey-lighter">
                <div class="field">
                  <label class="label">Creación</label>
                  <div class="control">
                    <div v-if="isLoading">
                      Cargando tipos de documento...
                    </div>
                    <div v-else>
                      <div v-for="documentType in documentTypes" :key="documentType._id">
                        <div class="field">
                          <b-form-checkbox v-model="selectedDocuments">
                            <input type="checkbox" :id="documentType['id-compensar']" :checked="documentType.empresa['Creacion']" :value="'Creacion'"  @change="updateAdministracionCreacion(documentType, $event.target.checked)"
                              :disabled="isDisabled()">
                          {{ documentType.Label }}
                        </b-form-checkbox>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="label">Administración de Delegados</label>
                  <div class="control">
                    <div v-if="isLoading">
                      Cargando tipos de documento...
                    </div>
                    <div v-else>
                      <div v-for="documentType in documentTypes" :key="documentType._id">
                        <div class="field">
                        <b-form-checkbox v-model="selectedDocuments">
                          <input type="checkbox" :id="documentType['id-compensar']" :checked="documentType.empresa['Administracion de Delegados']" :value="'Administracion de Delegados'"
                          :disabled="
                          documentType['id-compensar'] === 1 ||
                          documentType['id-compensar'] === 2 ||
                          documentType['id-compensar'] === 3 ||
                          documentType['id-compensar'] === 4 ||
                          documentType['id-compensar'] === 5 ||
                          documentType['id-compensar'] === 6 ||
                          documentType['id-compensar'] === 7 ||
                          documentType['id-compensar'] === 8 ||
                          documentType['id-compensar'] === 9 ||
                          documentType['id-compensar'] === 10 ||
                          documentType['id-compensar'] === 11 ||
                          documentType['id-compensar'] === 12 ||
                          documentType['id-compensar'] === 13 ||
                          documentType['id-compensar'] === 14 ||
                          documentType['id-compensar'] === 15 ||
                          isDisabled()"

                          @change="updateAdministracionDelegados(documentType, $event.target.checked)">
                          {{ documentType.Label }}
                        </b-form-checkbox>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column has-background-grey-lighter" v-if="selectedOptionAdministracion === true">
                <div class="field">
                  <label class="label">Representante Legal</label>
                  <div class="control">
                    <div v-if="isLoading">
                      Cargando tipos de documento...
                    </div>
                    <div v-else>
                      <div v-for="documentType in documentTypes" :key="documentType._id">
                        <div class="field">
                          <b-form-checkbox v-model="selectedDocuments">
                            <input type="checkbox" :id="documentType['id-compensar']" :checked="documentType.empresa['Representante Legal']" :value="'Representante Legal'"  :disabled="isDisabledCompany(documentType.code)" @change="updateRepresentanteLegal(documentType, $event.target.checked)">
                          {{ documentType.Label }}
                        </b-form-checkbox>
                       </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><br>
            <div v-if="isEditable()" class="field is-grouped  is-pulled-right">
              <div class="control">
                <button class="button is-danger" @click="descartar">Descartar</button>
              </div>
              <div>
                <button class="button is-primary" @click="mostrarAlerta" :disabled="botonDeshabilitado">Guardar</button>
              </div>
            </div>
            <div>
              <div class="overlay" v-show="showAlert"></div>
              <div class="alert" v-show="showAlert">
                <h2>¿Estás seguro de realizar los cambios?</h2>
                <div class="alert-buttons">
                  <button class="button is-danger" @click="descartar">Descartar</button>
                  <button class="button is-primary" @click="guardar()">Confirmar</button>
                </div>
              </div>
            </div>
          </div></div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import axios from "axios";
import { setLocale } from "yup";
import Swal from 'sweetalert2'
import {config} from "../../assets/config/jsonconfig.json";
import {mapGetters} from "vuex";
import { getToken } from "../../assets/config/getToken.js";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento

export default {
  name: "Parametrization",
  components: {
    MainLayout
  },
  data() {
    return {
      botonDeshabilitado: true,
      selectedOptionAdministracion:true,
      showAlert: false,
      guardarHabilitado: false,
      processSelected: 'Empresa',
      isLoading: false,
      documentTypes: [], // nuevo campo de datos para los tipos de documento
      selectedDocuments: [], // campo de datos para almacenar los tipos de documento seleccionados
      roleList: this.getRoleIdList(),
      token: null
    }
  },
  watch: {
    processSelected(newVal) {
      if (newVal === 'Persona') {
        this.$router.push('/habilitacionTDPersona');
      }
      if (newVal === 'Empresa') {
        this.$router.push('/habilitacionTDEmpresa');
      }
      else {
        this.fetchDocumentTypes();
      }
      },
      selectedDocuments(newSelectedDocuments) {
        this.guardarHabilitado = newSelectedDocuments.length > 0 || this.checkOriginalCheckboxes();
      }
  },

  computed: {

  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.fetchDocumentTypes()
  },
  methods: {
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.roleList.includes(60)
    },
    isDisabled() {
      return !this.isEditable()
    },
    isDisabledCompany(documentType) {
      return this.isDisabled() || documentType === 'NI'
    },
    async mostrarAlerta() {
      const result = await Swal.fire({
        title: '¿Estás seguro de realizar los cambios?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Descartar'
      })
      if (result.isConfirmed) {
        this.guardar()
      } else {
        this.descartar()
      }
    },

    updateAdministracionAutenticacion(documentType, isChecked) {
      documentType.empresa['Autenticacion'] = isChecked;
      this.botonDeshabilitado = false;
    },
    updateAdministracionRecuperacion(documentType, isChecked) {
      documentType.empresa['Recuperacion'] = isChecked;
      this.botonDeshabilitado = false;
    },
    updateAdministracionCreacion(documentType, isChecked) {
      documentType.empresa['Creacion'] = isChecked;
      this.botonDeshabilitado = false;

      if (this.documentTypes[1].empresa.Creacion == true) {
        this.selectedOptionAdministracion = true
      } else {
        this.selectedOptionAdministracion = false
      }
    },
    updateAdministracionDelegados(documentType, isChecked) {
      documentType.empresa['Administracion de Delegados'] = isChecked;
      this.botonDeshabilitado = false;
    },
    updateRepresentanteLegal(documentType, isChecked) {
      documentType.empresa['Representante Legal'] = isChecked;
      this.botonDeshabilitado = false;
    },
    updateDocumentType(id, isChecked) {
      this.documentTypes[id].empresa['Administracion de Delegados'] = isChecked;
      return this.documentType
    }

  ,

    checkOriginalCheckboxes() {
    let check = false;
    this.documentTypes.forEach((documentType) => {
      if (documentType.empresa.Autenticacion || documentType.empresa.Recuperacion || documentType.empresa.Creacion || documentType.empresa['Administracion de Delegados']) {
        check = true;
      }
    });
    return check;
  },
  descartar() {
    this.fetchDocumentTypes();
  },
    async guardar() {

      if (this.documentTypes[1].empresa.Creacion == false) {
        this.documentTypes.forEach((DocumentType) => {
          DocumentType.empresa['Representante Legal'] = false
        }
        )
      }
      const Api_Types = config["Api-Key-types"]
      this.isLoading = true;
      const datacomplex = [];
      this.documentTypes.forEach((DocumentType) => {
        const params = {
          "Sistema": `${this.$store.state.usuario}`,
          "id-compensar": DocumentType['id-compensar'],
          "empresa": {
            "Administracion de Delegados": DocumentType.empresa['Administracion de Delegados'],
            "Autenticacion": DocumentType.empresa['Autenticacion'],
            "Creacion": DocumentType.empresa['Creacion'],
            "Recuperacion": DocumentType.empresa['Recuperacion'],
            "Representante Legal": DocumentType.empresa['Representante Legal'],
          }
        };
        datacomplex.push(params);
      });

      try {
        const response = await axios.put(urlBaseTiposdeDocumento + "/enabled_types_many_dash_empresa", datacomplex,
        {
          headers: {
            'Api-Key': Api_Types,
            'Authorization': `Bearer ${this.token.Bearer}`
          }
        }
        );
        this.documentTypes = response.data;
        await Swal.fire({
          title: response.data['Status'],
          icon: 'success',
          confirmButtonText: 'OK'
        })

      } catch (error) {
        console.error(error);
  }

      this.fetchDocumentTypes();
},

    async fetchDocumentTypes() {
      this.isLoading = true;
      try {
        const response = await axios.get(urlBaseTiposdeDocumento+"/tiposdedocumento", {
          headers: {
              'Authorization': `Bearer ${this.token.Bearer}`
          },
        });
        this.documentTypes = response.data;
        if (this.documentTypes[1].empresa.Creacion == true) {
        this.selectedOptionAdministracion = true
      } else {
        this.selectedOptionAdministracion = false
      }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    }
  }
}
</script>
<style scoped>

p.centered-text {
  margin-top: auto;
}
.select-field{
  max-width: 300px; /* Ajusta el ancho máximo según sea necesario */
}
</style>