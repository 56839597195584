<template>
    <main-layout :itemMenuActive="0" tituloMenu="Configuración lista de exclusión">
      <br>
      <div class="container">
        <div class="card">
            <div class="card-content">
                <b-field grouped>
                  <div class="uk-navbar-right">
                    <b-field v-if="isDisable()" label="" class="column is-6">
                        <b-button type="is-primary" @click="isAddActive = true">Agregar</b-button>
                    </b-field>
                  </div>
                </b-field>
            </div>
            <div class="columns">
            <div class="column">
  <div class="card-content">
    <b-table :paginated="isPaginated" :per-page="perPage" :pagination-rounded="isPaginationRounded" :data="dataTable" :narrowed="true" :isBordered="true" :total="dataTable.length" default-sort="id" default-sort-direction="asc">
      <b-table-column field="id" label="Nro" width="40" numeric centered v-slot="props">
        {{ props.row.id }}
      </b-table-column>
      <b-table-column field="functionality" label="Funcionalidad" v-slot="props">
        {{ props.row.functionality }}
      </b-table-column>
      <b-table-column field="username" label="Usuario sintetico" v-slot="props">
        {{ props.row.username }}
      </b-table-column>
      <b-table-column field="origin" label="Origen" v-slot="props">
        {{ props.row.origin }}
      </b-table-column>
      <b-table-column field="state" label="Estado" v-slot="props">
        <div v-if="edicion === false && idseleccionado === `${props.row.id}`">
          <VueToggles :height="27" :width="115" checkedText="Habilitado" uncheckedText="Inhabilitado" checkedBg="#13b52d" uncheckedBg="#dc3545" v-model="state" :reverse="true" @click="cambioswitch(`${props.row.id}`)"/>
        </div>
        <div v-else :id="`${props.row.id}`" class="">
          <button class="btn btn-outline-secondary  border-0" @click="cambioswitch(`${props.row.id}`)" >
            <VueToggles :height="27" :width="115" ref="toggle" checkedText="Habilitado" uncheckedText="Inhabilitado" checkedBg="#13b52d" uncheckedBg="#dc3545" v-model="props.row.state" :reverse="true" :disabled="true"/>
          </button>
        </div>
      </b-table-column>
      <b-table-column field="phone" label="Telefono móvil" v-slot="props">
        {{ props.row.phone }}
      </b-table-column>
      <b-table-column field="mail" label="Correo" v-slot="props">
        {{ props.row.mail }}
      </b-table-column>
      <b-table-column field="Dashboard" label="Acción" v-slot="props" v-if="isDisable()">
        <div :id="props.row.id">
          <div v-if="edicion === false  && idseleccionado === `${props.row.id}`">
            <button class="btn btn-outline-secondary  border-0" @click=confirmarCambios>
                <i style="font-size: 30px; color: #666" class="bx bx-check-circle"></i>
            </button>
            <button class="btn btn-outline-secondary  border-0"
                @click="editar(`${props.row.id}`)">
                <i style="font-size: 30px; color: #666" class="bx bx-x-circle"></i>
            </button>
          </div>
          <div v-else :id="`${props.row.id}`">
            <button class="btn btn-outline-secondary  border-0"
                @click="editar(`${props.row.id}`, `${props.row.state}`)">
                <i style="font-size: 30px; color: #666" class="bx bx-edit-alt"></i>
              </button>
          </div>
        </div>
      </b-table-column>
      <template #bottom-left>
      <b>Total registros encontrados</b>: {{ dataTable.length }}
    </template>
    </b-table>

    <b-modal v-model="isAddActive" :width="440" scroll="clip">
      <div class="modal-card" style="width: auto">
          <form @submit.prevent="enviarDatos">
            <header class="modal-card-head">
                <p class="modal-card-title" style="text-align: center;">Agregar usuario sintetico</p>
            </header>
            
          <section class="modal-card-body">
            <b-field label="Funcionalidad:" horizontal>
              <b-select id="funcionality" v-model="functionality"  placeholder="Funcionalidad" required>
                <option value="Autenticación">Autenticación</option>
                <option value="Creación de cuenta">Creación de cuenta</option>
                <option value="Recuperación de contraseña">Recuperación de contraseña</option>
              </b-select>
            </b-field>
            <b-field label="Usuario sintetico:" horizontal>
              <b-input type="text" maxlength="20" id="username" v-model="username" required></b-input>
            </b-field>
            <b-field label="Origen:" horizontal>
              <b-input type="text" maxlength="50" id="origin" v-model="origin" required></b-input>
            </b-field>
            <b-field label="Estado:" horizontal>
              <VueToggles :height="27" :width="115" checkedText="Habilitado" uncheckedText="Inhabilitado" checkedBg="#13b52d" uncheckedBg="#dc3545" v-model="isSwitchedCustom" :reverse="true" @click="cambioswitchAdd()"/>
            </b-field>           
            <b-field label="Telefono móvil:" horizontal>
              <b-input maxlength="10" type="number" id="phone" v-model="phone" required></b-input>
            </b-field>
            <b-field label="Correo:" horizontal>
              <b-input maxlength="50" type="email" id="mail" v-model="mail" required></b-input>
            </b-field>
            
          </section>
          <footer class="modal-card-foot" >
            <div style="margin-left: 50%;">
              <b-button @click="cancelar" type="is-danger">Cancelar</b-button>
              <b-button native-type="submit" type="is-success">Guardar</b-button>
            </div>
          </footer>
            
          </form>
        </div>
    </b-modal>
  </div>             
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
        ></b-loading>
      </div>
    </main-layout>
  </template>
  
  <script>
  import MainLayout from "../../components/Layout/MainLayout";
  import {mapGetters} from "vuex";
  import axios from "axios";
  import VueToggles from 'vue-toggles';
  //import TableCard from "../../components/cards/TableCard.vue";
  import { COLUMNS_SYNTETHICUSERS } from "../../assets/config/constants/TableConstants";
  import { config } from "../../assets/config/jsonconfig.json";
  import Swal from 'sweetalert2';
  const urlBaseTiposdeDocumento = config.urlTiposdeDocumento;
  import { getToken } from "../../assets/config/getToken.js";

  export default {
    name: "Syntetic",
    components: {
      MainLayout,
      VueToggles,
      //TableCard
    },
    data() {
      return {
        isSwitchedCustom: false,
        username: '',
        origin: '',
        functionality: '',
        phone: '',
        mail: '',
        isAddActive:false,
        idseleccionado: 0,
        id: null,
        state: false,
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        edicion: "estatico",
        isLoading: false,
        isActive: undefined,
        isActiveText: "",
        dataTable: [],
        dataTableAdd: [],
        roleList: this.getRoleIdList(),
        token: null
      }
    },
    props: {
      columns: COLUMNS_SYNTETHICUSERS,
      info: undefined,
      tableConfig: {
        type: Object,
        default: () => {
          return {
            agregar: true,
            idseleccionado: 0,
            edicion: true,
            isPaginated: true,
            isPaginationRounded: true,
            perPage: 10,
            backendSorting: true,
            isDetailed: true,
          }
        }
      },
      type: {
        type: Number,
        default: 1
      },
    },
    watch: {
      isActive(newValue) {
        this.isActiveText = newValue ? "Activo" : "Inactivo";
      }
    },
    async mounted() {
      this.token = await getToken(config.DOMINIO);
      this.loadReportList()
    },
    methods: {
      loadReportList() {
          this.isLoading = true;
          const options = {
              method: 'get',
              url: urlBaseTiposdeDocumento + "synthetic-users",
              headers: {
                'Authorization': `Bearer ${this.token.Bearer}`,
              "x-api-key": config["x-api-key"] // Reemplaza 'yourApiKey' con tu API key real
              },
          }
          axios(options)
              .then((response) => {
                  if (response.status === 200) {
                      response.data.list.forEach((obj) => {
                          this.dataTable.push(obj)
                      })
                  }
              })
              .finally(() => this.isLoading = false);
      },
      editar(id, paramAlfanumeric) {
        //console.log("parametros editar:",id,paramAlfanumeric);
        this.state = null;
        this.primeravez = true;
        this.idseleccionado = id;
        this.edicion = !this.edicion;
        this.state = paramAlfanumeric;
        if(paramAlfanumeric === 'false'){
          this.state = false;
        }else{
          this.state = Boolean(paramAlfanumeric);
        }
      },
      cambioswitch(id) {
        console.log("Cambio switch:",id);
        this.state = !this.state;
      },
      cambioswitchAdd() {
        this.isSwitchedCustom = !this.isSwitchedCustom;
      },
      async confirmarCambios() {
        const result = await Swal.fire({
          title: '¿Estás seguro de realizar los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        })
        if (result.isConfirmed) {
          this.guardarCambios()
        }
      },
      async guardarCambios() {
        const params = {
          "state": this.state,
        }
        try {
          await axios.put(urlBaseTiposdeDocumento + "synthetic-users/"+this.idseleccionado, params,
            {
              headers: {
                'Authorization': `Bearer ${this.token.Bearer}`,
                'x-api-key': config["x-api-key"]
              }
            }
          )
          const result = await Swal.fire({
            title: 'Cambios realizados exitosamente',
            icon: 'success',
            confirmButtonText: 'OK'
          })

          if (result.isConfirmed) {
            this.dataTable = [];
            this.loadReportList();
            this.edicion = true;
          } 

        } catch (error) {
          const result = await Swal.fire({
            title: 'Error en la base de datos - '+ error.response.data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          })

          if (result.isConfirmed) {
            console.error(error);
          } 
          console.error(error);
        }

      },
      cancelar() {
        this.isAddActive = false;
        this.functionality = '';
        this.username = '';
        this.isSwitchedCustom = false;
        this.phone = '';
        this.mail = '';
        this.origin = '';
      },
      async enviarDatos(){
        const result = await Swal.fire({
          title: '¿Estás seguro de realizar los cambios?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        })
        if (result.isConfirmed) {
          //console.log("status",this.isSwitchedCustom);
          if(this.isSwitchedCustom == 'Inhabilitado'){
            this.isSwitchedCustom = false
          }else{
            this.isSwitchedCustom = true
          }
          const params = {
            "username": this.username,
            "origin": this.origin,
            "functionality": this.functionality,
            "state": this.isSwitchedCustom,
            "phone": this.phone,
            "mail": this.mail
          }
          try {
            await axios.post(urlBaseTiposdeDocumento + "synthetic-users", params,
              {
                headers: {
                  'Authorization': `Bearer ${this.token.Bearer}`,
                  'x-api-key': config["x-api-key"],
                  "Content-Type": "application/json"
                }
              }
            )
            const result = await Swal.fire({
              title: 'Cambios realizados exitosamente',
              icon: 'success',
              confirmButtonText: 'OK'
            })

            if (result.isConfirmed) {
              this.cancelar();
              this.dataTable = [];
              this.loadReportList();
              this.edicion = true;
            } 

          } catch (error) {
            this.cancelar();
            const result = await Swal.fire({
              title: 'Error al guardar los datos - '+ error.response.data.code,
              icon: 'error',
              confirmButtonText: 'OK'
            })

            if (result.isConfirmed) {
              console.error(error);
            } 
            console.error(error);
          }
        }
      },
      ...mapGetters(["getRoleIdList"]),
      isDisable() {
        return this.roleList.includes(80)
      },
    }
  }
  </script>
  
  <style>
    .btn-outline-secondary {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      outline: none; 
    }
    .my-swal-popup {
      width: 800px;
    }
    .labeled {
      border-radius: 5px;
      border: 1px solid grey;
      padding: 5px;
    }
  </style>