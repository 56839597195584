import { render, staticRenderFns } from "./CustomRouterLink.vue?vue&type=template&id=db40e420&scoped=true&"
import script from "./CustomRouterLink.vue?vue&type=script&lang=js&"
export * from "./CustomRouterLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db40e420",
  null
  
)

export default component.exports