<template>
  <main-layout itemMenuActive="31" tituloMenu="Logs de Auditoría">
    <div class="container is-full">
      <br />
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <b-field grouped label="Logs de Auditoría">
                  <b-field label="">
                    <h1 for="" style="margin-right: 1rem; line-height: 2.5rem;">
                      Tipo de Log:
                    </h1>
                    <b-select id="documento" placeholder="Seleccione un tipo de Log" v-model="opcionSele" expanded
                      v-on:change.native="redirecciones">
                      <option disabled value="Seleccione un tipo de Log">Seleccione un tipo de Log</option>
                      <option value="habilitacion">Habilitación de documentos</option>
                      <option value="parametrizacion">Parametrización tipos de documento</option>
                      <option value="textParametrization">Parametrización de Textos</option>
                      <option value="exportLogs">Exportación de Reportes</option>
                      <option value="envioProgramados">Envío Programados</option>
                      <option value="iconsParametrization">Parametrización de Iconos</option>
                      <option value="asignacionRoles">Asignación roles</option>
                    </b-select>
                  </b-field>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import { mapMutations, mapActions, mapState } from "vuex";
//import {formatDataLogs} from "../../assets/config/utilities";
import { COLUMNS_LOGS_ENABLED } from "../../assets/config/constants/TableConstants";
import axios from 'axios';
import { config } from "../../assets/config/jsonconfig.json";
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento
import { getToken } from "../../assets/config/getToken.js";

export default {

  name: "SopTransaction",
  components: {
    MainLayout,
  },
  data() {
    return {

      parejas: [],
      tiposSeleccionados: [],
      usuarioSeleccionado: null,
      opcionesUsuarios: [],
      opcionesTipos: [],
      opcionSele: null,
      opcionSeleccionada: "Seleccione un tipo de Log",
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      total_exitosas: 0,
      total_fallidas: 0,
      isLoading: false,
      dataTable: [],
      counter: 0,
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      locale: "en-CA",
      minDate: new Date(),
      maxDate: new Date(),
      columns: COLUMNS_LOGS_ENABLED,
      token: null
    };
  }, created() {
    axios.get(urlBaseTiposdeDocumento + '/get_documents_code', {
      headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
      },
    })
      .then(response => {
        this.parejas = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },

  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.obtenerRangoFecha();
    this.$emit("tituloTransaccion", "VU OnBoarding");
    this.nombreTituloMenu("Secure Onboarding Process");

  },

  methods: {
    obtenerRangoFecha() {
      const options = {
        method: "get",
        uri: "boards/getRangeDate",
      };
      this._axios(options)
        .then((response) => {
          this.minDate = new Date(response.data[0]);
          this.maxDate = new Date(response.data[1]);
        })
        .catch((error) => {
          this.isLoadingTable = false;
          console.log(error);
        })
        .finally(() => this.cancelarLoading());
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    redirecciones() {
      if (this.opcionSele === "habilitacion") {
        this.$router.push('/logs_habilitacion')
      }
      if (this.opcionSele === "parametrizacion") {
        this.$router.push('/ReportesParametrizacion')
      } else if (this.opcionSele === "textParametrization") {
        this.$router.push('/textParametrizationLogs')
      } else if (this.opcionSele === "exportLogs") {
          this.$router.push('/exportLogs')
      } else if (this.opcionSele === "envioProgramados"){
          this.$router.push('/envioProgramados')
      } else if (this.opcionSele === "iconsParametrization"){
          this.$router.push('/iconParametrizationLogs')
      } else if (this.opcionSele === "asignacionRoles"){
        this.$router.push('/asignacionRolesLogs')
      }

    },
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
  },
};
</script>
<style>
:root {
  --primary: #00522a;
}

b-table {
  font-size: small;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}

.has-text-primary {
  color: #00522a !important;
}

.styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}

.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}

.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>