<template>
  <main-layout itemMenuActive="37" tituloMenu="Reporte de Bloqueos">
    <div class="container is-full">
      <br/>
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
  <div class="column is-12 control has-icons-left">
    <div class="card control has-icons-left">
      <div class="card-content control">
        <div class="columns">
          <div class="column is-6">
           
          </div>
          <div class="column is-4"></div>
          <div class="column is-2">
            <div class="uk-navbar-right">
              <b-field label="">
                <b-button :disabled="this.dataTable.length === 0" v-on:click="exportar_csv_general" >CSV</b-button>
                <b-button :disabled="this.dataTable.length === 0" v-on:click="exportar_xlsx_general">Excel</b-button>
                <b-button :disabled="this.dataTable.length === 0" v-on:click="exportar_pdf_general">PDF</b-button>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="columns is-mobile is-multiline is-centered">
  <div class="column is-12">
    <div class="card">
      <div class="card-content">
        <b-field grouped label="">
  <div class="column is-one-sixth">
    <label class="label">Fecha:</label>
    <b-field label="" >
      <b-datepicker
        placeholder="Seleccione fecha..."
        v-model="fecha"
        range
        :max-date="maxDate"
      ></b-datepicker>
    </b-field><br>
  </div>

    <div class="column is-one-sixth">
    <label class="label">Tipo de documento:</label>
    <b-field label="">
      <b-select ref="tipoSelect" placeholder="Tipo de documento" v-model="selectedOption" v-on:change.native="cambioTipo">
        <option v-for="(value, index) in parejas" :key="index" :value="value.tipo" :alfanumerico="value.alfa">{{ value.text }}</option>
      </b-select>          
    </b-field><br>
  </div>
  
  <div class="column is-one-sixth">
    <label class="label">Número de documento:</label>
  <b-field label="">
    <div class="field has-addons">
      <div class="control">
        <a class="button is-static">
          <i class="bx bx-search"></i>
        </a>
      </div>
      <div class="control is-expanded">
        <b-input 
        ref="numeroDocumento"
        pattern="[0-9]+"
        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
        type="text" v-model="numeroDocumento" placeholder="Ingrese el número de documento"></b-input>
      </div>
    </div>
  </b-field>
  </div>
  
<div class="column is-two-sixths" style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
  <div>
    <!-- contenido adicional aquí -->
  </div>
  <div style="align-items: flex-end;">
    <b-field label="">
      <b-button native-type="submit" type="is-info" style="width: 93%;" @click="consultar">Consultar</b-button>
    </b-field>
    <br>
    <b-field label="">
      <b-button native-type="submit" type="is-info" style="width: 90%;" @click="limpiar">Limpiar</b-button>
    </b-field>
  </div>
</div>
</b-field>
      </div>
    </div>
  </div>
</div >
      </form>
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <TableCard messageEmpty=""
                         :columns="columns" :info="dataTable"
                         detailKey="id" :tableConfig="tableConfig"
                         :haveFilter="false"></TableCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
    ></b-loading>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
//import {formatDataLogs} from "../../assets/config/utilities";
import { COLUMNS_EVIDENTE_BLOCKS } from "../../assets/config/constants/TableConstants";
import axios from 'axios';
import iconv from 'iconv-lite';
import {config} from "../../assets/config/jsonconfig.json";
import {saveLog} from "../../services/exportLogs";
import { getToken } from "../../assets/config/getToken.js";

const url = config.urlBaseTiposdeDocumento
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento

export default {
  
  name: "SopTransaction",
  components: {
    MainLayout,
    TableCard: () => import("../../components/cards/TableCard")
  },
  data() {
    return {
      numeroDocumento: null,
      selectedOption: null,
      options: [],
      parejas: [],
      opcion:'',
      tiposSeleccionados: [],
      usuarioSeleccionado: null,
      opcionesUsuarios: [],
      opcionesTipos: [],
      opcionSeleccionada: "Seleccione un tipo de Log",
      tableConfig: {
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        backendSorting: true,
        isDetailed: false,
      },
      total_exitosas: 0,
      total_fallidas: 0,
      isLoading: false,
      dataTable: [],
      counter: 0,
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      locale: "en-CA",
      minDate: new Date(),
      maxDate: new Date(),
      columns: COLUMNS_EVIDENTE_BLOCKS,
      token: null
    };
  }, created() {
    axios.get(urlBaseTiposdeDocumento+'/get_documents_code', {
      headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
      },
    })
      .then(response => {
        this.parejas = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
  
  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  async mounted() {
    this.token = await getToken(config.DOMINIO);
    this.obtenerRangoFecha();
    this.apiTiposDocumento().then((res) => {
      let opTipos = res.filter(tipo => {
        return tipo.Dashboard == true
      }).map(function (tipo) {
        return ({
          tipo: tipo.code,
          text: tipo.Title,
          alfa: tipo.AlfaNumerico,
        })
      }).sort((x, y) => x.text.localeCompare(y.text));

      this.parejas = opTipos
    });
  },
  methods: {
    async apiTiposDocumento() {
      return await axios({
        method: 'get',
        url: urlBaseTiposdeDocumento+'/tiposdedocumento',
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`
        },
      }).then((res) => {
        return res.data;
      }).catch((error) => {
          console.error(error)
      })
    },
    cambioTipo() {
      let alfaNumerico = this.$refs.tipoSelect.$refs.select.selectedOptions[0].getAttribute('alfanumerico')
      let pattern = '[0-9]+';
      let oninput = "this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\..*)\\./g, '$1');"
      if (alfaNumerico == 'true') {
          pattern = '[0-9A-Za-zÑñ]+';
          oninput = "this.value = this.value.replace(/[^0-9A-Za-zÑñ]/g, '');"
      }
      this.$refs.numeroDocumento.$refs.input.value = '';
      this.$refs.numeroDocumento.$refs.input.pattern = pattern
      this.$refs.numeroDocumento.$refs.input.setAttribute('oninput', oninput);
    },
    sumar() { 
      if (this.fecha.length!==0) {
        let ultimaFecha = this.fecha[this.fecha.length - 1];
        let fechaObjeto = new Date(ultimaFecha);
        fechaObjeto.setDate(fechaObjeto.getDate() + 1);
        let fechaSumadaFormateada = fechaObjeto.toISOString();
        this.fecha[this.fecha.length - 1] = fechaSumadaFormateada;
      }
    },
    exportar_csv_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento+'/download_csv';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');
      if (this.selectedOption && this.selectedOption !== null) {
        this.opcion = this.selectedOption.toLowerCase();
      } else { 
        this.opcion = this.selectedOption;
      }
      const data = {
        data: {
            "Fecha":this.fecha,
            "Tipo de Documento": this.opcion,
            "Numero de documento": Number(this.numeroDocumento)
        },
        url: urlBaseTiposdeDocumento+"/get_reporter_evidente_block",
        filename: "data.csv",
        columns:["Nro","Tipo de Documento","Número de Documento","Fecha y Hora Bloqueo","Tiempo de Bloqueo"]
      };
      
      fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        return response.text();
      })
      .then(text => {
        const buffer = iconv.encode(text, 'latin1');
        const blob = new Blob([buffer], {type: 'text/csv'});
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        const fileName = `data_${formattedDate}.csv`;
        const encodedFileName = encodeURI(fileName);
        link.setAttribute('download', encodedFileName);
        link.click();
        newTab.document.write('<h1>Downloaded!</h1>');
        newTab.close();
          this.saveExportEvent("csv")
      })
      .catch(error => {
        newTab.document.write(`<h1>Error: ${error.message}</h1>`);
      })
    },
    exportar_xlsx_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento+'/download_excel';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');

      if (this.selectedOption && this.selectedOption !== null) {
        this.opcion = this.selectedOption.toLowerCase();
      } else { 
        this.opcion = this.selectedOption;
      }
      const data = {
        data: {
            "Fecha":this.fecha,
            "Tipo de Documento": this.opcion,
            "Numero de documento": Number(this.numeroDocumento)
        },
        url: urlBaseTiposdeDocumento+"/get_reporter_evidente_block",
        filename: "data.xlsx",
        columns:["Nro","Tipo de Documento","Número de Documento","Fecha y Hora Bloqueo","Tiempo de Bloqueo"]
      };
      fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.xlsx`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
            this.saveExportEvent("excel")
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
    exportar_pdf_general() {
      this.sumar()
      const now = new Date();
      const seconds = now.getSeconds();
      const formattedDate = now.toLocaleString().replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_/g, '') + '_' + seconds;
      const url = urlBaseTiposdeDocumento+'/download_pdf';
      const newTab = window.open();
      newTab.document.write('<h1>Loading...</h1>');
      if (this.selectedOption && this.selectedOption !== null) {
        this.opcion = this.selectedOption.toLowerCase();
      } else { 
        this.opcion = this.selectedOption;
      }
      const data = {
        data: {
            "Fecha":this.fecha,
            "Tipo de Documento": this.opcion,
            "Numero de documento": Number(this.numeroDocumento)
        },
        url: urlBaseTiposdeDocumento+"/get_reporter_evidente_block",
        filename: "data.pdf",
        columns:["Nro","Tipo de Documento","Número de Documento","Fecha y Hora Bloqueo","Tiempo de Bloqueo"]
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          const objectURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectURL;
          const filenamedown = `data_${formattedDate}.pdf`;
          link.download = filenamedown;
          link.click();
          newTab.document.write('<h1>Downloaded!</h1>');
          newTab.close();
            this.saveExportEvent("pdf")
        })
        .catch(error => {
          newTab.document.write(`<h1>Error: ${error.message}</h1>`);
        })
    },
   
    consultar() {
      this.sumar()
      const ruta = urlBaseTiposdeDocumento + '/get_reporter_evidente_block';
      if (this.selectedOption && this.selectedOption !== null) {
        this.opcion = this.selectedOption.toLowerCase();
      } else { 
        this.opcion = this.selectedOption;
      }
      const data = {
        Fecha: this.fecha,
        "Tipo de Documento": this.opcion,
        "Numero de documento": Number(this.numeroDocumento)
      }

      axios.post(ruta, JSON.stringify(data),{
          headers: {
            'Authorization': `Bearer ${this.token.Bearer}`,
            'Content-Type': 'application/json'
          }
      }).then((response) => {
        response.data.forEach((item) => {
              this.dataTable.push(item);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    convertirFechadata(fechaString) {
      const fecha = new Date(fechaString);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    },
    sumarDia(fechaString) {
      const fecha = new Date(fechaString);
      fecha.setDate(fecha.getDate() + 1);
      const fechaISO = fecha.toISOString();
      return fechaISO;
    }
    ,
    limpiar() {
      this.usuarioSeleccionado = null;
      this.selectedOption = null;
      this.numeroDocumento = null;
      this.fecha = []
    },
    get_users() {
      axios.get(url+'/getUserslogsEnabled', {
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
        }
      })
        .then((response) => {
          this.opcionesUsuarios = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios.get(url+'/get_documents_code', {
        headers: {
          'Authorization': `Bearer ${this.token.Bearer}`,
        }
      })
        .then((response) => {
          this.opcionesTipos = response.data["Label"];
          this.tipo=response.data["code"]
        })
        .catch((error) => {
          console.log(error);
        })
    },
    obtenerRangoFecha() {
      const options = {
        method: "get",
        uri: "boards/getRangeDate",
      };
      this._axios(options)
          .then((response) => {
            this.minDate = new Date(response.data[0]);
            this.maxDate = new Date(response.data[1]);
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
    },
    handleSubmit(data) {
      
      if (this._PERFIL_MODULO_.onboarding) {
        this.isLoading = true;     
        this.dataTable = [];
        this.counter = 0;
        
        axios.get(data)
            .then((response) => {
              response.data.forEach((item) => {
                this.dataTable.push(item);
              });
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    }
    ,
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    convertFecha: function (fecha) {
      const dtf = new Intl.DateTimeFormat(this.locale);
      return dtf.format(new Date(fecha));
    },
      saveExportEvent(type) {
          let logData = {
              "user": this.getUsuario(),
              "sectionId": 3,
              "reportId": 2,
              "format": type
          }
          if (this.fecha.length !== 0) {
              logData.startDate = this.fecha[0]
              logData.endDate = new Date(this.fecha[1])
              logData.endDate.setDate(logData.endDate.getDate() - 1)
          }
          if (this.numeroDocumento || this.selectedOption) {
              logData.document = ""
              if (this.selectedOption) {
                  logData.document += this.selectedOption
              }
              if (this.numeroDocumento) {
                  logData.document += this.numeroDocumento
              }
          }
          saveLog(logData);
      },
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
      ...mapGetters(["getUsuario"])
  },
};
</script>
<style>
:root {
  --primary: #00522a;
}

b-table {
  font-size: small;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}

.has-text-primary {
  color: #00522a !important;
}

.styled-select select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}

.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}

.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>