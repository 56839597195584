<template>
  <main-layout :itemMenuActive="20" tituloMenu="Parametrización de textos">
    <br>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <!--          <div style="display: flex; justify-content: space-between; align-items: center">-->
          <p class="title is-4 centered-text">Edición de formularios</p>
          <br>
          <div class="columns">
            <div class="column is-half">
              <b-field horizontal label="Proceso">
                <b-select placeholder="Seleccione un proceso" v-model="processSelected" expanded>
                  <option :key="item.key" v-for="item in processList" :value="item">
                    {{ item.value }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field horizontal label="Formulario">
                <b-select placeholder="Seleccione un formulario" v-model="formSelected" expanded
                          :disabled="formSelectDisabled">
                  <option :key="item.key" v-for="item in formList" :value="item">
                    {{ item.value }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div v-if="formVars">
            <hr>
            <p class="title is-5">{{ formSelected.value }}</p>
            <div class="columns">
              <!--              <div class="column is-three-thirds">-->
              <div class="column">
                <div :key="index" class="columns" v-for="(rowList, index) in formValuesByRow">
                  <div class="column is-half" :key="item.key" v-for="item in rowList">
                    <b-field v-if="!item.hasOwnProperty('link')" horizontal :label="item.name"
                             :type="formErrors[item.key] ? 'is-danger' : ''" :message="formErrors[item.key]">
                      <b-input v-model="item.value" :disabled="isDisabled()"/>
                    </b-field>
                    <b-field v-else horizontal :label="item.name">
                      <b-field label="Texto" label-position="inside"
                               :type="formErrors[item.key] ? 'is-danger' : ''"
                               :message="formErrors[item.key]">
                        <b-input v-model="item.value" :disabled="isDisabled()"/>
                      </b-field>
                      <b-field label="Link" label-position="inside"
                               :type="formErrors[item.key + '_link'] ? 'is-danger' : ''"
                               :message="formErrors[item.key + '_link']">
                        <b-input v-model="item.link" :disabled="isDisabled()"/>
                      </b-field>
                    </b-field>
                  </div>
                </div>
              </div>
              <div v-if="htmlFile" class="column">
                <div v-if="isLoadingPreview" class="card iframe-full-size" style="background: gray">
                  <div class="card-content">
                    <b-loading :is-full-page="false" v-model="isLoadingPreview"></b-loading>
                  </div>
                </div>
                <iframe :style="{display: (isLoadingPreview ? 'none' : 'inline')}" id="form_frame" :src="URL_HTML_FORMS + htmlFile + '?id=' + Math.random()" class="iframe-full-size">
                </iframe>
              </div>
            </div>
            <div v-if="isEditable()" class="columns">
              <div class="column column is-4 is-offset-8">
                <div class="columns">
                  <b-button style="margin-right: 10px" class="column" :loading="isLoading"
                            type="is-danger" expanded @click="discard">
                    Descartar
                  </b-button>
                  <b-button class="column" :loading="isLoading" type="is-primary" expanded
                            @click="submit" :disabled="isDisabledSubmit">
                    Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile is-multiline is-centered">
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../../components/Layout/MainLayout";
import {mapActions, mapGetters} from "vuex";
import {object, setLocale, string} from "yup";
import {HOST_API} from "../../assets/config/constants/GeneralConstants";
import axios from "axios";
import dataConfig from "@/assets/config/jsonconfig.json";

setLocale({
  mixed: {
    required: 'Es un campo obligatorio'
  }
});

export default {
  name: "Parametrization",
  components: {
    MainLayout
  },
  data() {
    return {
      isLoading: false,
      isLoadingPreview: false,
      formList: [],
      processList: [],
      formSelected: undefined,
      formSelectDisabled: true,
      processSelected: undefined,
      formVars: undefined,
      formData: undefined,
      htmlFile: undefined,
      originalFormVars: undefined,
      formErrors: undefined,
      schema: undefined,
      isDisabledSubmit: false,
      URL_HTML_FORMS: dataConfig.config.urlBaseHTMLForms,
      roleList: this.getRoleIdList()
    }
  },
  watch: {
    processSelected(newValue) {
      if (newValue) {
        this.formList = newValue.forms;
        this.formSelectDisabled = false;
        this.formVars = undefined;
        this.htmlFile = undefined;
      }
    },
    formSelected(newValue) {
      if (newValue) {
        this.htmlFile = undefined;
        this.loadFormVars(this.processSelected.key, newValue.key)
      }
    },
    formVars: {
      handler(newValue) {
        if (newValue) {
          this.checkFormChange();
        }
      },
      deep: true
    }
  },
  computed: {
    formValuesByRow() {
      const result = []
      for (let i = 0; i < this.formVars.length; i += 2)
        result.push(this.formVars.slice(i, i + 2))
      return result
    }
  },
  mounted() {
    this.loadFormList()
  },
  methods: {
    ...mapActions(['_axios']),
    ...mapGetters(["getRoleIdList"]),
    isEditable() {
      return this.roleList.includes(51)
    },
    isDisabled() {
      return !this.isEditable()
    },
    submit() {
      this.resetFormErrors();
      this.schema.validate(this.getFormData(), {abortEarly: false})
          .then(() => {
            this.saveFormVars();
          }).catch((err) => {
        err.inner.forEach((error) => {
          this.formErrors = {...this.formErrors, [error.path]: error.message};
        });
      });
    },
    saveFormVars() {
      this.isLoading = true;
      let postData = this.getFormData();
      postData.formKey = this.formSelected.key;
      postData.processKey = this.processSelected.key;
      const options = {
        method: 'post',
        url: `${HOST_API}/parametrization`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
        data: postData
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.$buefy.toast.open({
                message: `Los cambios se guardaron exitosamente`,
                type: 'is-success'
              })
              this.originalFormVars = this.getFormData()
              this.isDisabledSubmit = true;
              this.isLoadingPreview = true;
              setTimeout(() => {
                this.isLoadingPreview = false;
                  const _theframe = document.getElementById("form_frame");
                  _theframe.contentWindow.location.href = _theframe.src;
              }, 7000);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    loadFormList() {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/parametrization`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              this.processList = response.data.data;
            }
          })
          .finally(() => this.isLoading = false);
    },
    loadFormVars(processKey, formKey) {
      this.isLoading = true;
      const options = {
        method: 'get',
        url: `${HOST_API}/parametrization/${processKey}/${formKey}`,
        headers: {
          Authorization: `Bearer ${this.$store.state.tokenAuth}`
        },
      }
      axios(options)
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data.data;
              let reponseList = []
              for (const key in responseData) {
                responseData[key]["key"] = key
                reponseList.push(responseData[key]);
              }
              reponseList.sort((a, b) => a.orden - b.orden)
              this.formVars = reponseList
              this.originalFormVars = this.getFormData()
              if (response.data.htmlFile !== "") {
                  this.htmlFile = response.data.htmlFile;
              }
              this.createSchema()
            }
          })
          .finally(() => this.isLoading = false);
    },
    createSchema() {
      let jsonSchema = {}, formErrors = {}
      for (const item of this.formVars) {
        jsonSchema[item.key] = string().required().max(500)
        formErrors[item.key] = ''
        if (item.link) {
          jsonSchema[item.key + '_link'] = string().required().url('Debe ser una URL')
          formErrors[item.key + '_link'] = ''
        }
      }
      this.schema = object().shape(jsonSchema)
      this.formErrors = formErrors
    },
    checkFormChange() {
      const formData = this.getFormData();
      this.isDisabledSubmit = this.isEquivalent(formData, this.originalFormVars)
      this.resetFormErrors();
      this.schema.validate(formData, {abortEarly: false}).catch((err) => {
        this.isDisabledSubmit = true;
        err.inner.forEach((error) => {
          this.formErrors = {...this.formErrors, [error.path]: error.message};
        });
      });
    },
    resetFormErrors() {
      for (const key in this.formErrors) {
        this.formErrors[key] = '';
      }
    },
    getFormData() {
      let jsonFormData = {}
      for (const item of this.formVars) {
        jsonFormData[item.key] = item.value
        if (item.link) {
          jsonFormData[item.key + '_link'] = item.link
        }
      }
      return jsonFormData
    },
    isEquivalent(a, b) {
      const aProps = Object.getOwnPropertyNames(a);
      for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
      return true;
    },
    discard() {
      this.formVars = undefined;
      this.formSelected = undefined;
      this.processSelected = undefined;
      this.formList = [];
      this.formSelectDisabled = true;
    }
  }
}
</script>

<style scoped>

p.centered-text {
  margin-top: auto;
  margin-bottom: auto;
}

.iframe-full-size {
  width: 100%;
  height: 100%;
}

</style>