<template>
  <router-link
      :to="route"
      class="uk-button uk-text-capitalize router-item"
      :style="{
              padding: '.4rem',
              borderRadius: '1px',
              width: '100%',
              textAlign: 'left',
              // background: optionSelected == optionNum ? '#304156' : '#304156',
              color: this.getOptionSelected() === this.getOptionNum() ? '#409EFF' : '#BFCBD9'
            }">
    <i :class="getIconClass()" style="margin-right: 0.1rem"></i>
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "CustomRouterLink",
  props: [
    'route',
    'iconOption',
    'text',
    'optionNum',
    'optionSelected'
  ],
  methods: {
    getIconClass() {
      return 'bx ' + this.iconOption;
    },
    getOptionNum() {
        return parseInt(this.optionNum)
    },
    getOptionSelected() {
        return parseInt(this.optionSelected)
    }
  }
}
</script>

<style scoped>

</style>