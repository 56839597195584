<template>
    <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
            <div class="card">
                <div class="card-content">
                    <div v-if="dataList.length > 0">
                        <b-table :data="dataList" :columns="columns"
                                 :paginated="isPaginated"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 backend-pagination
                                 pagination-position="bottom"
                                 :pagination-rounded="true"
                                 @page-change="onPageChange"
                                 :total="total"
                                 striped 
                                 :detailed="detailed">

                            <template #detail="item">
                                <div v-if="tableTypeDetails == 'AsignacionRolesDetail'">
                                    <AsignacionRolesDetail :dataDetail="item"></AsignacionRolesDetail>
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <div v-else>
                        <b-message type="is-info">
                            <template v-if="messageEmpty">
                                {{ messageEmpty }}
                            </template>
                            <template v-else>
                                Sin datos para procesar.
                            </template>
                        </b-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormatting } from "../../assets/config/utilities";
export default {
    name: "SimpleTableCard",
    components: {
        AsignacionRolesDetail: () => import("../tables/cardDetail/AsignacionRolesDetail"),
    },
    props: {
        columns: Array,
        dataList: Array,
        messageEmpty: String,
        isPaginated: {
            type: Boolean,
            default: true
        },
        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        total: Number,
        pageChange: {type: Function},
        tableTypeDetails: {
            type: String,
            default: ''
        },
        detailed: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        if (this.tableTypeDetails == 'AsignacionRolesDetail') {
            const rows = document.querySelectorAll('tr[draggable="false"]');
            let index = 0;
            for (const row of rows) {
                this.dataList[index].createdAt = dateFormatting(this.dataList[index].createdAt);
                if (this.dataList[index].actionType == 'Consultar') {
                    row.childNodes[0].removeChild(row.childNodes[0].childNodes[0]);
                }
                index++;
            }
        }
    },
    methods: {
        onPageChange(page) {
            this.pageChange(page);
        }
    }
}
</script>

<style scoped>

</style>