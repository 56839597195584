import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import {redirectAuthenticated} from "../assets/config/utilities";
import SendReportsHome from "../views/Reportes/SendReportsHome";
import CreateReport from "../views/Reportes/CreateReport";
import CreateReportv2 from "../views/Reportes/CreateReportv2";
import UserList from "../views/Users/UserList";
import UserDetail from "../views/Users/UserDetail";
import Parametrization from "../views/Parametrization/Parametrization";
import Evidente from "../views/Parametrization/Evidente";
import AsignacionRoles from "../views/Parametrization/AsignacionRoles";
import IconsConfig from "../views/Parametrization/IconsConfig";
import ProfileList from "../views/Users/ProfileList";
import ProfileDetail from "../views/Users/ProfileDetail";
import Store from "../store";
import ProxyList from "../views/Proxies/ProxyList";
import SearchProxies from "../views/Proxies/SearchProxies";
import logsHabilitacion from "../views/Auditoria/Habilitacion";
import habilitacionTD from "../views/Parametrization/habilitacionTD";
import habilitacionTDPersona from "../views/Parametrization/habilitacionTDPersona";
import habilitacionTDEmpresa from "../views/Parametrization/habilitacionTDEmpresa";
import ParametrizaciondeTiposdeDocumentos from "../views/Parametrization/ParametrizaciondeTiposdeDocumentos";
import EvidenteIntentos from "../views/Reportes_Evidente/Evidente_Intentos";
import EvidenteBloqueos from "../views/Reportes_Evidente/Evidente_Bloqueos";
import ReportesParametrizacion from "../views/Auditoria/Parametrizacion_Tipos";
import logs from "../views/Auditoria/Logs";
import sendreportsv2 from "../views/Reportes/SendReportsHomev2";
import viewreportv2 from "../views/Reportes/ViewReportv2";
import historicoreport from "../views/Reportes/HistoricoReport";
import Updatereportv2 from "../views/Reportes/UpdateReportv2";
import TextParametrization from "../views/Auditoria/TextParametrization.vue";
import ExportLogs from "../views/Auditoria/ExportLogs.vue";
import EnvioProgramados from "../views/Auditoria/EnvioProgramados.vue";
import IconParametrizationLogs from "../views/Auditoria/IconParametrizationLogs.vue";
import syntheticusers from "../views/Parametrization/syntheticUsers";
import Switch from "../views/Parametrization/Switch";
import AsignacionRolesLogs from "../views/Auditoria/AsignacionRolesLogs.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/Auth/Logout')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Auth/Login'),
        beforeEnter: redirectAuthenticated
    },
    {
        path: '/firmadodocumento',
        name: 'FdTransaction ',
        component: () => import('../views/Firmado/FdTransaction.vue')
    },
    {
        path: '/borradodocumento',
        name: 'BdTransaction ',
        component: () => import('../views/Firmado/BdTransaction.vue')
    },
    {
        path: '/adminalert',
        name: 'AdminAlert ',
        component: () => import('../views/AdminAlert/AdminAlert.vue')
    },
    {
        path: '/certificadodocumento',
        name: 'CdTransaction ',
        component: () => import('../views/Firmado/CdTransaction.vue')
    },
    {
        path: '/facerecogn',
        name: 'FrTransaction',
        component: () => import('../views/Vu/FrTransaction.vue')
    },
    {
        path: '/facerecognFinan',
        name: 'FrTransactionFinan',
        component: () => import('../views/financiamiento/facefinan.vue')
    },
    {
        path: '/diariofacerecognFinan',
        name: 'ReporteDiarioFaceRecognitionFinan',
        component: () => import('../views/financiamiento/facefinandiario.vue')
    },
    {
        path: '/onsop',
        name: 'SopTransaction',
        component: () => import('../views/Vu/SopTransaction.vue')
    },
    {
        path: '/onsopFinan',
        name: 'SopTransactionFinan',
        component: () => import('../views/financiamiento/sopfinan.vue')
    },
    {
        path: '/diarioonsopFinan',
        name: 'ReporteDiarioOnBoardingFinan',
        component: () => import('../views/financiamiento/sopfinandiario.vue')
    },
    {
        path: '/diarioonsop',
        name: 'ReporteDiarioOnBoardingTuya',
        component: () => import('../views/Tuya/ReporteDiarioOnBoardingTuya.vue')
    },
    {
        path: '/diariofacerecogn',
        name: 'ReporteDiarioFaceRecognition',
        component: () => import('../views/Tuya/ReporteDiarioFaceRecognition.vue')
    },
    {
        path: '/reporteerrores',
        name: 'ReporteErrores ',
        component: () => import('../views/Errores/ReporteErrores')
    },
    {
        path: '/transaccionesusuario',
        name: 'PorUsuario ',
        component: () => import('../views/Reportes/PorUsuario.vue')
    },
    {
        path: '/transaccionesusuariocomp',
        name: 'PorUsuario',
        component: () => import('../views/Reportes/PorUsuarioCompensar.vue')
    },
    {
        path: '/transaccionesusuariofinan',
        name: 'PorUsuarioFinan',
        component: () => import('../views/financiamiento/porUsuario.vue')
    },
    {
        path: '/cantidadtransaccionesusuario',
        name: 'CantidadTransaccionesPorUsuario ',
        component: () => import('../views/Vu/CantidadTransaccionesPorUsuario')
    },
    {
        path: '/onboardingacomulado',
        name: 'TransaccionesOnboardingAcumulado',
        component: () => import('../views/Vu/TransaccionesOnboardingAcomulado')
    },
    {
        path: '/onboardingacomuladoFinan',
        name: 'TransaccionesOnboardingAcumuladoFinan',
        component: () => import('../views/financiamiento/acumulado')
    },
    {
        path: '/analisisResultadosFinan',
        name: 'AnalisisResultadosFinan',
        component: () => import('../views/financiamiento/analisisResultado')
    },
    {
        path: '/consultaregistraduria',
        name: 'CdRegistraduria',
        component: () => import('../views/Registraduria/CdRegistraduria')
    },
    {
        path: '/consultaconsent',
        name: 'Prueba',
        component: () => import('../views/Ciam/Prueba')
    },
    {
        path: '/reportelogin',
        name: 'Reporte Login',
        component: () => import('../views/Ciam/Login')
    },
    {
        path: '/reporteregistrar',
        name: 'Reporte Registrar',
        component: () => import('../views/Ciam/Registrar')
    },
    {
        path: '/reportecambiar',
        name: 'Reporte Cambiar',
        component: () => import('../views/Ciam/Cambiar')
    },
    {
        path: '/reporterecuperar',
        name: 'Reporte Recuperar',
        component: () => import('../views/Ciam/Recuperar')
    },
    {
        path: '/sendreports',
        name: 'EnviarReportes',
        component: SendReportsHome
    },
    {
        path: '/sendreportsv2',
        name: 'EnviarReportesv2',
        component: sendreportsv2
    },
    {
        path: '/viewreportv2/:reportId',
        name: 'ViewReportesv2',
        component: viewreportv2
    },
    {
        path: '/UpdateReportv2/:reportId',
        name: 'UpdateReportesv2',
        component: Updatereportv2
    },
    {
        path: '/historicoreport/:reportId',
        name: 'HistoricoReport',
        component: historicoreport
    },
    {
        path: '/reportv2/:id?',
        name: 'CrearReportev2',
        component: CreateReportv2
    },
    {
        path: '/report/:id?',
        name: 'CrearReporte',
        component: CreateReport
    },
    {
        path: '/users',
        name: 'Usuarios',
        component: UserList
    },
    {
        path: '/user/:id?',
        name: 'Usuario',
        component: UserDetail
    },
    {
        path: '/parametrization',
        name: 'Parametrizacion',
        component: Parametrization
    },
    {
        path: '/evidente',
        name: 'EvidenteMaster',
        component: Evidente
    },
    {
        path: '/asignacionRoles',
        name: 'Asignación de roles',
        component: AsignacionRoles
    },
    {
        path: '/habilitacionTD',
        name: 'Habilitación de documentos',
        component: habilitacionTD
    }, 
    {
        path: '/ParametrizaciondeTiposdeDocumentos',
        name: 'Parametrizacion de Tipos de Documentos',
        component: ParametrizaciondeTiposdeDocumentos
    },
    {
        path: '/habilitacionTDPersona',
        name: 'Habilitación de documentos Persona',
        component: habilitacionTDPersona
    },
    {
        path: '/habilitacionTDEmpresa',
        name: 'Habilitación de documentos Empresa',
        component: habilitacionTDEmpresa
    },
    {
        path: '/Evidente_Intentos',
        name: 'Evidente Intentos',
        component: EvidenteIntentos
    },
    {
        path: '/Evidente_Bloqueos',
        name: 'Evidente Bloqueos',
        component: EvidenteBloqueos
    },
    {
        path: '/ReportesParametrizacion',
        name: 'Reportes Parametrizacion',
        component: ReportesParametrizacion
    },
    {
        path: '/envioProgramados',
        name: 'Envio Programados',
        component: EnvioProgramados
    },
    {
        path: '/icons',
        name: 'Iconos',
        component: IconsConfig
    },
    {
        path: '/profiles',
        name: 'Perfiles',
        component: ProfileList
    },
    {
        path: '/profile/:id?',
        name: 'Perfil',
        component: ProfileDetail
    },
    {
        path: '/proxies',
        name: 'Delegados',
        component: ProxyList
    },
    {
        path: '/proxies/user',
        name: 'Delegados por usuario',
        component: SearchProxies
    },
    {
        path: '/logs_habilitacion',
        name: 'Logs de habilitacion',
        component: logsHabilitacion
    },
    {
        path: '/logs_auditoria',
        name: 'Logs de auditoria',
        component: logs
    },
    {
        path: '/condensadoLogin',
        name: 'Condensado Autenticación',
        component: () => import('../views/ReportesTotales/login') 
    },
    {
        path: '/condensadoAutenticacion',
        name: 'Condensado Autenticaciónn',
        component: () => import('../views/ReportesTotales/login') 
    },
    {
        path: '/condensadoCambio',
        name: 'Condensado Cambio de Clave',
        component: () => import('../views/ReportesTotales/cambio') 
    },
    {
        path: '/condensadoRecuperar',
        name: 'Condensado Recuperación de Clave',
        component: () => import('../views/ReportesTotales/recuperacion') 
    },
    {
        path: '/condensadoRegistrar',
        name: 'Condensado Creación de Cuenta',
        component: () => import('../views/ReportesTotales/registrar') 
    },
    {
        path: '/condensadoSOP',
        name: 'Condensado Onboarding Digital',
        component: () => import('../views/ReportesTotales/sop') 
    },
    {
        path: '/condensadoFace',
        name: 'Condensado Reconocimiento Facial',
        component: () => import('../views/ReportesTotales/face') 
    },
    {
        path: '/textParametrizationLogs',
        name: 'TextParametrizationLogs',
        component: TextParametrization
    },
    {
        path: '/exportLogs',
        name: 'ExportLogs',
        component: ExportLogs
    },
    {
        path: '/iconParametrizationLogs',
        name: 'IconParametrizationLogs',
        component: IconParametrizationLogs
    },
    {
        path: '/syntheticUsers',
        name: 'SyntheticUsers',
        component: syntheticusers
    },
    {
        path: '/switch',
        name: 'Switch',
        component: Switch
    },
    {
        path: '/asignacionRolesLogs',
        name: 'AsignacionRolesLogs',
        component: AsignacionRolesLogs
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login') {
        if (Store.getters.getTokenAuth !== '') {
            // if (Store.getters.getRoles.includes(to.id)) {
            //     next();
            // } else {
            //     next('/');
            // }
            next()
        } else {
            next("/login");
        }
    } else {
        next();
    }
});

export default router
